import React from "react"

import Spinner from "components/spinner"

import UString from "utilities/string"

export default function (props: React.ImgHTMLAttributes<HTMLImageElement>) {
    const [loaded, setLoaded] = React.useState<boolean>(false)

    const onLoad = () => setLoaded(true)
    const onError = ({ currentTarget }: React.SyntheticEvent<HTMLImageElement, Event>) => (currentTarget.src = "/static/images/not-available.png", setLoaded(true))

    return <div className="relative w-full h-full">
        {!loaded ? <div className="absolute-page"><Spinner className="w-1/2" /></div> : null}
        <img {...props} className={UString.concat_class_name(!loaded ? "hidden" : undefined, props.className || String())} onLoad={onLoad} onError={onError} />
    </div>
}