import { useContext, useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ArrowCircleLeftIcon, MenuIcon } from "@heroicons/react/solid";
import { Select, MenuItem } from '@mui/material';

import Authenticate from "services/authenticate";

import SideBar from "components/sidebar";
import i18next from "i18next";

export default function (): JSX.Element {
  const AuthContext = useContext(Authenticate.Context);
  const Location = useLocation(),
    [sidebar, setSidebar] = useState<boolean>(false);
  let navigate = useNavigate();

  useEffect(setSidebar.bind(null, false), [Location]);

  const goBack = () => {
    navigate(-1);
  };

  const goForward = () => {
    navigate(1);
  };

  const [lang, setLang] = useState(localStorage.getItem("i18nextLng") || "tr-TR")

  return (
    <div id="layout">
      <SideBar show={sidebar} onClose={setSidebar.bind(null, false)} />
      <main>
        <div id="user-control">
          <div id="user-control-wrapper">
            <button
              onClick={setSidebar.bind(null, !sidebar)}
              className="xl:hidden w-7 h-7"
            >
              <MenuIcon />
            </button>
            <div className="flex ml-2">
              <button type="button" onClick={goBack}>
                <ArrowCircleLeftIcon className="h-10 w-10" />
              </button>
            </div>
            <div id="user">
              <Select sx={{ minWidth: "8vw", marginRight: "1.2vw", height: "3vw" }} value={lang} onChange={(e: any) => {
                setLang(e.target.value);
                i18next.changeLanguage(e.target.value);
                window.location.reload();
              }}>
                <MenuItem value="tr-TR"><div style={{display:"flex", flexDirection: "row"}}><img style={{marginRight: "0.3vw"}} src="https://img.icons8.com/color/24/null/turkey.png"/><span>Türkçe</span></div></MenuItem>
                <MenuItem value="en-US"><div style={{display:"flex", flexDirection: "row"}}><img style={{marginRight: "0.3vw"}} src="https://img.icons8.com/color/24/null/great-britain.png"/><span>English</span></div></MenuItem>
                <MenuItem value="ar-SA"><div style={{display:"flex", flexDirection: "row"}}><img style={{marginRight: "0.3vw"}} src="https://img.icons8.com/color/24/saudi-arabia.png"/><span>Arabia</span></div></MenuItem>
              </Select>
              {AuthContext?.User?.email}
            </div>
          </div>
        </div>
        <Outlet />
      </main>
    </div>
  );
}
