import { useContext, useState, useEffect } from "react";

import Http from "services/http";
import Response from "services/response";

import Report from "services/report";
import IResponseProductReport from "services/interfaces/report/product";

import { ArrowSmUpIcon } from "@heroicons/react/solid";
import { ArrowSmDownIcon } from "@heroicons/react/solid";

import { useTranslation } from "react-i18next";
import { defaultCurrency } from "services/helpers/exchange";

export default function ({
  TodayProductReport,
  tableBusyRate,
}: {
  TodayProductReport: Response<IResponseProductReport>;
  tableBusyRate: any;
}) {
  const ReportService = new Report(useContext(Http.Context)!);
  const WeekAgoProductReport = new Response<IResponseProductReport>();

  const [sumOfToday, setSumOfToday] = useState<number>(0);
  const [totalOfWeekAgo, setTotalOfWeekAgo] = useState<number>(0);
  const [sumOfTheseTimesOfWeekAgo, setSumOfTheseTimesOfWeekAgo] =
    useState<number>(0);

  const {t} = useTranslation();

  const [checksOfWeekAgo, setChecksOfWeekAgo] = useState<{
    total: number;
    sum: number;
  }>();

  const [diff, setDiff] = useState<{ inc: boolean; val: number }>({
    inc: false,
    val: 0,
  });

  // On mount, fetch the reports
  useEffect(() => {
    let startOfToday = new Date().setHours(0, 1, 0, 0);
    let startOfWeekAgo = startOfToday - 7 * 24 * 60 * 60 * 1000;
    let endOfWeekAgo = startOfWeekAgo + 24 * 60 * 60 * 1000;
    WeekAgoProductReport.handle(
      ReportService.getReport(
        "productbased",
        startOfWeekAgo.toString(),
        endOfWeekAgo.toString()
      )
    );
  }, []);

  // Make the calculations when today's report fetched
  useEffect(() => {
    if (TodayProductReport.data) {
      let sum = 0;

      // for now just the current branch, but if needed, merge all sales of branches
      let mergedProducts = TodayProductReport.data.report
        .at(-1)! // -1 => current branch
        .data[0]?.result.reduce((acc: any, curr: any) => {
          if (curr.isPaid) {
            acc.push(curr);
          }
          return acc;
        }, []);
      mergedProducts?.forEach((item: any) => {
        sum += item.price;
      });
      setSumOfToday(sum); // bugün bu saate kadarki kazanç
    }
  }, [TodayProductReport.data]);

  // Make the calculations when a week ago's report fetched
  useEffect(() => {
    if (WeekAgoProductReport.data) {
      let total = 0;
      let sum = 0;
      let sumChecks: any = [];
      let totalChecks: any = [];

      // for now just the current branch, but if needed, merge all sales of branches
      const prods = WeekAgoProductReport.data?.report?.at(-1)!.data[0]?.result.reduce((acc: any, curr: any) => {
          if (curr.isPaid) {
            acc.push(curr);
          }
          return acc;
        }, []);

      prods?.forEach((item: any) => {
        total += item.price;
        totalChecks.push(item.check);
        let cmpPoint = new Date(item.createdAt);
        if (
          cmpPoint.getHours() * 60 + cmpPoint.getMinutes() <=
          new Date().getHours() * 60 + new Date().getMinutes()
        ) {
          sum += item.price;
          sumChecks.push(item.check);
        }
      });
      setChecksOfWeekAgo({
        total: new Set(totalChecks).size, // geçen hafta gün sonu adisyon
        sum: new Set(sumChecks).size, // geçen hafta bu saatlerdeki adisyon
      });

      setTotalOfWeekAgo(total); // geçen hafta gün sonu kazanç
      setSumOfTheseTimesOfWeekAgo(sum); // geçen hafta bu saatlerdeki kazanç
    }
  }, [WeekAgoProductReport.data]);

  useEffect(() => {
    let diff = Math.round((100 * sumOfToday) / sumOfTheseTimesOfWeekAgo);
    diff = diff || 0;
    setDiff({ inc: 100 < diff, val: Math.abs(100 - diff) });
  }, [sumOfToday, sumOfTheseTimesOfWeekAgo]);
  return (
    <div className="flex flex-col h-full space-y-3">
      <div className="flex flex-col sm:flex-row justify-between">
        <div className="flex items-center space-x-2">
          <span className="text-xs font-medium text-gray-500 uppercase">
            {t('last')} {new Date().toLocaleDateString(localStorage.getItem("i18nextLng") || "tr-TR", { weekday: "long" })}{" "}
            {t('until-this-time')}
          </span>
          <div className="text-sm font-medium space-x-2">
            <span>{sumOfTheseTimesOfWeekAgo}{defaultCurrency()}</span>
          </div>
          <span className="text-sm text-gray-500 hidden md:inline">
           {t('sold')} {" "}
            <span className="hidden lg:inline">
              ({checksOfWeekAgo?.sum} {t('check')})
            </span>
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-xs font-medium text-gray-500 uppercase">
            {t('last')} {new Date().toLocaleDateString(localStorage.getItem("i18nextLng") || "tr-TR", { weekday: "long" })}{" "}
            {t('end-of-day')}
          </span>
          <div className="text-sm font-medium space-x-2">
            <span>{totalOfWeekAgo}{defaultCurrency()}</span>
          </div>
          <span className="text-sm text-gray-500 hidden lg:inline">
            ({checksOfWeekAgo?.total} {t('check')})
          </span>
        </div>
      </div>
      <div className="relative mt-auto w-full h-3 rounded-md bg-gray-200">
        <span
          style={{
            width: `${
              ((new Date().getHours() * 60 + new Date().getMinutes()) /
                (60 * 24)) *
              100
            }%`,
          }}
          className={`
                ${
                  diff.inc
                    ? "bg-teal-400"
                    : diff.val > 50
                    ? "bg-red-400"
                    : "bg-amber-400"
                }
                animate-pulse top-0 left-0 absolute rounded-md h-full
              `}
        ></span>
      </div>
      <div className="flex items-center space-x-2">
        <span className="text-xs font-medium text-gray-500 uppercase">
        {t('until-this-time-today')}
        </span>
        <div className="flex items-center">
          <span className="text-sm font-medium">{sumOfToday}{defaultCurrency()}</span>
          {sumOfTheseTimesOfWeekAgo != 0 ? (
            <div
              className={`${
                diff.inc
                  ? "text-teal-400"
                  : diff.val > 50
                  ? "text-red-400"
                  : "text-amber-400"
              } animate-pulse flex`}
            >
              {!diff.inc ? (
                <ArrowSmDownIcon className="ml-1 w-4 h-4 text-inherit" />
              ) : (
                <ArrowSmUpIcon className="ml-1 w-4 h-4 text-inherit" />
              )}
              <span className="text-xs">%{diff.val}</span>
            </div>
          ) : (
            <span className="ml-2 text-sm text-gray-500">
              ({t('no-sale-last-week')})
            </span>
          )}
        </div>
        <span className="text-sm text-gray-500 hidden lg:inline">
          ({t('table-occupancy')} {tableBusyRate})
        </span>
      </div>
    </div>
  );
}
