import { Service as HService } from "services/http";

export default class Branch {
  private Http: HService | null = null;

  constructor(http: HService) {
    this.Http = http;
  }

  public get endpoint() {
    return `${this.Http?.baseURL}/branches`;
  }

  public async getAll() {
    return await this.Http?.instance.get("/branches");
  }

  public async getSubBranches() {
    return await this.Http?.instance.get("/subbranch");
  }

  public async getPaymentMethods() {
    return await this.Http?.instance.get("/payment-methods");
  }

  public async editPaymentMethods(data: any) {
    return await this.Http?.instance.post("/payment-methods", data);
  }

  public async getGetirCredentials() {
    return await this.Http?.instance.get("/integration/getir/secret-key");
  }

  public async saveGetirCredentials(data: any) {
    return await this.Http?.instance.post(
      "/integration/getir/secret-key",
      data
    );
  }

  public async getMatchedGetirProducts() {
    return await this.Http?.instance.get("/integration/getir/matched-products");
  }

  public async saveMatchedGetirProducts(data: any) {
    return await this.Http?.instance.post(
      "/integration/getir/matched-products",
      data
    );
  }

  public async getMatchedGetirOptions() {
    return await this.Http?.instance.get("/integration/getir/matched-options");
  }

  public async saveMatchedGetirOptions(data: any) {
    return await this.Http?.instance.post(
      "/integration/getir/matched-options",
      data
    );
  }
}
