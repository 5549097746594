import React, { useContext, useEffect, useRef, useState } from "react";
import { Formik, FieldArray, FormikTouched, FormikErrors } from "formik";
import { array, object, string } from "yup";
import UString from "utilities/string";
import Spinner from "components/spinner";
import Survey from "services/survey";
import Serve from "services/serve";
import Allergen from "services/allergen";
import UserService from "services/userService";
import Lang from "services/language";
import Http from "services/http";
import Response from "services/response";
import { useNavigate, useParams, Navigate } from "react-router-dom";

import { get } from "lodash";
import { Listbox } from "@headlessui/react";
import Structures from "services/structures";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import CancelReason from "services/cancelreason";
import Notes from "services/note";

const lang = {
  survey: "survey",
  serve: "catering",
  service: "service",
  allergen: "allergen",
  cancel_reason: "cancel-reason",
  note: "note"
  //...
};

export default function () {
  const SurveyService = new Survey(useContext(Http.Context)!);
  const SelectedSurvey = new Response<{
    question: string;
    options: [];
    is_multi_choice: boolean;
  }>();

  const ServeService = new Serve(useContext(Http.Context)!);
  const SelectedServe = new Response();

  const AllergenService = new Allergen(useContext(Http.Context)!);
  const SelectedAllergen = new Response();

  const CancelReasonService = new CancelReason(useContext(Http.Context)!);
  const SelectedCancelReason = new Response();

  const NoteService = new Notes(useContext(Http.Context)!);
  const SelectedNote = new Response();

  const USService = new UserService(useContext(Http.Context)!);
  const SelectedService = new Response();

  const LService = new Lang(useContext(Http.Context)!);
  const Langs = new Response();
  const { t } = useTranslation();
  const [selectedLangs, setSelectedLangs] = useState<
    {
      lang: string;
      _id: string;
      description: string;
    }[]
  >();

  const Save = new Response();

  const Form = useRef<any>();
  const navigate = useNavigate();

  const { id, path } = useParams();

  useEffect(() => {
    if (id && path == "survey") {
      SelectedSurvey.handle(SurveyService.getSurvey(id));
    }
    if (id && path == "serve") {
      SelectedServe.handle(ServeService.getServe(id));
    }
    if (id && path == "service" && !Langs.data) {
      SelectedService.handle(USService.getService(id));
      Langs.handle(LService.get());
    }
    if (id && path == "allergen") {
      SelectedAllergen.handle(AllergenService.getAllergen(id));
    }
    if (id && path == "cancel_reason") {
      SelectedCancelReason.handle(CancelReasonService.getCancelReason(id));
    }
    if(id && path == "note") {
      SelectedNote.handle(NoteService.getNote(id));
    }
  }, [id && path]);

  useEffect(() => {
    if (Langs.data) {
      setSelectedLangs(
        Langs.data.items?.find((l: any) => l.itemId == id && l.type == 4)
          ?.locale
      );
    }
  }, [Langs.data]);

  useEffect(() => {
    if (Save.data && !Save.error) {
      toast.success(
        `${t(lang[path as keyof typeof lang])} ${id ? t('editing') : t('adding')
        } ${t('success')}.`
      );
      navigate(`/settings`);
    }
  }, [Save.data]);

  useEffect(() => {
    if (Save.error) {
      toast.error(
        `${t(lang[path as keyof typeof lang])} ${id ? t('editing') : t('adding')
        } ${t('failure')}. (${Save.error.message})`
      );
      navigate(`/settings`);
    }
  }, [Save.error]);

  if (path == "survey") {
    return (
      <>
        <div className="header-top">
          <h2 className="text-xl font-medium pl-2">
            {t('survey')} {id ? t('edit') : t('add')}
          </h2>
        </div>
        <div id="content">
          <Formik
            innerRef={Form}
            enableReinitialize={true}
            initialValues={{
              question: SelectedSurvey.data?.question || String(),
              options: SelectedSurvey.data?.options || ["", ""],
              is_multi_choice: SelectedSurvey.data?.is_multi_choice || false,
            }}
            validationSchema={object({
              question: string().required(t('required')!),
              options: array()
                .of(string().required(t('required')!))
                .min(2)
                .required(t('required')!),
            })}
            onSubmit={(values) =>
              Save.handle(
                id && SelectedSurvey.data
                  ? SurveyService.updateSurvey(id, values)
                  : SurveyService.createSurvey(values)
              )
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form className="action" onSubmit={handleSubmit}>
                <div className="wrapper">
                  <div className="item">
                    <div className="w-full">
                      <label htmlFor="question">{t('survey-question')}</label>
                      <input
                        type="text"
                        id="question"
                        name="question"
                        className={UString.concat_class_name(
                          errors.question && touched.question
                            ? "!border-red-500"
                            : undefined,
                          "my-1"
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('survey-placeholder')!}
                        value={values.question}
                      />
                      {errors.question && touched.question ? (
                        <span className="error">* {errors.question}</span>
                      ) : null}
                    </div>
                    <div className="w-full">
                      <label htmlFor={`is_multi_choice`}>
                        {t('allow-multi-choice')}
                      </label>
                      <label
                        htmlFor="is_multi_choice"
                        className="w-full classic-btn select-none mt-2 cursor-pointer"
                      >
                        <div className="flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id={`is_multi_choice`}
                              name={`is_multi_choice`}
                              type="checkbox"
                              checked={values?.is_multi_choice}
                              onChange={(event) =>
                                setFieldValue(
                                  `is_multi_choice`,
                                  event.target.checked
                                )
                              }
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded-full"
                            />
                          </div>
                          <div className="ml-2 text-sm">
                            <span className="font-medium text-gray-700">
                              {t('yes')}
                            </span>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div className="w-full">
                      <FieldArray
                        name="options"
                        render={({ insert, remove }) => (
                          <>
                            <div className="flex flex-col space-y-4 divide-y divide-gray-200 xl:divide-y-0">
                              {values.options.map((option: any, i: any) => {
                                return (
                                  <div
                                    className="flex flex-col xl:flex-row gap-4 w-full py-4 xl:py-0"
                                    key={i}
                                  >
                                    <div className="w-full">
                                      <label htmlFor={`options.[${i}]`}>
                                        {t('opt')} {i + 1}
                                      </label>
                                      <input
                                        type="text"
                                        name={`options.[${i}]`}
                                        className={UString.concat_class_name(
                                          touched.options &&
                                            errors.options?.at(i)
                                            ? "!border-red-500"
                                            : undefined,
                                          "my-1"
                                        )}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={t('opt-placeholder')!}
                                        value={option}
                                      />
                                      {touched.options && errors.options ? (
                                        typeof errors.options == "string" ? (
                                          <span className="error">
                                            * {t('min-2-option')}
                                          </span>
                                        ) : (
                                          errors.options.at(i) && (
                                            <span className="error">
                                              * {t('opt')} {i + 1} {t('require')}
                                            </span>
                                          )
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div className="xl:mt-6">
                                      <button
                                        className="classic-btn red w-full xl:w-auto"
                                        type="button"
                                        onClick={() =>
                                          values.options.length > 1 && remove(i)
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-5 w-5"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={1.5}
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="w-full pt-4 mt-4 border-t border-gray-200">
                              <button
                                className="w-full xl:ml-auto classic-btn justify-center"
                                type="button"
                                onClick={insert.bind(
                                  null,
                                  values.options.length + 1,
                                  String()
                                )}
                              >
                                {t('add-opt')}
                              </button>
                            </div>
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="header-bottom">
          <button
            type="submit"
            disabled={Save.loading}
            className="ml-auto classic-btn"
            onClick={() => Form.current.handleSubmit()}
          >
            {Save.loading ? <Spinner className="h-5 m-auto" /> : t('save')}
          </button>
        </div>
      </>
    );
  }

  if (path == "serve") {
    return (
      <>
        <div className="header-top">
          <h2 className="text-xl font-medium pl-2">
            {t('serve')} {id ? t('edit') : t('add')}
          </h2>
        </div>
        <div id="content">
          <Formik
            innerRef={Form}
            enableReinitialize={true}
            initialValues={{
              title: SelectedServe.data?.title || String(),
              description: SelectedServe.data?.description || String(),
            }}
            validationSchema={object({
              title: string().required(t('required')!),
              description: string().required(t('required')!),
            })}
            onSubmit={(values) =>
              Save.handle(
                id && SelectedServe.data
                  ? ServeService.updateServe(id, values)
                  : ServeService.createServe(values)
              )
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form className="action" onSubmit={handleSubmit}>
                <div className="wrapper">
                  <div className="item">
                    <div className="w-full">
                      <label htmlFor="title">{t('catering-title')}</label>
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className={UString.concat_class_name(
                          errors.title && touched.title
                            ? "!border-red-500"
                            : undefined,
                          "my-1"
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('catering-placeholder')!}
                        value={values.title}
                      />
                      {errors.title && touched.title ? (
                        <span className="error">* {errors.title}</span>
                      ) : null}
                    </div>

                    <div className="w-full">
                      <label htmlFor="description">{t('catering-description')}</label>
                      <input
                        type="text"
                        id="description"
                        name="description"
                        className={UString.concat_class_name(
                          errors.description && touched.description
                            ? "!border-red-500"
                            : undefined,
                          "my-1"
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('catering-desc-placeholder')!}
                        value={values.description}
                      />
                      {errors.description && touched.description ? (
                        <span className="error">* {errors.description}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="header-bottom">
          <button
            type="submit"
            disabled={Save.loading}
            className="ml-auto classic-btn"
            onClick={() => Form.current.handleSubmit()}
          >
            {Save.loading ? <Spinner className="h-5 m-auto" /> : t('save')}
          </button>
        </div>
      </>
    );
  }

  if (path == "service") {
    return (
      <>
        <div className="header-top">
          <h2 className="text-xl font-medium pl-2">
            {t('service')} {id ? t('edit') : t('add')}
          </h2>
        </div>
        <div id="content">
          <Formik
            innerRef={Form}
            enableReinitialize={true}
            initialValues={{
              description: SelectedService.data?.description || String(),
              lang: {
                locale: selectedLangs || [
                  {
                    lang: "tr", // TODO: must be branch's default language
                    description: SelectedService.data?.description || String(),
                  },
                ],
              },
            }}
            validationSchema={object({
              description: string().required(t('required')!),
              lang: object({
                locale: array()
                  .of(
                    object({
                      lang: string().required(t('required')!),
                      description: string().required(
                        t('required')!
                      ),
                    }).required()
                  )
                  .required(t('required')!),
              }).required(t('required')!),
            })}
            onSubmit={(values) => {
              let data = {
                service: { description: values.description },
                lang: values.lang,
              };
              Save.handle(
                id && SelectedService.data
                  ? USService.update(id, data)
                  : USService.add(data)
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form className="action" onSubmit={handleSubmit}>
                <div className="wrapper">
                  <div className="item">
                    <div className="w-full">
                      <FieldArray
                        name="lang.locale"
                        render={({ insert, remove }) => (
                          <>
                            <div className="pt-4 space-y-4">
                              {values?.lang?.locale?.length ? (
                                values?.lang?.locale?.map(
                                  (language: any, index: number) => {
                                    return (
                                      <div
                                        className="flex flex-col"
                                        key={index}
                                      >
                                        <div className="flex flex-col xl:flex-row gap-4 w-full">
                                          <div className="w-full xl:w-1/3">
                                            <label
                                              htmlFor={`lang.locale.[${index}].lang`}
                                            >
                                              {t('language')}
                                            </label>
                                            <Listbox
                                              value={language.lang}
                                              onChange={(value) =>
                                                setFieldValue(
                                                  `lang.locale.[${index}].lang`,
                                                  value
                                                )
                                              }
                                            >
                                              <div className="relative mt-1">
                                                <Listbox.Button className="listbox-btn">
                                                  <span className="listbox-title">
                                                    {
                                                      Structures.types.lang.find(
                                                        (role) =>
                                                          language.lang ===
                                                          role.value
                                                      )?.title
                                                    }
                                                  </span>
                                                  <span className="listbox-selector-icon">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      className="w-5 h-5 text-gray-400"
                                                      viewBox="0 0 20 20"
                                                      fill="currentColor"
                                                    >
                                                      <path
                                                        fillRule="evenodd"
                                                        d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                      />
                                                    </svg>
                                                  </span>
                                                </Listbox.Button>
                                                <Listbox.Options className="listbox-options">
                                                  {Structures.types.lang.map(
                                                    (value) => (
                                                      <Listbox.Option
                                                        className={UString.concat_class_name(
                                                          language.lang ===
                                                            value.value
                                                            ? "bg-ebony text-white"
                                                            : "hover:bg-ebony hover:text-white",
                                                          "flex items-center cursor-pointer px-6 py-2"
                                                        )}
                                                        key={value.value}
                                                        value={value.value}
                                                      >
                                                        <span>
                                                          {value.title}
                                                        </span>
                                                      </Listbox.Option>
                                                    )
                                                  )}
                                                </Listbox.Options>
                                              </div>
                                            </Listbox>
                                          </div>

                                          <div className="w-full xl:w-2/3">
                                            <label
                                              htmlFor={`lang.locale.[${index}].description`}
                                            >
                                              {t('service-description')}
                                            </label>
                                            <input
                                              type="text"
                                              name={`lang.locale.[${index}].description`}
                                              className={UString.concat_class_name(
                                                get(
                                                  errors,
                                                  `lang.locale.[${index}].description`
                                                ) &&
                                                  get(
                                                    touched,
                                                    `lang.locale.[${index}].description`
                                                  )
                                                  ? "!border-red-500 !ring-red-500"
                                                  : undefined,
                                                "flex mt-1"
                                              )}
                                              onChange={(value) => {
                                                if (index == 0) {
                                                  setFieldValue(
                                                    `description`,
                                                    value.target.value
                                                  );
                                                }
                                                handleChange(value);
                                              }}
                                              onBlur={handleBlur}
                                              placeholder={t('lang-placeholder')!}
                                              value={
                                                language?.description ||
                                                String()
                                              }
                                            />
                                            {get(
                                              errors,
                                              `lang.locale.[${index}].description`
                                            ) &&
                                              get(
                                                touched,
                                                `lang.locale.[${index}].description`
                                              ) ? (
                                              <span className="error">
                                                *{" "}
                                                {get(
                                                  errors,
                                                  `lang.locale.[${index}].description`
                                                )}
                                              </span>
                                            ) : null}
                                          </div>
                                          {index != 0 && (
                                            <div className="xl:mt-6">
                                              <button
                                                className="classic-btn justify-center red w-full "
                                                type="button"
                                                onClick={() => remove(index)}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  className="h-5 w-5"
                                                  fill="none"
                                                  viewBox="0 0 24 24"
                                                  stroke="currentColor"
                                                >
                                                  <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={1.5}
                                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                                  />
                                                </svg>
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <div>{t('no-language')}</div>
                              )}
                              <div className="border-t border-gray-300 pt-4">
                                <button
                                  className="w-full xl:ml-auto classic-btn justify-center"
                                  type="button"
                                  onClick={insert.bind(
                                    null,
                                    values?.lang?.locale?.length + 1,
                                    {
                                      lang: "en",
                                      description: String(),
                                    }
                                  )}
                                >
                                  {t('add-lang')}
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="header-bottom">
          <button
            type="submit"
            disabled={Save.loading}
            className="ml-auto classic-btn"
            onClick={() => Form.current.handleSubmit()}
          >
            {Save.loading ? <Spinner className="h-5 m-auto" /> : t('save')}
          </button>
        </div>
      </>
    );
  }

  if (path == "allergen") {
    return (
      <>
        <div className="header-top">
          <h2 className="text-xl font-medium pl-2">
            {t('allergen')} {id ? t('edit') : t('add')}
          </h2>
        </div>
        <div id="content">
          <Formik
            innerRef={Form}
            enableReinitialize={true}
            initialValues={{
              title: SelectedAllergen.data?.title || String(),
            }}
            validationSchema={object({
              title: string().required(t('required')!),
            })}
            onSubmit={(values) =>
              Save.handle(
                id && SelectedAllergen.data
                  ? AllergenService.update(id, values)
                  : AllergenService.create(values)
              )
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form className="action" onSubmit={handleSubmit}>
                <div className="wrapper">
                  <div className="item">
                    <div className="w-full">
                      <label htmlFor="title">
                        {t('can-create-allergen')}
                      </label>
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className={UString.concat_class_name(
                          errors.title && touched.title
                            ? "!border-red-500"
                            : undefined,
                          "my-1"
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('allergen-placeholder')!}
                        value={values.title}
                      />
                      {errors.title && touched.title ? (
                        <span className="error">* {errors.title}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="header-bottom">
          <button
            type="submit"
            disabled={Save.loading}
            className="ml-auto classic-btn"
            onClick={() => Form.current.handleSubmit()}
          >
            {Save.loading ? <Spinner className="h-5 m-auto" /> : t('save')}
          </button>
        </div>
      </>
    );
  }
  if (path == "cancel_reason") {
    return (
      <>
        <div className="header-top">
          <h2 className="text-xl font-medium pl-2">
            {t('cancel-reason')} {id ? t('edit') : t('add')}
          </h2>
        </div>
        <div id="content">
          <Formik
            innerRef={Form}
            enableReinitialize={true}
            initialValues={{
              title: SelectedCancelReason.data?.title || String(),
            }}
            validationSchema={object({
              title: string().required(t('required')!),
            })}
            onSubmit={(values) =>
              Save.handle(
                id && SelectedCancelReason.data
                  ? CancelReasonService.updateCancelReason(id, values)
                  : CancelReasonService.createCancelReason(values)
              )
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form className="action" onSubmit={handleSubmit}>
                <div className="wrapper">
                  <div className="item">
                    <div className="w-full">
                      <label htmlFor="title">
                        {t('reason')}
                      </label>
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className={UString.concat_class_name(
                          errors.title && touched.title
                            ? "!border-red-500"
                            : undefined,
                          "my-1"
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('cancel-reason-placeholder')!}
                        value={values.title}
                      />
                      {errors.title && touched.title ? (
                        <span className="error">* {errors.title}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="header-bottom">
          <button
            type="submit"
            disabled={Save.loading}
            className="ml-auto classic-btn"
            onClick={() => Form.current.handleSubmit()}
          >
            {Save.loading ? <Spinner className="h-5 m-auto" /> : t('save')}
          </button>
        </div>
      </>
    )
  }

  if (path == "note") {
    return (
      <>
        <div className="header-top">
          <h2 className="text-xl font-medium pl-2">
            {t('note')} {id ? t('edit') : t('add')}
          </h2>
        </div>
        <div id="content">
          <Formik
            innerRef={Form}
            enableReinitialize={true}
            initialValues={{
              title: SelectedNote.data?.title || String(),
              note: SelectedNote.data?.note || String(),
            }}
            validationSchema={object({
              title: string().required(t('required')!),
              note: string().required(t('required')!),
            })}
            onSubmit={(values) =>
              Save.handle(
                id && SelectedNote.data
                  ? NoteService.updateNote(id, values)
                  : NoteService.createNote(values)
              )
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form className="action" onSubmit={handleSubmit}>
                <div className="wrapper">
                  <div className="item">
                  <div className="w-full">
                      <label htmlFor="note">
                        {t('title')}
                      </label>
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className={UString.concat_class_name(
                          errors.title && touched.title
                            ? "!border-red-500"
                            : undefined,
                          "my-1"
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('note')!}
                        value={values.title}
                      />
                      {errors.title && touched.title ? (
                        <span className="error">* {errors.title}</span>
                      ) : null}
                    </div>
                    <div className="w-full">
                      <label htmlFor="note">
                        {t('note')}
                      </label>
                      <input
                        type="text"
                        id="note"
                        name="note"
                        className={UString.concat_class_name(
                          errors.note && touched.note
                            ? "!border-red-500"
                            : undefined,
                          "my-1"
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={t('note')!}
                        value={values.note}
                      />
                      {errors.note && touched.note ? (
                        <span className="error">* {errors.note}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="header-bottom">
          <button
            type="submit"
            disabled={Save.loading}
            className="ml-auto classic-btn"
            onClick={() => Form.current.handleSubmit()}
          >
            {Save.loading ? <Spinner className="h-5 m-auto" /> : t('save')}
          </button>
        </div>
      </>
    )
  }

  /*

  if (path == "catering") {
    return (
      <>
        <div className="header-top">
          <h2 className="text-xl font-medium pl-2">
            İkram {id ? "düzenle" : "ekle"}
          </h2>
        </div>
        <div id="content">
          <Formik
            innerRef={Form}
            enableReinitialize={true}
            initialValues={{
              title: Fetch.data?.title || String(),
            }}
            validationSchema={object({
              title: string().required("İkram is required"),
            })}
            onSubmit={(values) =>
              Save.handle(
                id && Fetch.data
                  ? Service.update(id, values)
                  : Service.create(values)
              )
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form className="action" onSubmit={handleSubmit}>
                <div className="wrapper">
                  <div className="item">
                    <div className="w-full">
                      <label htmlFor="title">Bir İkram Türü Giriniz</label>
                      <input
                        type="text"
                        name="title"
                        className={UString.concat_class_name(
                          errors.title && touched.title
                            ? "has-error"
                            : undefined,
                          "my-1"
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Kişisel İkram"
                        value={values.title}
                      />
                      {errors.title && touched.title ? (
                        <span className="error">* {errors.title}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="header-bottom">
          <button
            type="submit"
            disabled={Save.loading}
            className="ml-auto classic-btn"
            onClick={() => Form.current.handleSubmit()}
          >
            {Save.loading ? <Spinner className="h-5 m-auto" /> : "Kaydet"}
          </button>
        </div>
      </>
    );
  }
  if (path == "cancel") {
    return (
      <>
        <div className="header-top">
          <h2 className="text-xl font-medium pl-2">
            İptal Seçeneği {id ? "düzenle" : "ekle"}
          </h2>
        </div>
        <div id="content">
          <Formik
            innerRef={Form}
            enableReinitialize={true}
            initialValues={{
              title: Fetch.data?.title || String(),
            }}
            validationSchema={object({
              title: string().required("İptal is required"),
            })}
            onSubmit={(values) =>
              Save.handle(
                id && Fetch.data
                  ? Service.update(id, values)
                  : Service.create(values)
              )
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form className="action" onSubmit={handleSubmit}>
                <div className="wrapper">
                  <div className="item">
                    <div className="w-full">
                      <label htmlFor="title">Bir İptal Seçeneği Giriniz</label>
                      <input
                        type="text"
                        name="title"
                        className={UString.concat_class_name(
                          errors.title && touched.title
                            ? "has-error"
                            : undefined,
                          "my-1"
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Servis İptali"
                        value={values.title}
                      />
                      {errors.title && touched.title ? (
                        <span className="error">* {errors.title}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="header-bottom">
          <button
            type="submit"
            disabled={Save.loading}
            className="ml-auto classic-btn"
            onClick={() => Form.current.handleSubmit()}
          >
            {Save.loading ? <Spinner className="h-5 m-auto" /> : "Kaydet"}
          </button>
        </div>
      </>
    );
  }
  if (path == "notes") {
    return (
      <>
        <div className="header-top">
          <h2 className="text-xl font-medium pl-2">
            Not {id ? "düzenle" : "ekle"}
          </h2>
        </div>
        <div id="content">
          <Formik
            innerRef={Form}
            enableReinitialize={true}
            initialValues={{
              title: Fetch.data?.title || String(),
            }}
            validationSchema={object({
              title: string().required("Not is required"),
            })}
            onSubmit={(values) =>
              Save.handle(
                id && Fetch.data
                  ? Service.update(id, values)
                  : Service.create(values)
              )
            }
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form className="action" onSubmit={handleSubmit}>
                <div className="wrapper">
                  <div className="item">
                    <div className="w-full">
                      <label htmlFor="title">Bir Not Ekleyiniz</label>
                      <textarea
                        name="title"
                        className={UString.concat_class_name(
                          errors.title && touched.title
                            ? "has-error"
                            : undefined,
                          "my-1"
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Not Ekleyiniz"
                        value={values.title}
                      />
                      {errors.title && touched.title ? (
                        <span className="error">* {errors.title}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="header-bottom">
          <button
            type="submit"
            disabled={Save.loading}
            className="ml-auto classic-btn"
            onClick={() => Form.current.handleSubmit()}
          >
            {Save.loading ? <Spinner className="h-5 m-auto" /> : "Kaydet"}
          </button>
        </div>
      </>
    );
  } */
  return <Navigate to="/settings" replace={true} />;
}
