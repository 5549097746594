import MenuItem, { IItem } from "components/sidebar/menu-item";
import { useContext } from "react";
import Authenticate from "services/authenticate";

export default function ({ data }: { data: IItem[] }) {
  const context = useContext(Authenticate.Context!);

  if (context?.User?.permissions?.includes(301)) {
    return (
      <nav id="menu">
        <ul className="space-y-2">
          {data.map((item) => (
            <MenuItem data={item} key={item.url} />
          ))}
        </ul>
      </nav>
    );
  }
  return (
    <nav id="menu">
      <ul className="space-y-2">
        {data
          .filter(
            (d: any) => d.label != "Ürün Grupları" && d.label != "Şubelerim"
          )
          .map((item) => (
            <MenuItem data={item} key={item.url} />
          ))}
      </ul>
    </nav>
  );
}
