import { useContext, useEffect, useRef, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";

import Http from "services/http";
import Check from "services/check";
import Case from "services/case";
import Member from "services/member";
import Response from "services/response";
import CTable from "services/table";

import { payment_types } from "services/interfaces/case/payment_types";
import { CheckType } from "services/interfaces/case/checkType";

import { convertCurrency } from "services/helpers/exchange";
import Loading from "components/loading";
import NetworkError from "components/network-error";
import TableComponent from "components/table";
import useCache from "services/useCache";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TableContainer,
  TableRow,
  TableCell,
  Typography,
  Toolbar,
  Table,
  TableBody,
  TableHead,
  Paper,
  Box,
  Stack,
} from "@mui/material";

export default function () {
  const { id } = useParams();
  const navigate = useNavigate();
  const [viewDialog, setViewDialog] = useState(false);
  const [check, setCheck]: any = useState({});
  const { t } = useTranslation();
  useEffect(() => (!id && navigate(-1), undefined), [id]);

  const Services = {
    Check: new Check(useContext(Http.Context)!),
    Member: new Member(useContext(Http.Context)!),
    Table: new CTable(useContext(Http.Context)!),
    Case: new Case(useContext(Http.Context)!),
  };

  let Fetchs = {
    Checks: new Response<CheckType[]>(),
    Members: new Response(),
    Tables: useCache(Services.Table),
  };

  useEffect(
    () => (
      !Fetchs.Checks.data && Fetchs.Checks.handle(Services.Check.getById(id!)),
      undefined
    ),
    [Fetchs.Checks.data]
  );

  useEffect(() => {
    const fetch = async () => {
      let c = await Services.Case.getById(id!);
      Fetchs.Members.handle(Services.Member.getByBranchId(c.data.branch));
    };
    fetch();
  }, []);

  const PaymentTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("payment-type")}</TableCell>
              <TableCell>{t("amount")}</TableCell>
              <TableCell>{t("currency")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {check?.payments?.map((payment: any) => (
              <TableRow>
                <TableCell>
                  {t(payment_types[payment?.type - 1]?.title)}
                </TableCell>
                <TableCell>{payment.amount}</TableCell>
                <TableCell>{payment.currency}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const ProductTable = (props: { type: string }) => {
    var products: any = [];
    if (props.type == "caterings") {
      check?.orders.map((order: any) => {
        order?.products
          ?.filter((p: any) => p.status != 0 && p.isServe)
          ?.map((p: any) => products.push(p));
      });
    } else if (props.type == "cancelled-products") {
      check?.orders.map((order: any) => {
        order?.products
          ?.filter((p: any) => p.status == 0)
          ?.map((p: any) => products.push(p));
      });
    } else {
      check?.orders.map((order: any) => {
        order?.products
          ?.filter((p: any) => p.status != 0 && !p.isServe)
          ?.map((p: any) => products.push(p));
      });
    }
    check?.orders.map((order: any) => {
      order?.products
        ?.filter((p: any) => p.status != 0 && p.isServe)
        ?.map((p: any) => products.push(p));
    });
    if (products.length == 0) return null;
    return (
      <>
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {t(props.type)}
          </Typography>
        </Toolbar>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("product-name")}</TableCell>
                <TableCell>{t("quantity")}</TableCell>
                <TableCell>{t("amount")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products?.map((product: any) => (
                <TableRow>
                  <TableCell>{product.productName}</TableCell>
                  <TableCell>{product.quantity}</TableCell>
                  <TableCell>{product.price}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };

  const DiscountTable = () => {
    if (check?.discounts?.length == 0) return null;
    return (
      <>
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {t("applied-discounts")}
          </Typography>
        </Toolbar>
        <TableContainer>
          <TableHead>
            <TableRow>
              <TableCell>{t("discounts")}</TableCell>
              <TableCell>{t("amount")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {check?.discounts?.map((discount: any) => (
              <TableRow>
                <TableCell>{discount.title}</TableCell>
                <TableCell>{discount.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
      </>
    );
  };

  const ViewDialog = () => {
    return (
      <Dialog
        open={viewDialog}
        onClose={() => setViewDialog(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Fiş Detayı</DialogTitle>
        <DialogContent sx={{ overflow: "scroll" }}>
          <Stack direction="row" spacing={4} sx={{ flexWrap: "wrap", gap: 4 }}>
            <Box sx={{ width: "33%" }}>
              <Toolbar>
                <Typography
                  sx={{ flex: "1 1 100%" }}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  {t("payments")}
                </Typography>
              </Toolbar>
              <PaymentTable />
            </Box>
            {["caterings", "cancelled-products", "sold-products"].map(
              (type: string) => (
                <Box>
                  <ProductTable {...{ type: type }} />
                </Box>
              )
            )}
            <Box>
              <DiscountTable />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setViewDialog(false)}>
            {"cancel"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <div className="header-top">
        <ViewDialog />
        <h2 className="text-xl font-medium pl-2">{t("ticket-list")}</h2>
      </div>
      <div id="content">
        <Loading done={!Fetchs.Checks.loading} />
        {!Fetchs.Checks.loading ? (
          <NetworkError
            error={Fetchs.Checks.error}
            retry={() => Fetchs.Checks.handle(Services.Check.getById(id!))}
          />
        ) : null}
        {Fetchs.Checks.data && Fetchs.Members.data && Fetchs.Tables.data && (
          <TableComponent
            data={{
              head: [
                { element: t("customer-waiter-name"), props: { scope: "col" } },
                { element: t("table"), props: { scope: "col" } },
                { element: t("section"), props: { scope: "col" } },
                { element: t("date"), props: { scope: "col" } },
                { element: t("price"), props: { scope: "col" } },
                { element: t("payment-type"), props: { scope: "col" } },
                { element: t("actions"), props: { scope: "col" } },
              ],
              body: Fetchs.Checks.data.map((check: CheckType) => ({
                id: check._id,
                elements: [
                  {
                    element: (
                      <div className="flex items-center">
                        <div className="text-sm font-medium text-gray-900">
                          {Fetchs.Members.data.find(
                            (worker: any) => worker._id == check.user
                          )?.name +
                            " " +
                            Fetchs.Members.data.find(
                              (worker: any) => worker._id == check.user
                            )?.lastname}
                        </div>
                      </div>
                    ),
                    props: { className: "text-left text-sm text-gray-900" },
                  },
                  {
                    element: (
                      <div className="flex items-center">
                        <div className="text-sm font-medium text-gray-900">
                          {
                            Fetchs.Tables.data.find(
                              (table: any) => table.id == check.table
                            )?.title
                          }
                        </div>
                      </div>
                    ),
                    props: { className: "text-left text-sm text-gray-900" },
                  },
                  {
                    element: (
                      <div className="flex items-center">
                        <div className="text-sm font-medium text-gray-900">
                          {check.sectionTitle}
                        </div>
                      </div>
                    ),
                    props: { className: "text-left text-sm text-gray-900" },
                  },
                  {
                    element: new Date(check.createdAt).toLocaleDateString(
                      "tr-TR",
                      {
                        minute: "numeric",
                        hour: "numeric",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }
                    ),
                    props: { className: "text-left text-sm text-gray-900" },
                  },
                  {
                    element: (
                      <div className="flex items-center">
                        <div className="text-sm font-medium text-gray-900">
                          {convertCurrency(
                            check.payments?.reduce(
                              (accumulator: any, currentValue: any) => {
                                if (
                                  currentValue.type !== 16 &&
                                  currentValue.type !== 14 &&
                                  currentValue.type !== 6
                                ) {
                                  return accumulator + currentValue.amount;
                                }
                                return accumulator;
                              },
                              0
                            )
                          ) +
                            " " +
                            (check.payments.length > 0
                              ? check.payments[0]?.currency
                              : "")}
                        </div>
                      </div>
                    ),
                    props: { className: "text-left text-sm text-gray-900" },
                  },
                  {
                    element: (
                      <div className="flex items-center">
                        <div className="text-sm font-medium text-gray-900">
                          {check.payments.length > 1
                            ? check.payments.reduce(
                                (accumulator: any, currentValue: any) =>
                                  accumulator +
                                  " | " +
                                  t(
                                    payment_types[currentValue.type - 1]?.title
                                  ),
                                " "
                              )
                            : t(
                                payment_types[check.payments[0]?.type - 1]
                                  ?.title
                              )}
                        </div>
                      </div>
                    ),
                    props: { className: "text-left text-sm text-gray-900" },
                  },
                  {
                    element: (
                      <div className="flex items-center">
                        <div className="text-sm font-medium text-gray-900">
                          <Button
                            variant="contained"
                            onClick={() => {
                              setCheck(check);
                              setViewDialog(true);
                            }}
                            color="inherit"
                          >
                            {t("view")}
                          </Button>
                        </div>
                      </div>
                    ),
                    props: { className: "text-left text-sm text-gray-900" },
                  },
                ],
              })),
            }}
          />
        )}
      </div>
    </>
  );
}
