import React, { useState, useContext, useEffect, useRef } from 'react'
import { Box, Tab, Grid, Paper, Button, Stack, TextField, Avatar, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem, Checkbox, ListItemText } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import useCache from "services/useCache";
import Http from "services/http";
import ProductGroup from "services/product-group";
import Product from "services/product";
import { Formik, FieldArray } from "formik";
import Response from "services/response";
import { array, object, string, number } from 'yup';
import DeleteIcon from '@mui/icons-material/Delete';
import { get } from 'lodash';
import structures from 'services/structures';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const order_types = [
    {
        label: "waiter-pos-order",
        value: 1
    },
    {
        label: "qr-code-order",
        value: 2
    },
    {
        label: "home-delivery",
        value: 3
    },
    {
        label: "takeaway",
        value: 4
    },
    {
        label: "case-sale",
        value: 5
    },
]


export default function Edit() {
    const ProductGroupService = new ProductGroup(useContext(Http.Context)!);
    const ProductService = new Product(useContext(Http.Context)!);
    const Group = new Response();
    const Form = useRef<any>();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [value, setValue] = useState('1');
    const [products, setProducts]: any = useState();

    const params = window.location.pathname;
    const id: any = params.split('/')[3];
    const [selectedProduct, setSelectedProduct]: any = useState();
    const [search, setSearch] = useState("");
    useEffect(() => {
        Group.handle(ProductGroupService.getProductGroup(id));
        const fetch = async () => {
            let p: any = await ProductService.getAll();
            setProducts(p.data);
        }
        fetch();
    }, [])

    const handleTabChange = (event: any, newValue: string) => {
        setValue(newValue);
    }
    return (
        <Box sx={{ overflow: "scroll" }}>

            {Group.data && products && (
                <>
                    <Formik
                        innerRef={Form}
                        validationSchema={object({
                            title: string().required(t('required')!),
                            groupProducts: array(),
                            products: array()
                        })}
                        initialValues={{
                            title: Group?.data?.title,
                            groupProducts: Group?.data?.products,
                            products: products,
                            createdAt: Group?.data?.createdAt
                        }}
                        onSubmit={async (values) => {
                            let data = {
                                products: values.groupProducts,
                                title: values.title
                            };
                            ProductGroupService.updateProductGroup(id, data);
                            toast.success(t('product-group-update-success'), {autoClose: 12000});
                            navigate('/product-group')
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                        }) => (
                            <>
                                <form onSubmit={handleSubmit}>
                                    <Box sx={{ marginLeft: "2vw", marginBottom: "6vw", marginTop: "2vw" }}>
                                        <Stack direction="row" spacing={6}>
                                            <Box>
                                                <Stack sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} direction="row">
                                                    <TextField label={t('product-group-name')} name="title" id="title" value={values.title} onChange={handleChange} onBlur={handleBlur} error={errors.title && touched.title ? true : false} helperText={errors.title && touched.title ? (
                                                        <span className="error">{errors.title}</span>
                                                    ) : null} />
                                                </Stack>
                                            </Box>
                                            <span><strong>{t('created-at')}: </strong>{new Date(values.createdAt).toLocaleDateString()}</span>
                                        </Stack>
                                        <Button type="submit" variant="contained" color="success" sx={{marginTop: "3vw"}}>{t('save')}</Button>

                                    </Box>
                                    <TabContext value={value}>
                                        <Box>
                                            <TextField sx={{ margin: "auto", alignSelf: "center", display: "flex", alignSelft: "center", width: "30%", marginBottom: "2vw" }} label="Ara" value={search} onChange={(e: any) => setSearch(e.target.value)} />
                                            <TabList onChange={handleTabChange} sx={{
                                                margin: "auto",
                                                alignSelf: "center",
                                                width: "50%",
                                                "& button": { color: "#fff", backgroundColor: "#005DB4" },
                                                "& button:hover": { color: "#fff", backgroundColor: "#01417E" },
                                                "& button:focus": { color: "#fff", backgroundColor: "green" },
                                                "& button:Mui-selected": { color: "#fff", backgroundColor: "green" },
                                            }}>
                                                <Tab label={t('branchs-products')} value="1" />
                                                <Tab label={t('groups-products')} value="2" />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="1">
                                            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                                                {values.products?.filter((product: any) => !values?.groupProducts?.find((p: any) => p.id == product.id)).length == 0 ? (
                                                    <>
                                                        <span>{t('no-more-product')}</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        {values.products?.filter((product: any) => !values.groupProducts?.find((p: any) => p.id == product.id) && product.title.includes(search || "")).map((product: any) => (
                                                            <Grid key={product.title} xs={3} item>
                                                                <Paper onClick={() => {
                                                                    setValue('2');
                                                                    setSelectedProduct(product);
                                                                }} sx={{
                                                                    backgroundColor: "#1A2027",
                                                                    cursor: "pointer",
                                                                    '&:hover': {
                                                                        backgroundColor: "#9A9797",
                                                                        transform: "scale(1.1)",
                                                                    }
                                                                }}>
                                                                    <Box>
                                                                        <Stack direction="column" spacing={3}>
                                                                            <Avatar variant="square" src={product.image} sx={{ margin: "auto", width: "100%", height: "5vw" }} />
                                                                            <Stack direction="column" spacing={1} sx={{ marginBottom: "4vw", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                                                <span style={{ color: "#fff" }}><strong>{t('product-name')}: </strong>{product.title}</span>
                                                                                <span style={{ color: "#fff" }}><strong>{t('price')}: </strong>{product.prices[0].price} {product.prices[0].currency}</span>
                                                                            </Stack>
                                                                        </Stack>

                                                                    </Box>
                                                                </Paper>
                                                            </Grid>
                                                        ))}
                                                    </>
                                                )}
                                            </Grid>
                                        </TabPanel>
                                        <TabPanel value="2">
                                            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                                                {values.groupProducts?.length == 0 ? (
                                                    <>
                                                        <span>{t('no-product-in-group')} <a style={{ cursor: "pointer", fontWeight: "bold" }} onClick={() => setValue('1')}>{t('products-in-branch')}</a> {t('can-add-to-group')}</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <FieldArray
                                                            name="groupProducts"
                                                            render={({ insert, remove }) => (
                                                                <>
                                                                    {values.groupProducts?.filter((product: any) => product.title.includes(search || "")).map((product: any, index: number) => (
                                                                        <Grid key={product._id} xs={3} item>
                                                                            <Paper sx={{
                                                                                backgroundColor: "#1A2027",
                                                                                cursor: "pointer",
                                                                                '&:hover': {
                                                                                    backgroundColor: "#9A9797",
                                                                                    transform: "scale(1.1)",
                                                                                }
                                                                            }}
                                                                                onClick={() => {
                                                                                    setSelectedProduct(product);
                                                                                }}
                                                                            >
                                                                                <Box>
                                                                                    <Stack direction="column" spacing={3}>
                                                                                        <Avatar variant="square" src={product.image} sx={{ margin: "auto", width: "100%", height: "5vw" }} />
                                                                                        <Stack direction="column" spacing={1} sx={{ marginBottom: "4vw", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                                                            <span style={{ color: "#fff" }}><strong>{t('product-name')}: </strong>{product.title}</span>
                                                                                            <span style={{ color: "#fff" }}><strong>{t('price')}: </strong>{product.prices[0].price} {product.prices[0].currency}</span>
                                                                                            <IconButton color="error" onClick={(e: any) => {
                                                                                                e.stopPropagation();
                                                                                                remove(index);
                                                                                            }}><DeleteIcon /></IconButton>
                                                                                        </Stack>
                                                                                    </Stack>
                                                                                </Box>
                                                                            </Paper>
                                                                        </Grid>
                                                                    ))}
                                                                    <Dialog open={selectedProduct ? true : false} onClose={() => setSelectedProduct(null)} maxWidth='lg' fullWidth>
                                                                        <DialogTitle>{t('product-edit')}</DialogTitle>
                                                                        {selectedProduct && (
                                                                            <>
                                                                                <Formik
                                                                                    validationSchema={object({
                                                                                        product: object({
                                                                                            title: string().required(t('required')!),
                                                                                            prices: array().of(object({
                                                                                                priceName: string().required(t('required')!),
                                                                                                price: number().required(t('required')!),
                                                                                                vat_rate: number().required(t('required')!)
                                                                                            }))
                                                                                        })
                                                                                    })}
                                                                                    initialValues={{
                                                                                        product: selectedProduct || Object(),
                                                                                    }}
                                                                                    onSubmit={(values) => {

                                                                                    }}
                                                                                >
                                                                                    {({
                                                                                        values,
                                                                                        errors,
                                                                                        touched,
                                                                                        handleChange,
                                                                                        handleBlur,
                                                                                        handleSubmit,
                                                                                        setFieldValue,
                                                                                    }) => (
                                                                                        <>
                                                                                            <DialogContent>
                                                                                                <TextField
                                                                                                    label={t('product-name')}
                                                                                                    name="product.title"
                                                                                                    value={get(values, 'product.title')}
                                                                                                    onChange={handleChange}
                                                                                                    onBlur={handleBlur}
                                                                                                    error={get(errors, 'product.title') && get(touched, 'product.title') ? true : false}
                                                                                                    helperText={get(errors, 'product.title') && get(touched, 'product.title') ? (<span>{get(errors, 'product.title')}</span>) : null}
                                                                                                />
                                                                                                <Box sx={{marginTop: "1.5vw", marginBottom: "1vw"}}>
                                                                                                    <FieldArray
                                                                                                        name="product.active_list"
                                                                                                        render={(array) => (
                                                                                                            <div className="w-full flex flex-wrap -my-1 -mx-4">
                                                                                                                {Object.keys(structures.types.order).map(
                                                                                                                    (order, index) => (
                                                                                                                        <div
                                                                                                                            className="flex items-start my-1 px-4"
                                                                                                                            key={index}
                                                                                                                        >
                                                                                                                            <div className="flex items-center h-5">
                                                                                                                                <input
                                                                                                                                    id={`active_list.[${index}]`}
                                                                                                                                    name={`active_list.[${index}]`}
                                                                                                                                    type="checkbox"
                                                                                                                                    value={order}
                                                                                                                                    checked={values?.product?.active_list.includes(
                                                                                                                                        Number(order)
                                                                                                                                    )}
                                                                                                                                    onChange={(event) =>
                                                                                                                                        event.target.checked
                                                                                                                                            ? array.push(Number(order))
                                                                                                                                            : array.remove(
                                                                                                                                                values?.product?.active_list.indexOf(
                                                                                                                                                    Number(order)
                                                                                                                                                )
                                                                                                                                            )
                                                                                                                                    }
                                                                                                                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded-full"
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                            <div className="ml-2 text-sm select-none">
                                                                                                                                <label
                                                                                                                                    htmlFor={`product.active_list.[${index}]`}
                                                                                                                                    className="font-medium text-gray-700"
                                                                                                                                >
                                                                                                                                    {(structures.types.order as any)[order]}
                                                                                                                                </label>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                )}
                                                                                                            </div>
                                                                                                        )}
                                                                                                    />
                                                                                                </Box>
                                                                                                <FieldArray
                                                                                                    name="product.prices"
                                                                                                    render={({ remove, insert }) => (
                                                                                                        <>
                                                                                                            <Stack direction="column" spacing={2} sx={{ marginTop: "2vw" }}>
                                                                                                                {values.product.prices.map((price: any, index: number) => (
                                                                                                                    <Box>
                                                                                                                        <Stack direction="row" spacing={2}>
                                                                                                                            <TextField
                                                                                                                                name={`product.prices.${index}.priceName`}
                                                                                                                                label={t('price-name')}
                                                                                                                                placeholder={t('general-price')!}
                                                                                                                                value={price.priceName}
                                                                                                                                onChange={handleChange}
                                                                                                                                onBlur={handleBlur}
                                                                                                                                error={get(errors, `product.prices.${index}.priceName`) && get(touched, `product.prices.${index}.priceName`) ? true : false}
                                                                                                                                helperText={get(errors, `product.prices.${index}.priceName`) && get(touched, `product.prices.${index}.priceName`) ? (<span>{get(errors, `product.prices.${index}.priceName`)}</span>) : null}
                                                                                                                            />
                                                                                                                            <Select
                                                                                                                                name={`product.prices.${index}.order_type`}
                                                                                                                                value={price.order_type}
                                                                                                                                onChange={handleChange}
                                                                                                                                onBlur={handleBlur}
                                                                                                                                multiple
                                                                                                                                size="small"
                                                                                                                                label={t('listing-name')}
                                                                                                                                renderValue={(selected) => price.order_type.map((type: any) => (<>{order_types.find((t: any) => t.value == type)?.label}, </>))}
                                                                                                                                sx={{ width: "10vw" }}
                                                                                                                            >
                                                                                                                                {order_types.map((type: any) => (
                                                                                                                                    <MenuItem value={type.value}>
                                                                                                                                        <Checkbox checked={price.order_type.includes(type.value)} />
                                                                                                                                        <ListItemText>{t(type.label)}</ListItemText>
                                                                                                                                    </MenuItem>
                                                                                                                                ))}
                                                                                                                            </Select>
                                                                                                                            <TextField
                                                                                                                                name={`product.prices.${index}.amount`}
                                                                                                                                label={t('amount')}
                                                                                                                                value={price.amount}
                                                                                                                                onChange={handleChange}
                                                                                                                                onBlur={handleBlur}
                                                                                                                                error={get(errors, `product.prices.${index}.amount`) && get(touched, `product.prices.${index}.amount`) ? true : false}
                                                                                                                                helperText={get(errors, `product.prices.${index}.amount`) && get(touched, `product.prices.${index}.amount`) ? (<span>{get(errors, `product.prices.${index}.amount`)}</span>) : null}
                                                                                                                            />
                                                                                                                            <TextField
                                                                                                                                name={`product.prices.${index}.price`}
                                                                                                                                label={t('price')}
                                                                                                                                value={price.price}
                                                                                                                                onChange={handleChange}
                                                                                                                                onBlur={handleBlur}
                                                                                                                                error={get(errors, `product.prices.${index}.price`) && get(touched, `product.prices.${index}.price`) ? true : false}
                                                                                                                                helperText={get(errors, `product.prices.${index}.price`) && get(touched, `product.prices.${index}.price`) ? (<span>{get(errors, `product.prices.${index}.price`)}</span>) : null}
                                                                                                                            />
                                                                                                                            <Select
                                                                                                                                name={`product.prices.${index}.currency`}
                                                                                                                                value={price.currency}
                                                                                                                                onChange={handleChange}
                                                                                                                            >
                                                                                                                                {["TL", "USD", "EUR"].map((currency: string) => (
                                                                                                                                    <MenuItem value={currency}>
                                                                                                                                        {currency}
                                                                                                                                    </MenuItem>
                                                                                                                                ))}
                                                                                                                            </Select>
                                                                                                                            <TextField
                                                                                                                                name={`product.prices.${index}.vat_rate`}
                                                                                                                                label={t('vat')}
                                                                                                                                value={price.vat_rate}
                                                                                                                                onChange={handleChange}
                                                                                                                                onBlur={handleBlur}
                                                                                                                                error={get(errors, `product.prices.${index}.vat_rate`) && get(touched, `product.prices.${index}.vat_rate`) ? true : false}
                                                                                                                                helperText={get(errors, `product.prices.${index}.vat_rate`) && get(touched, `product.prices.${index}.vate_rate`) ? (<span>{get(errors, `product.prices.${index}.vat_rate`)}</span>) : null}
                                                                                                                            />
                                                                                                                            <IconButton color="error" onClick={() => remove(index)}><DeleteIcon /></IconButton>

                                                                                                                        </Stack>
                                                                                                                    </Box>
                                                                                                                ))}
                                                                                                                <Button sx={{ width: "50%", margin: "auto", alignSelf: "center" }} color="inherit" variant="contained" onClick={insert.bind(null, values.product.prices.length + 1, {
                                                                                                                    priceName: String(),
                                                                                                                    currency: "TL",
                                                                                                                    order_type: [
                                                                                                                        Number(
                                                                                                                            Object.keys(structures.types.order)[0]
                                                                                                                        ),
                                                                                                                    ],
                                                                                                                    amount: 1,
                                                                                                                    vat_rate: Number(),
                                                                                                                    price: Number(),
                                                                                                                })}>{t('add-price')}</Button>
                                                                                                            </Stack>
                                                                                                        </>
                                                                                                    )}
                                                                                                />
                                                                                            </DialogContent>
                                                                                            <DialogActions>
                                                                                                <Button type="submit" variant="contained" color="success" onClick={() => {
                                                                                                    let findIndex = Form.current.values.groupProducts.findIndex((p: any) => p.id == values.product.id);
                                                                                                    if (findIndex > -1) Form.current.values.groupProducts.splice(findIndex, 1);
                                                                                                    Form.current.values.groupProducts.push(values.product);
                                                                                                    setSelectedProduct(null);
                                                                                                }}>{t('save')}</Button>
                                                                                                <Button type="button" variant="outlined" color="error" onClick={() => setSelectedProduct(null)}>{t('cancel')}</Button>
                                                                                            </DialogActions>
                                                                                        </>
                                                                                    )}
                                                                                </Formik>
                                                                            </>
                                                                        )}

                                                                    </Dialog>
                                                                </>
                                                            )}
                                                        />
                                                    </>
                                                )}
                                            </Grid>
                                        </TabPanel>
                                    </TabContext>
                                </form>


                            </>
                        )}
                    </Formik>
                </>
            )}
        </Box>
    )
}
