import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import Http from "services/http";
import Dashboard from "services/dashboard";
import Case from "services/case";
import Check from "services/check";
import Expenses from "services/expenses";
import { convertCurrency, defaultCurrency} from 'services/helpers/exchange';
import { IResponseProfit } from "services/interfaces/dashboard/profit";
import IBusyRate, {
  IResponseBusyRate,
} from "services/interfaces/dashboard/busy-rate";
import axios from 'axios';
import { IResponseTopSellingProducts } from "services/interfaces/dashboard/top-selling-products";
import { IResponseChecksCount } from "services/interfaces/dashboard/checks-count";
import { CaseType } from "services/interfaces/case/caseType";
import ShowSum from "components/showSum";
import ShowGraph from "components/showGraph";
import WeeklyDifference from "components/weeklyDifference";
import useCache from "services/useCache";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

import Response from "services/response";
import IResponseProductReport from "services/interfaces/report/product";
import { useTranslation } from "react-i18next";

export default function () {
  const [openCaseList, setOpenCaseList] = useState<CaseType[]>([]);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [checkAmountList, setAmountCheckList] = useState<
    { total: number; date: string }[]
  >([]);
  const [expensesAmountList, setExpensesAmountList] = useState<
    { total: number; date: string }[]
  >([]);
  const DashboardService = new Dashboard(useContext(Http.Context)!);
  const CaseService = new Case(useContext(Http.Context)!);
  const CheckService = new Check(useContext(Http.Context)!);
  const ExpensesService = new Expenses(useContext(Http.Context)!);
  const TodayProductReport = new Response<IResponseProductReport>();
  const Cases = useCache(CaseService);
  const Fetchs: {
    Profit: { data: IResponseProfit; error: any };
    BusyRate: { data: IResponseBusyRate; error: any };
    TopSellingProducts: { data: IResponseTopSellingProducts; error: any };
    ChecksCount: { data: IResponseChecksCount; error: any };
  } = {
    Profit: useCache(DashboardService, "getProfit"),
    BusyRate: useCache(DashboardService, "getBusyRate"),
    TopSellingProducts: useCache(DashboardService, "getTopSellingProducts"),
    ChecksCount: useCache(DashboardService, "getChecksCount"),
  };

  const { t } = useTranslation();

  const beautifyHour = (dt: any) => {
    return (
      (new Date(dt).getHours() < 10 ? "0" : "") +
      new Date(dt).getHours() +
      ":" +
      (new Date(dt).getMinutes() < 10 ? "0" : "") +
      new Date(dt).getMinutes()
    );
  };
  useEffect(() => {
    if(Cases.data){
         fetchCases();
    }
  }, [Cases.data]);
  
  const [todayCasesCreateAt, setTodayCasesCreateAt] = useState<Date>();
  const fetchCases = async () => {
    try { 
      const today = new Date();
      const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
      const refreshToken = localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN_NAME!);
      const todayCases = Cases.data?.filter((item: any) => {
        const caseDate = new Date(item.createdAt);
        return (
          caseDate.getDate() === today.getDate() &&
          caseDate.getMonth() === today.getMonth() &&
          caseDate.getFullYear() === today.getFullYear()
        );
      });
      
      const filteredCases = Cases.data?.filter((item: any) => item.is_open);
      
      setOpenCaseList(filteredCases);
  
      let listCheck: any[] = [];
  
      setTodayCasesCreateAt(
        todayCases.length > 0
          ? todayCases?.sort((a: any, b: any) => a.createdAt - b.createdAt)[0].createdAt
          : filteredCases.length > 0
            ? filteredCases?.sort((a: any, b: any) => a.createdAt - b.createdAt)[0].createdAt
            : new Date()
      );

      let dataCategory: any = await axios.get(`manager/report/category`, {
        baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
        headers: {
            'Authorization': `Bearer ${token}`,
            'x-refresh': refreshToken!
        },
        params: {
            startdate: new Date( todayCases.length > 0
              ? todayCases?.sort((a: any, b: any) => a.createdAt - b.createdAt)[0].createdAt
              : filteredCases.length > 0
                ? filteredCases?.sort((a: any, b: any) => a.createdAt - b.createdAt)[0].createdAt
                : new Date()).getTime(),
            enddate: today.getTime(),
        }
    }); 

      if (Cases.data?.length > 0) {
        const foundCategory = dataCategory.data.find((item:any) => item.id === Cases.data[0].branch);
        setCategoryList(foundCategory.reports)
      }
      if (todayCases.length > 0) {

        const promises = todayCases.map(async (item: any) => {
          const response = await CheckService.getById(item._id);
          return response.data;
        });
  
        const results = await Promise.all(promises);
        
        results.forEach((check: any) => {
          listCheck.push(check);
        });
  
        let listExpensesAmount: {
          total: number;
          date: string;
        }[] = [];
  
        const promisesExpenses = todayCases.map(async (item: any) => {
          const responseAll: any[] = [];
  
          for (const expensesId of item.expenses) {
            const TIMEOUT_DURATION = 5000;
          try {
             const timeoutPromise = new Promise((resolve, reject) => {
               setTimeout(() => {
               reject(new Error("İstek zaman aşımına uğradı"));
                }, TIMEOUT_DURATION);
                });
  
            const expense = await Promise.race([ExpensesService.getById(expensesId), timeoutPromise]);
  
            if(expense){
                        responseAll.push(expense.data);}
            } catch (error) {
              console.error("İstek tamamlanamadı:", error);
              }
          }
  
          return responseAll;
        });
  
        const resultsExpenses = await Promise.all(promisesExpenses);
        let totalExpensesAmount = 0.0;
  
        resultsExpenses.forEach((expenses: any) => {
          expenses.map((exp: any) => {
            totalExpensesAmount += exp.expense_amount;
            listExpensesAmount = [
              ...listExpensesAmount,
              {
                total: totalExpensesAmount,
                date: exp.createdAt,
              },
            ];
          });
        });
  
        setExpensesAmountList(listExpensesAmount);
      } else if (filteredCases.length > 0) {
        
        const promises = filteredCases.map(async (item: any) => {
          const response = await CheckService.getById(item._id);
          return response.data;
        });
  
        const results = await Promise.all(promises);
        
        results.forEach((check: any) => {
          listCheck.push(check);
        });
  
        let listExpensesAmount: {
          total: number;
          date: string;
        }[] = [];
  
        const promisesExpenses = filteredCases.map(async (item: any) => {
          const responseAll: any[] = [];
  
          for (const expensesId of item.expenses) {
            const TIMEOUT_DURATION = 5000;
          try {
             const timeoutPromise = new Promise((resolve, reject) => {
               setTimeout(() => {
               reject(new Error("İstek zaman aşımına uğradı"));
                }, TIMEOUT_DURATION);
                });
  
            const expense = await Promise.race([ExpensesService.getById(expensesId), timeoutPromise]);
  
            if(expense){
                        responseAll.push(expense.data);}
            } catch (error) {
              console.error("İstek tamamlanamadı:", error);
              }
          }
          return responseAll;
        });
  
        const resultsExpenses = await Promise.all(promisesExpenses);
        let totalExpensesAmount = 0.0;
  
        resultsExpenses.forEach((expenses: any) => {
          expenses.map((exp: any) => {
            totalExpensesAmount += exp.expense_amount;
            listExpensesAmount = [
              ...listExpensesAmount,
              {
                total: totalExpensesAmount,
                date: exp.createdAt,
              },
            ];
          });
        });
  
        setExpensesAmountList(listExpensesAmount);
      }
  
      let listCheckAmount: {
        total: number;
        date: string;
      }[] = [];
  
      listCheck
        ?.sort((a: any, b: any) => a.createdAt - b.createdAt)
        .forEach((check1: any) => {
          let totalAmount = 0.0;
  
          check1.forEach((check2: any) => {
            let lastCreatePaymentTime = "";
  
            check2.payments.forEach((check3: any) => {
              if (check3.type !== 6 && check3.type !== 14 && check3.type !== 16) {
              totalAmount += check3.amount;
              lastCreatePaymentTime = check3.createdAt;
              }
            });
            lastCreatePaymentTime===""?null:
            listCheckAmount = [
              ...listCheckAmount,
              {
                total: totalAmount,
                date: lastCreatePaymentTime,
              },
            ];
          });
        });
  
      setAmountCheckList(listCheckAmount);
    } catch (error) {
    }
  };
  return (
    <div id="content" className="!static p-6 space-y-4 overflow-x-hidden">
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
        <ShowSum
          name={t("daily-income")}
          type={defaultCurrency()}
          data={
            Fetchs.Profit.data?.data.reduce((acc, curr) => {
              const formattedIncome:any = curr.totalIncome ;
              return parseFloat(convertCurrency(acc + parseFloat(formattedIncome)));
            }, 0) || 0
          }
        />
        <ShowSum
          name={t("daily-paid-check")}
          data={Fetchs.ChecksCount.data?.data.totalChecks}
        />

        <ShowSum
          name={t("cancelled-orders")}
          data={Fetchs.ChecksCount.data?.data.cancelledOrders}
        />

        <ShowSum
          name={t("daily-open-order")}
          data={Fetchs.ChecksCount.data?.data.unpaidChecks}
        />
      </div>

      <div className="border rounded-lg">
        <div className="border-b p-4 border-gray-200">
          <h3 className="text-lg font-medium">{t("last-week-today")}</h3>
        </div>
        <div className="p-4">
          <WeeklyDifference
            TodayProductReport={TodayProductReport}
            tableBusyRate={
              Fetchs.BusyRate.data?.data.busyTables +
              " / " +
              (Fetchs.BusyRate.data?.data.busyTables +
                Fetchs.BusyRate.data?.data.availableTables)
            }
          />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4">
        <ShowGraph
          name={t("daily-income")}
          data={{
            labels: [
              beautifyHour(todayCasesCreateAt) ?? "06:00",
              ...checkAmountList.map((item: any) => beautifyHour(item.date)),
              beautifyHour(new Date()),
            ],
            datasets: [
              {
                label: t("income"),
                data: ["0", ...checkAmountList.map((item: any) => item.total)],
                borderColor: "#16a34a",
                backgroundColor: "#22c55e",
                cubicInterpolationMode: "monotone",
                tension: 0.4,
              },
            ],
          }}
        />
        <ShowGraph
          name={t("daily-expense")}
          data={{
            labels: [
              beautifyHour(todayCasesCreateAt) ?? "06:00",
              ...expensesAmountList.map((item: any) => beautifyHour(item.date)),
              beautifyHour(new Date()),
            ],
            datasets: [
              {
                label: t("expense"),
                data: [
                  "0",
                  ...expensesAmountList.map((item: any) => item.total),
                ],
                borderColor: "#dc2626",
                backgroundColor: "#ef4444",
                cubicInterpolationMode: "monotone",
                tension: 0.4,
              },
            ],
          }}
        />
      </div>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <div className="max-h-[66vh] flex flex-col border border-gray-200 rounded-lg">
          <div className="border-b border-gray-200 p-4">
            <h3 className="text-xl font-medium">{t("category-based-sale")}</h3>
          </div>
         <div className="flex-grow overflow-auto">
            {categoryList.length > 0 ? (
              <table className="relative min-w-full divide-y divide-gray-200">
                <thead className="sticky top-0 bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("category")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("total")}
                    </th>
                  </tr>
                </thead>
                <tbody className=" bg-white divide-y divide-gray-200">
                  {categoryList
                    .sort((a: any, b: any) => b.saleCount - a.saleCount)
                    .filter((category:any) => category.saleCount>0)
                    .slice(0, 5)
                    .map((category: any) => (
                      <tr key={category.id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {category.categoryTitle}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-center text-sm text-gray-900">
                            {  (convertCurrency(category.saleCount))+ ' / '+ convertCurrency(category.salePercent) +'%'}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ) : (
              <div className="text-center p-4">{t("no-sale-yet")}</div>
            )}
          </div>
        </div>
        <div className="max-h-[66vh] flex flex-col border border-gray-200 rounded-lg">
          <div className="border-b border-gray-200 p-4">
            <h3 className="text-xl font-medium">
              {t("best-selling-products")}
            </h3>
          </div>
          <div className="flex-grow overflow-auto">
            {Fetchs.TopSellingProducts.data?.data.length > 0 ? (
              <table className="relative min-w-full divide-y divide-gray-200">
                <thead className="sticky top-0 bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("product")}
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("total")}
                    </th>
                  </tr>
                </thead>
                <tbody className=" bg-white divide-y divide-gray-200">
                  {Fetchs.TopSellingProducts.data?.data
                    .sort((a: any, b: any) => b.saleCount - a.saleCount)
                    .slice(0, 5)
                    .map((product: any) => (
                      <tr key={product._id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">
                            {product._id}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-center text-sm text-gray-900">
                            {convertCurrency(product.count)}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ) : (
              <div className="text-center p-4">{t("no-sale-yet")}</div>
            )}
          </div>
          {Fetchs.TopSellingProducts.data?.data.length > 0 && (
            <div className="flex items-end justify-end border-t border-gray-200 p-3">
              <Link to="/product" className="text-sm">
                {t("view-all")}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
