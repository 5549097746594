import { payment_types } from "services/interfaces/case/payment_types";
import Structures from "services/structures";

export const parseCaseBased = (
  report: { label: string; result: object[] }[]
) => {
  let parsed: { type: number; dates: string[]; amounts: number[] }[] = [];

  report.map((period: any) => {
    period.result.map((caseType: any) => {
      let exists = parsed.find((item: any) => item.type === +caseType.type);

      if (exists) {
        let lastD = new Date(
          exists.dates.at(-1)!.split(".").reverse().join("-")
        ).getTime();
        let newD = new Date(
          period.label.split(".").reverse().join("-")
        ).getTime();
        let diff = (newD - lastD) / (1000 * 60 * 60 * 24);
        for (let i = 1; i < diff; i++) {
          // add missing dates
          exists.dates.push(timeStampToDate(lastD + 1000 * 60 * 60 * 24 * i));
          exists.amounts.push(0);
        }
        exists.dates.push(timeStampToDate(newD));
        exists.amounts.push(+caseType.amount || 0); // if amount is null, set it to 0
      } else {
        parsed.push({
          type: +caseType.type,
          dates: [period.label],
          amounts: [+caseType.amount || 0],
        });
      }
    });
  });

  return parsed;
};

export const parseWorkerBased = (
  report: { label: string; result: object[] }[]
) => {
  let parsed: { user: string; dates: string[]; amounts: number[] }[] = [];

  report.map((period: any) => {
    period.result.map((userObj: any) => {
      let exists = parsed.find((item: any) => item.user === userObj.user);

      if (exists) {
        let lastD = new Date(
          exists.dates.at(-1)!.split(".").reverse().join("-")
        ).getTime();
        let newD = new Date(
          period.label.split(".").reverse().join("-")
        ).getTime();
        let diff = (newD - lastD) / (1000 * 60 * 60 * 24);
        for (let i = 1; i < diff; i++) {
          // add missing dates
          exists.dates.push(timeStampToDate(lastD + 1000 * 60 * 60 * 24 * i));
          exists.amounts.push(0);
        }
        exists.dates.push(timeStampToDate(newD));
        exists.amounts.push(+userObj.payments[0]?.amount || 0); // if amount is null, set it to 0
      } else {
        parsed.push({
          user: userObj.user,
          dates: [period.label],
          amounts: [+userObj.payments[0].amount || 0],
        });
      }
    });
  });

  return parsed;
};

export const parseTableBased = (
  report: { label: string; result: object[] }[]
) => {
  let parsed: { table: string; dates: string[]; quantities: number[] }[] = [];

  report.map((period: any) => {
    period.result.map((tableType: any) => {
      let exists = parsed.find((item: any) => item.table === tableType.table);

      if (exists) {
        let lastD = new Date(
          exists.dates.at(-1)!.split(".").reverse().join("-")
        ).getTime();
        let newD = new Date(
          period.label.split(".").reverse().join("-")
        ).getTime();
        let diff = (newD - lastD) / (1000 * 60 * 60 * 24);
        for (let i = 1; i < diff; i++) {
          // add missing dates
          exists.dates.push(timeStampToDate(lastD + 1000 * 60 * 60 * 24 * i));
          exists.quantities.push(0);
        }
        exists.dates.push(timeStampToDate(newD));
        exists.quantities.push(+tableType.quantity || 0); // if quantities is null, set it to 0
      } else {
        parsed.push({
          table: tableType.table,
          dates: [period.label],
          quantities: [+tableType.quantity || 0],
        });
      }
    });
  });

  return parsed;
};

export const parseOrderTypeBased = (
  report: { label: string; result: object[] }[]
) => {
  let parsed: { order_type: number; dates: string[]; amounts: number[] }[] = [];

  report.map((period: any) => {
    period.result.map((orderType: any) => {
      let exists = parsed.find(
        (item: any) => item.order_type === +orderType.order_type
      );

      if (exists) {
        let lastD = new Date(
          exists.dates.at(-1)!.split(".").reverse().join("-")
        ).getTime();
        let newD = new Date(
          period.label.split(".").reverse().join("-")
        ).getTime();
        let diff = (newD - lastD) / (1000 * 60 * 60 * 24);
        for (let i = 1; i < diff; i++) {
          // add missing dates
          exists.dates.push(timeStampToDate(lastD + 1000 * 60 * 60 * 24 * i));
          exists.amounts.push(0);
        }
        exists.dates.push(timeStampToDate(newD));
        exists.amounts.push(+orderType.payments[0]?.amount || 0); // if amount is null, set it to 0
      } else {
        parsed.push({
          order_type: +orderType.order_type,
          dates: [period.label],
          amounts: [+orderType.payments[0]?.amount || 0],
        });
      }
    });
  });

  return parsed;
};

export const parseProductBased = (
  report: { label: string; result: object[] }[]
) => {
  let parsed: { name: string; dates: string[]; amounts: number[] }[] = [];

  report.map((period: any) => {
    period.result.map((product: any) => {
      if (product.isPaid) {
        let exists = parsed.find((item: any) => item.name === product.name);

        if (exists) {
          let lastD = new Date(
            exists.dates.at(-1)!.split(".").reverse().join("-")
          ).getTime();
          let newD = new Date(
            period.label.split(".").reverse().join("-")
          ).getTime();
          let diff = (newD - lastD) / (1000 * 60 * 60 * 24);
          for (let i = 1; i < diff; i++) {
            // add missing dates
            exists.dates.push(timeStampToDate(lastD + 1000 * 60 * 60 * 24 * i));
            exists.amounts.push(0);
          }
          exists.dates.push(timeStampToDate(newD));
          exists.amounts.push(+product.price || 0); // if amount is null, set it to 0
        } else {
          parsed.push({
            name: product.name,
            dates: [period.label],
            amounts: [+product.price || 0],
          });
        }
      }
    });
  });

  return parsed;
};

export function productCSV(branches: any) {
  let data: any = {};
  branches.forEach((branch: any, i: number) => {
    branch.data.forEach((period: any) => {
      period.result.forEach((product: any) => {
        if (!data[i]) {
          data = { ...data, [i]: [] };
        }
        data[i].push({
          date: period.label,
          isPaidTitle: product.isPaid ? "Yapıldı" : "Yapılmadı",
          ...product,
        });
      });
    });
    if (data[i]) {
      // combine duplicate products
      data[i] = data[i].reduce((acc: any, curr: any) => {
        let prodExists = acc.find(
          (p: any) =>
            p.name === curr.name &&
            p.date === curr.date &&
            p.isPaid === curr.isPaid
        );
        if (prodExists) {
          prodExists.price += curr.price;
          prodExists.quantity += curr.quantity;
        } else {
          acc = [...acc, curr];
        }
        return acc;
      }, []);
    }
    data[i]?.sort(sortByDate);
  });
  let header = [
    { label: "Tarih", key: "date" },
    { label: "Ürün Adı", key: "name" },
    { label: "Kategori", key: "categoryTitle" },
    { label: "Adet", key: "quantity" },
    { label: "Fiyat", key: "price" },
    { label: "Ödeme", key: "isPaidTitle" },
  ];
  return { header, data };
}
export function caseCSV(branches: any) {
  let data: any = {};
  branches.forEach((branch: any, i: number) => {
    branch.data.forEach((period: any) => {
      period.result.forEach((type: any) => {
        if (!data[i]) {
          data = { ...data, [i]: [] };
        }
        data[i].push({
          date: period.label,
          type: payment_types[+type.type - 1].title,
          payment: (+type.amount).toFixed(2) + " " + (type.currency || "TL"),
        });
      });
    });
    data[i]?.sort(sortByDate);
  });
  let header = [
    { label: "Tarih", key: "date" },
    { label: "Gelir Tipi", key: "type" },
    { label: "Kazanç", key: "payment" },
  ];
  return { header, data };
}

export function tableCSV(branches: any, panelTables: any) {
  let data: any = {};
  branches.forEach((branch: any, i: number) => {
    branch.data.forEach((period: any) => {
      period.result.forEach((table: any) => {
        if (!data[i]) {
          data = { ...data, [i]: [] };
        }
        data[i].push({
          date: period.label,
          table: table.table,
          tableName: panelTables.find((t: any) => t.id == table.table)?.title,
          quantity: table.quantity,
        });
      });
    });
    data[i]?.sort(sortByDate);
  });
  let header = [
    { label: "Tarih", key: "date" },
    { label: "Masa Adı", key: "tableName" },
    { label: "Sipariş Adedi", key: "quantity" },
  ];
  return { header, data };
}

export function workerCSV(branches: any, panelWorkers: any) {
  let data: any = {};
  branches.forEach((branch: any, i: number) => {
    branch.data.forEach((period: any) => {
      period.result.forEach((user: any) => {
        if (!data[i]) {
          data = { ...data, [i]: [] };
        }
        data[i].push({
          date: period.label,
          user: panelWorkers.find((worker: any) => worker.id == user.user)
            ?.name,
          payment:
            (+user.payments[0]?.amount).toFixed(2) +
            " " +
            (user.payments[0]?.currency || "TL"),
          quantity: user.quantity,
        });
      });
    });
    data[i]?.sort(sortByDate);
  });
  let header = [
    { label: "Tarih", key: "date" },
    { label: "Çalışan Adı", key: "user" },
    { label: "Sipariş Adedi", key: "quantity" },
    { label: "Kazanç", key: "payment" },
  ];
  return { header, data };
}

export function orderTypeCSV(branches: any) {
  let data: any = {};
  branches.forEach((branch: any, i: number) => {
    branch.data.forEach((period: any) => {
      period.result.forEach((type: any) => {
        if (!data[i]) {
          data = { ...data, [i]: [] };
        }
        data[i].push({
          date: period.label,
          order_type: +type.order_type,
          typeName:
            Structures.types.order[
              type.order_type as keyof typeof Structures.types.order
            ],
          payment:
            (+type.payments[0]?.amount).toFixed(2) +
            " " +
            (type.payments[0]?.currency || "TL"),
          quantity: type.quantity,
        });
      });
    });
    data[i]?.sort(sortByDate);
  });
  let header = [
    { label: "Tarih", key: "date" },
    { label: "Sipariş Tipi", key: "typeName" },
    { label: "Sipariş Adedi", key: "quantity" },
    { label: "Kazanç", key: "payment" },
  ];
  return { header, data };
}

function timeStampToDate(stamp: any) {
  let date = new Date(Number(stamp));
  return (
    (date.getDate() < 10 ? "0" : "") +
    date.getDate() +
    (date.getMonth() < 9 ? ".0" : ".") +
    (date.getMonth() + 1) +
    "." +
    date.getFullYear()
  );
}

function sortByDate(a: any, b: any) {
  let bd = b.date.split(".").reverse().join("-");
  let ad = a.date.split(".").reverse().join("-");
  return new Date(ad).getTime() - new Date(bd).getTime();
}
