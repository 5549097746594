import { useContext, useState } from "react"
import { Navigate, useNavigate, useLocation } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";

import Http from "services/http"
import Authenticate from "services/authenticate"
import { useEffect } from "react";
import {useTranslation} from 'react-i18next'

import UString from "utilities/string"

import { toast } from "react-toastify"
import Spinner from "components/spinner"

export default function () {
    const [error, setError] = useState(String());
    const {t} = useTranslation();

    const navigate = useNavigate(), location = useLocation();
    const from = (location.state as any)?.from?.pathname || "/"

    const AuthContext = useContext(Authenticate.Context);
    const HttpContext = useContext(Http.Context);

    useEffect(() => {
        if(error.length > 0){
          toast.error(error);
        }
    }, [error]);

    if (AuthContext && AuthContext.IsAuthenticated)
        return <Navigate to={from} replace />

    return (
        <div id="sign-in-container">
            <div id="sign-in-wrapper">
                <div>
                    <img className="mx-auto w-5/6" src="/static/logos/main-logo.png" />
                </div>
                <Formik
                    initialValues={{
                        email: String(),
                        password: String()
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email(t('email-error')!).max(255).required(t('email-required')!),
                        password: Yup.string().min(6, t('password-error')!).required(t('password-required')!),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                        setError(String());

                        try {
                            const response = await HttpContext?.instance.post("/signin", values);
                            response && AuthContext && AuthContext.Context?.setTokens({ access: response.data.accessToken, refresh: response.data.refreshToken });
                        } catch (error: any) {
                            setError(error.response.data)
                        }

                        (setSubmitting(false), AuthContext!.IsAuthenticated) && navigate(from, { replace: true });
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div id="sign-in-form-wrapper">
                                <div>
                                    <label className="text-sm font-medium" htmlFor="email-address">
                                        {t('email')}
                                    </label>
                                    <input
                                        id="email-address"
                                        type="email"
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        autoComplete="email"
                                        className={`sign-in-field${errors.email && touched.email && errors.email ? UString.space.concat("has-error") : String()}`}
                                        placeholder="manager@digigarson.com"
                                    />
                                    {
                                        errors.email && touched.email && errors.email ? <div className="text-sm text-red-500">* {errors.email}</div> : null
                                    }
                                </div>
                                <div>
                                    <label className="text-sm font-medium" htmlFor="password">
                                        {t('password')}
                                    </label>
                                    <input
                                        id="password"
                                        type="password"
                                        name="password"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        autoComplete="current-password"
                                        className={`sign-in-field${errors.password && touched.password && errors.password ? UString.space.concat("has-error") : String()}`}
                                        placeholder="*********"
                                    />
                                    {
                                        errors.password && touched.password && errors.password ? <div className="text-sm text-red-500">* {errors.password}</div> : null
                                    }
                                </div>
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                    <input
                                        id="remember-me"
                                        name="remember-me"
                                        type="checkbox"
                                        className="h-4 w-4 text-ebony-600 focus:ring-ebony-500 border-gray-300 rounded"
                                    />
                                    <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                                        {t('remember')}
                                    </label>
                                </div>

                                <div className="text-sm">
                                    <a href="#" className="font-medium text-ebony-600 hover:text-ebony-500">
                                        {t('forgot-password-question')}
                                    </a>
                                </div>
                            </div>

                            <div>
                                <button
                                    disabled={isSubmitting}
                                    type="submit"
                                    className="min-h-[46px] group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-ebony hover:bg-ebony-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ebony-500"
                                >
                                    {
                                        isSubmitting ? <Spinner className="text-white w-5 h-5" strokeWidth={4} /> : <span>{t('login')}</span>
                                    }
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
                <div className="text-center">
                    <img className="h-10 mx-auto mb-2" src="/static/logos/matrix-logo.png" />
                    <p className="text-xs text-gray-400">Copyright © {new Date().getFullYear()} Matrix Teknoloji</p>
                </div>
            </div>
        </div>
    )
}