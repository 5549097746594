export const payment_types = [
    {id: 1, title: "pos_bank"},
    {id: 2, title: "pos_cash"},
    {id: 3, title: "app_payment"},
    {id: 4, title: "serve"},
    {id: 5, title: "discount"},
    {id: 6, title: "tick"},
    {id: 7, title: "sodexo"},
    {id: 8, title: "smart"},
    {id: 9, title: "winwin"},
    {id: 10, title: "multinet"},
    {id: 11, title: "setcard"},
    {id: 12, title: "metropol"},
    {id: 13, title: "edended"},
    {id: 14, title: "tips"},
    {id: 15, title: "jetkurye"},
    {id: 16, title: "not-payable"},
    {id: 17, title: "migros-hemen"},
    {id: 18, title: "getir-online"},
    {id: 19, title: "trendyol"},
    {id: 20, title: "yemek-sepeti"},
]