import { useState, useRef, useContext, useEffect } from "react";
import {
  IconButton,
  Box,
  TableContainer,
  Paper,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableHead,
  TextField,
  Button,
  Typography,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  InputLabel,
  Stack,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import clone from "clone";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import Authenticate from "services/authenticate";
import ProductService from "services/product";
import CategoryService from "services/category";
import ProductGroupService from "services/product-group";
import Http from "services/http";
import Response from "services/response";
import Loading from "components/loading";
import { blue, green, indigo } from "@mui/material/colors";
import SearchIcon from "@mui/icons-material/Search";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";

enum order_types {
  "Garson/Pos Siparişi" = 1,
  "QR Kodu Siparişi" = 2,
  "Adres Teslim" = 3,
  "Gel Al" = 4,
  "Kasa Satış" = 5,
}

export default function CreateProductGroup() {
  const [products, setProducts]: any = useState([]);
  const [categories, setCategories]: any = useState([]);
  const [selectedProduct, setSelectedProduct]: any = useState(null);
  const [addedProducts, setAddedProducts]: any = useState([]);
  const [title, setTitle] = useState("");
  const [branchFilter, setBranchFilter] = useState("");
  const [groupFilter, setGroupFilter] = useState("");

  const allProductPage = useRef(0);
  const rowsPerAllProductPage = useRef(5);

  const context = useContext(Authenticate.Context!);

  const PService = new ProductService(useContext(Http.Context)!);
  const CService = new CategoryService(useContext(Http.Context)!);
  const PGService = new ProductGroupService(useContext(Http.Context)!);

  const PFetch = new Response();
  const CFetch = new Response();

  const { t } = useTranslation();

  useEffect(() => (PFetch.handle(PService.getAllProducts()), undefined), []);
  useEffect(() => (CFetch.handle(CService.getAllCategories()), undefined), []);

  useEffect(() => {
    if (PFetch.data) {
      setProducts(PFetch.data);
    }
  }, [PFetch.data]);

  useEffect(() => {
    if (CFetch.data) {
      setCategories(CFetch.data);
    }
  }, [CFetch.data]);

  const showToast = (message: string, type: string) => {
    switch (type) {
      case "success":
        toast.success(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case "warning":
        toast.warning(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case "error":
        toast.error(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      default:
        break;
    }
  };

  const saveProductGroup = async () => {
    let data = {
      title: title,
      products: addedProducts,
    };
    if (data.title == "") {
      showToast(t("product-group-name-warning")!, "warning");
      return;
    }
    if (data.products.length == 0) {
      showToast(t("product-group-item-warning")!, "warning");
      return;
    }
    let response = await PGService.createProductGroup(data);
    if (response.status == 200) {
      showToast(t("product-group-created-success")!, "success");
      setSelectedProduct(null);
      setAddedProducts([]);
      setTitle("");
      return;
    }
    showToast(t("server-error")!, "error");
  };

  const getCategoryTitle = (categoryId: string) => {
    let c: any = categories.find((category: any) => category.id == categoryId);
    if (!c) return;
    return c.title;
  };

  const emptyRows =
    allProductPage.current > 0
      ? Math.max(
          0,
          (1 + allProductPage.current) * rowsPerAllProductPage.current -
            products.length
        )
      : 0;

  const onClickProduct = (product: any) => {
    setSelectedProduct(clone(product));
  };

  const onChangeHandler = (event: any, type: string) => {
    switch (type) {
      case "title":
        setSelectedProduct((prev: any) => ({
          ...prev,
          title: event.target.value,
        }));
        break;
      case "description":
        setSelectedProduct((prev: any) => ({
          ...prev,
          description: event.target.value,
        }));
        break;
      case "productGroupTitle":
        setTitle(event.target.value);
        break;
      case "sale_type":
        setSelectedProduct((prev: any) => ({
          ...prev,
          sale_type: event.target.value,
        }));
        break;
      default:
        break;
    }
  };

  const onPriceChange = (
    event: any,
    type: string,
    index: number,
    value?: number
  ) => {
    let product = clone(selectedProduct);
    switch (type) {
      case "title":
        product.prices[index].priceName = event.target.value;
        break;
      case "amount":
        product.prices[index].amount = event.target.value;
        break;
      case "price":
        product.prices[index].price = Number(event.target.value);
        break;
      case "currency":
        product.prices[index].currency = event.target.value;
        break;
      case "vat_rate":
        product.prices[index].vat_rate = event.target.value;
        break;
      case "order_type":
        let typeIndex = product.prices[index].order_type.findIndex(
          (t: number) => t == value
        );
        if (typeIndex > -1) {
          delete product.prices[index].order_type[typeIndex];
        } else {
          product.prices[index].order_type.push(value);
        }
        break;
      default:
        break;
    }
    setSelectedProduct(product);
  };

  const cancelChange = () => {
    setSelectedProduct(null);
  };

  const deletePrice = (index: number) => {
    let prices = clone(selectedProduct.prices);
    delete prices[index];
    setSelectedProduct((prev: any) => ({ ...prev, prices: prices }));
  };

  const addPrice = () => {
    let prices = clone(selectedProduct.prices);
    prices.push({
      priceName: "",
      order_type: [],
      amount: 1,
      price: 0,
      vat_rate: 0,
      currency: "",
    });
    setSelectedProduct((prev: any) => ({ ...prev, prices: prices }));
  };

  const addProduct = () => {
    const product = clone(selectedProduct);
    product.prices = product.prices.filter((p: any) => {
      return p !== null && p !== undefined;
    });
    if (product.prices.length == 0) {
      showToast(t("product-price-warning")!, "warning");
      return;
    }
    if (product.title.length == 0) {
      showToast(t("product-title-description")!, "warning");
      return;
    }
    let isValidated = true;
    product.prices.map((p: any) => {
      if (!p.vat_rate) p.vat_rate = 0;
      if (
        p.priceName == "" ||
        p.order_type.length == 0 ||
        p.price == null ||
        p.vat_rate == null ||
        p.currency == null ||
        p.amount == 0 ||
        p.amount == null
      ) {
        isValidated = false;
        p;
      }
    });
    if (!isValidated) {
      showToast(t("product-price-info-warning")!, "warning");
      return;
    }

    setAddedProducts((prev: any) => [...prev, product]);
    setProducts((prev: any) =>
      prev.filter((p: any) => p.id != selectedProduct.id)
    );
    setSelectedProduct(null);
  };

  const orderTypeChange = (e: any, index: number) => {
    let product = clone(selectedProduct);
    product.prices[index].order_type = e.target.value;
    setSelectedProduct(product);
  };

  const sortArrays = (array: any[]) => {
    var sortedArray = array.sort((a: any, b: any) =>
      a.title?.localeCompare(b.title)
    );
    return sortedArray;
  };

  const filterArray = (products: any, text: string) => {
    const filteredArray = products.filter((p: any) =>
      p.title?.toLowerCase()?.includes(text?.toLowerCase())
    );
    return filteredArray;
  };

  if (context?.User?.permissions?.includes(301)) {
    return (
      <Box sx={{ overflow: "scroll" }}>
        <Loading done={!PFetch.loading && !CFetch.loading} />
        <hr />
        <Typography
          variant="h5"
          sx={{
            marginTop: "1.2rem",
            marginBottom: "1.2rem",
            marginLeft: "2rem",
          }}
        >
          {t("create-product-group")}
        </Typography>
        <hr />

        <Box sx={{ marginTop: "1.2rem", marginBottom: "1.2rem" }}>
          <Stack direction="row" justifyContent="space-between">
            <TextField
              value={title}
              size="small"
              placeholder={t("product-group-name")!}
              sx={{ width: "25rem", marginLeft: "4rem" }}
              onChange={(e: any) => onChangeHandler(e, "productGroupTitle")}
            />
            <Button
              variant="contained"
              sx={{
                marginRight: "4rem",
                width: "8rem",
                backgroundColor: blue[900],
                "&:hover": { backgroundColor: blue[700] },
              }}
              color="success"
              onClick={(e: any) => saveProductGroup()}
            >
              {t("create")}
            </Button>
          </Stack>
        </Box>
        <Box>
          {selectedProduct ? (
            <>
              <Paper elevation={2} sx={{ margin: "2rem", padding: "1.2rem" }}>
                <Box
                  sx={{
                    margin: "auto",
                    width: "25%",
                    padding: "0.7rem",
                    borderRadius: "6rem",
                    backgroundColor: blue[800],
                  }}
                >
                  <Typography
                    variant="h6"
                    textAlign="center"
                    fontSize="1.1rem"
                    color="white"
                  >
                    {t("product-edit")}
                  </Typography>
                </Box>
                <Stack direction="column" spacing={4} margin="2rem">
                  <Stack direction="row" spacing={2}>
                    <Box>
                      <InputLabel id="pLbl">{t("product-name")}</InputLabel>
                      <TextField
                        defaultValue={selectedProduct.title}
                        variant="outlined"
                        placeholder="Ürün adı"
                        onChange={(e: any) => onChangeHandler(e, "title")}
                      />
                    </Box>
                    <Box>
                      <InputLabel id="cLbl">{t("product-category")}</InputLabel>
                      <TextField
                        value={getCategoryTitle(selectedProduct.category)}
                        variant="filled"
                        placeholder="Ürün kategorisi"
                        sx={{ cursor: "not-allowed" }}
                        disabled
                      />
                    </Box>
                    <Box>
                      <InputLabel id="typeLbl">{t("type")}</InputLabel>
                      <Select
                        value={selectedProduct.sale_type}
                        sx={{ width: "8rem" }}
                        onChange={(e: any) => onChangeHandler(e, "sale_type")}
                      >
                        <MenuItem value={1}>{t("quantity")}</MenuItem>
                        <MenuItem value={2}>{t("kilogram")}</MenuItem>
                        <MenuItem value={3}>{t("liter")}</MenuItem>
                        <MenuItem value={4}>{t("meter")}</MenuItem>
                        <MenuItem value={5}>{t("portion")}</MenuItem>
                      </Select>
                    </Box>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Box>
                      <InputLabel id="dLbl">
                        {t("product-description")}
                      </InputLabel>
                      <TextField
                        value={selectedProduct.description}
                        variant="filled"
                        placeholder="Ürün açıklaması"
                        multiline
                        rows={4}
                        onChange={(e: any) => onChangeHandler(e, "description")}
                      />
                    </Box>
                  </Stack>
                </Stack>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    sx={{
                      margin: "auto",
                      width: "25%",
                      padding: "0.7rem",
                      borderRadius: "6rem",
                      backgroundColor: green[800],
                    }}
                  >
                    <Typography
                      variant="h6"
                      textAlign="center"
                      fontSize="1.1rem"
                      color="white"
                    >
                      {t("price-list")}
                    </Typography>
                  </Box>
                  <Stack direction="column" spacing={3} margin="1rem">
                    {selectedProduct.prices.map((price: any, index: number) => (
                      <Stack direction="row" spacing={2}>
                        <TextField
                          label={t("price-name")}
                          placeholder={t("general-price")!}
                          defaultValue={price.priceName}
                          variant="filled"
                          size="small"
                          sx={{ marginLeft: 2 }}
                          onChange={(e: any) =>
                            onPriceChange(e, "title", index)
                          }
                          required
                        />
                        <Select
                          onChange={(e: any) => orderTypeChange(e, index)}
                          multiple
                          sx={{ width: "10vw", marginLeft: 2 }}
                          variant="filled"
                          size="small"
                          label="Listeleme Adı"
                          value={selectedProduct.prices[index].order_type}
                          renderValue={(selected: any) =>
                            selectedProduct.prices[index].order_type.map(
                              (type: any) => order_types[type]
                            )
                          }
                          required
                        >
                          <MenuItem value={1}>
                            <Checkbox
                              checked={selectedProduct.prices[
                                index
                              ].order_type.includes(1)}
                            />
                            <ListItemText primary={t("waiter-pos-order")!} />
                          </MenuItem>
                          <MenuItem value={2}>
                            <Checkbox
                              checked={selectedProduct.prices[
                                index
                              ].order_type.includes(2)}
                            />
                            <ListItemText primary={t("qr-code-order")!} />
                          </MenuItem>
                          <MenuItem value={3}>
                            <Checkbox
                              checked={selectedProduct.prices[
                                index
                              ].order_type.includes(3)}
                            />
                            <ListItemText primary={t("home-delivery")!} />
                          </MenuItem>
                          <MenuItem value={4}>
                            <Checkbox
                              checked={selectedProduct.prices[
                                index
                              ].order_type.includes(4)}
                            />
                            <ListItemText primary={t("takeaway")!} />
                          </MenuItem>
                          <MenuItem value={5}>
                            <Checkbox
                              checked={selectedProduct.prices[
                                index
                              ].order_type.includes(5)}
                            />
                            <ListItemText primary={t("case-sale")!} />
                          </MenuItem>
                        </Select>
                        <TextField
                          label={t("amount")!}
                          type="number"
                          placeholder="1"
                          defaultValue={price.amount}
                          variant="filled"
                          sx={{ marginLeft: 2, width: "8vw" }}
                          size="small"
                          onChange={(e: any) =>
                            onPriceChange(e, "amount", index)
                          }
                          required
                        />
                        <TextField
                          label={t("price")!}
                          type="number"
                          placeholder="0"
                          defaultValue={price.price}
                          variant="filled"
                          sx={{ marginLeft: 2 }}
                          size="small"
                          onChange={(e: any) =>
                            onPriceChange(e, "price", index)
                          }
                          required
                        />
                        <Select
                          sx={{ width: "6vw", marginLeft: 2 }}
                          variant="filled"
                          size="small"
                          label="Para Birimi"
                          defaultValue={price.currency}
                          onChange={(e: any) =>
                            onPriceChange(e, "currency", index)
                          }
                          required
                        >
                          <MenuItem value="TL">TL</MenuItem>
                          <MenuItem value="USD">USD</MenuItem>
                          <MenuItem value="EUR">EUR</MenuItem>
                        </Select>
                        <TextField
                          label={t("vat")!}
                          type="number"
                          placeholder="0"
                          defaultValue={price.vat_rate ? price.vat_rate : 0}
                          variant="filled"
                          sx={{ marginLeft: 2, width: "8vw" }}
                          size="small"
                          onChange={(e: any) =>
                            onPriceChange(e, "vat_rate", index)
                          }
                          required
                        />
                        <IconButton onClick={(e: any) => deletePrice(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    ))}
                  </Stack>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    sx={{ width: "10vw", margin: "auto" }}
                    onClick={() => addPrice()}
                  >
                    {t("add-price")}
                  </Button>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 91.3,
                      marginTop: 4,
                      marginBottom: 3,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ width: "12vw", marginRight: 2 }}
                      onClick={(evet: any) => addProduct()}
                      type="submit"
                    >
                      {t("add")}
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ width: "12vw" }}
                      onClick={() => cancelChange()}
                    >
                      {t("cancel")}
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </>
          ) : null}
          <Stack
            direction="row"
            spacing={4}
            alignItems="center"
            justifyContent="center"
          >
            <Paper
              elevation={2}
              sx={{ padding: "1rem", margin: "1rem", width: "50%" }}
            >
              <Box
                sx={{
                  margin: "auto",
                  marginBottom: "1rem",
                  width: "40%",
                  padding: "0.7rem",
                  borderRadius: "6rem",
                  backgroundColor: blue[800],
                }}
              >
                <Typography
                  variant="h6"
                  textAlign="center"
                  fontSize="1rem"
                  color="white"
                >
                  {t("branchs-products")}
                </Typography>
              </Box>
              <Box sx={{ marginBottom: "0.3rem" }}>
                <TextField
                  onChange={(e: any) => setBranchFilter(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                />
              </Box>
              <TableContainer
                component={Paper}
                sx={{
                  backgroundColor: blue[900],
                  height: "42rem",
                  width: "100%",
                }}
              >
                <Table
                  stickyHeader
                  sx={{ minWidth: 500 }}
                  aria-label="custom pagination table"
                >
                  <TableHead component={Paper} elevation={3}>
                    <TableRow>
                      <TableCell
                        className="text head"
                        sx={{
                          color: "#fff",
                          backgroundColor: indigo[800],
                          fontSize: "1rem",
                        }}
                      >
                        {t("product-name")}
                      </TableCell>
                      <TableCell
                        className="text head"
                        sx={{
                          color: "#fff",
                          backgroundColor: indigo[800],
                          fontSize: "1rem",
                        }}
                      >
                        {t("product-price")}
                      </TableCell>
                      <TableCell
                        className="text head"
                        sx={{
                          color: "#fff",
                          backgroundColor: indigo[800],
                          fontSize: "1rem",
                        }}
                      >
                        {t("product-category")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortArrays(filterArray(products, branchFilter)).length >
                    0 ? (
                      sortArrays(filterArray(products, branchFilter))?.map(
                        (row: any) =>
                          selectedProduct?.id == row.id ? null : (
                            <TableRow
                              sx={{
                                cursor: "pointer",
                                "&:hover": {
                                  backgroundColor: blue[700],
                                },
                              }}
                              key={row.id}
                              onClick={() => onClickProduct(row)}
                            >
                              <TableCell
                                className="text"
                                component="th"
                                scope="row"
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  color: "#fff",
                                  fontSize: "0.9rem",
                                }}
                              >
                                {row.image ? (
                                  <img
                                    src={row.image}
                                    style={{
                                      borderRadius: "8rem",
                                      width: "3rem",
                                      height: "3rem",
                                      marginRight: "1rem",
                                      marginLeft: "0.3rem",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src="https://manager.digigarson.com/static/images/not-available.png"
                                    style={{
                                      width: "3rem",
                                      height: "3rem",
                                      borderRadius: "8rem",
                                      marginRight: "1rem",
                                      marginLeft: "0.3rem",
                                    }}
                                  />
                                )}
                                <Typography textAlign="center" margin="auto">
                                  {row.title}
                                </Typography>
                              </TableCell>
                              <TableCell
                                className="text"
                                style={{
                                  color: "#fff",
                                  fontSize: "0.9rem",
                                }}
                                align="left"
                              >
                                {row.prices[0].price} {row.prices[0].currency}
                              </TableCell>
                              <TableCell
                                className="text"
                                style={{
                                  color: "#fff",
                                  fontSize: "0.9rem",
                                }}
                                align="left"
                              >
                                {getCategoryTitle(row.category)}
                              </TableCell>
                            </TableRow>
                          )
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography
                            textAlign="center"
                            fontSize="1.2rem"
                            fontWeight="bold"
                            margin="auto"
                            color="#fff"
                          >
                            {t("no-products-to-list")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Paper
              elevation={2}
              sx={{ padding: "1rem", margin: "1rem", width: "50%" }}
            >
              <Box
                sx={{
                  margin: "auto",
                  marginBottom: "1rem",
                  width: "40%",
                  padding: "0.7rem",
                  borderRadius: "6rem",
                  backgroundColor: green[800],
                }}
              >
                <Typography
                  variant="h6"
                  textAlign="center"
                  fontSize="1rem"
                  color="white"
                >
                  {t("groups-products")}
                </Typography>
              </Box>
              <Box sx={{ marginBottom: "0.3rem" }}>
                <TextField
                  onChange={(e: any) => setGroupFilter(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                />
              </Box>
              <TableContainer
                sx={{
                  height: "42rem",
                  width: "100%",
                  backgroundColor: green[700],
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          backgroundColor: green[900],
                          color: "#fff",
                          fontSize: "1.1rem",
                        }}
                      >
                        {t("product-name")}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: green[900],
                          color: "#fff",
                          fontSize: "1.1rem",
                        }}
                      >
                        {t("product-price")}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: green[900],
                          color: "#fff",
                          fontSize: "1.1rem",
                        }}
                      >
                        {t("product-category")}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: green[900],
                          color: "#fff",
                          fontSize: "1.1rem",
                        }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortArrays(filterArray(addedProducts, groupFilter))
                      ?.length > 0 ? (
                      sortArrays(filterArray(addedProducts, groupFilter))?.map(
                        (product: any, index: number) => (
                          <TableRow
                            sx={{
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: green[500],
                              },
                            }}
                            key={index}
                            onClick={() => {
                              let added = clone(addedProducts);
                              added.splice(index, 1);
                              setAddedProducts(added);
                              setProducts((prev: any) => [...prev, product]);
                              setSelectedProduct(product);
                            }}
                          >
                            <TableCell sx={{ color: "#fff", fontSize: "1rem" }}>
                              {product.title}
                            </TableCell>
                            <TableCell sx={{ color: "#fff", fontSize: "1rem" }}>
                              {product.prices[0].price}
                            </TableCell>
                            <TableCell sx={{ color: "#fff", fontSize: "1rem" }}>
                              {getCategoryTitle(product.category)}
                            </TableCell>
                            <TableCell sx={{ color: "#fff", fontSize: "1rem" }}>
                              <IconButton
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  let added = clone(addedProducts);
                                  added.splice(index, 1);
                                  setAddedProducts(added);
                                  setProducts((prev: any) => [
                                    ...prev,
                                    product,
                                  ]);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Typography
                            textAlign="center"
                            fontSize="1.2rem"
                            fontWeight="bold"
                            margin="auto"
                            color="#fff"
                          >
                            {t("no-products-to-list")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Stack>
        </Box>
        <ToastContainer />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <Typography variant="h2">403</Typography>
      <Typography variant="h2">Forbidden</Typography>
      <hr />
      <Typography variant="h5">{t("no-permission")}</Typography>
    </Box>
  );
}
