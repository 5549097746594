import React, { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import MultiTableService from "services/multitable";

import Http from "services/http";
import Section from "services/section";
import Response from "services/response";
import { Formik } from "formik";
import { object, string, number } from "yup";
import { Listbox } from "@headlessui/react";

import Spinner from "components/spinner";
import { toast } from "react-toastify";

import UString from "utilities/string";
import useCache from "services/useCache";
import { useTranslation } from "react-i18next";

export default function () {
  const Service = new MultiTableService(useContext(Http.Context)!);
  const SectionService = new Section(useContext(Http.Context)!);
  const Save = new Response();
  const { t } = useTranslation();
  const Form = useRef<any>();
  const navigate = useNavigate();

  const SectionFetch = useCache(SectionService);

  useEffect(() => {
    if (Save.data && !Save.error) {
      toast.success(t('add-multi-table-success'));
      navigate("/table");
    }
  }, [Save.data]);

  useEffect(() => {
    if (Save.error) {
      toast.error(`${t('add-multi-table-failure')} (${Save.error.message})`);
    }
  }, [Save.error]);

  return (
    <React.Fragment>
      <div className="header-top">
        <h2 className="text-xl font-medium pl-2">{t('add-multi-table')}</h2>
      </div>
      <div id="content">
        <Formik
          innerRef={Form}
          enableReinitialize={true}
          initialValues={{
            section: SectionFetch.data?.[0]?.id || String(),
            floor: Number(),
            ceiling: Number(),
          }}
          validationSchema={object({
            section: string().required(t('required')!),
            floor: number().required(t('required')!),
            ceiling: number().required(t('required')!),
          })}
          onSubmit={(values) => Save.handle(Service.create(values))}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form className="action" onSubmit={handleSubmit}>
              <div className="wrapper">
                <div className="item">
                  <div className="relative w-full">
                    <label>{t('section')}</label>
                    {SectionFetch.data ? (
                      <Listbox
                        value={values.section}
                        onChange={(value) => setFieldValue("section", value)}
                      >
                        <div className="relative mt-1">
                          <Listbox.Button className="listbox-btn">
                            <span className="listbox-title">
                              {SectionFetch.data.find(
                                (section: any) => section.id === values.section
                              )?.title || t('unknown-section')}
                            </span>
                            <span className="listbox-selector-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          </Listbox.Button>
                          <Listbox.Options className="listbox-options">
                            {SectionFetch.data &&
                              SectionFetch.data.map(
                                (section: any, index: any) => (
                                  <Listbox.Option
                                    className={UString.concat_class_name(
                                      values.section === section.id
                                        ? "bg-ebony text-white"
                                        : "hover:bg-ebony hover:text-white",
                                      "flex items-center cursor-pointer px-6 py-2"
                                    )}
                                    key={index}
                                    value={section.id}
                                  >
                                    <span>{section.title}</span>
                                  </Listbox.Option>
                                )
                              )}
                          </Listbox.Options>
                        </div>
                      </Listbox>
                    ) : (
                      <div className="classic-btn w-full min-h-[38px] mt-1">
                        <Spinner className="w-5 m-auto" />
                      </div>
                    )}
                  </div>
                  <div className="w-full">
                    <label htmlFor="title">{t('lower-value')}</label>
                    <input
                      type="number"
                      name="floor"
                      min={0}
                      className={UString.concat_class_name(
                        errors.floor && touched.floor ? "has-error" : undefined,
                        "my-1"
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t('initial-value')!}
                      value={values.floor}
                    />
                    {errors.floor && touched.floor ? (
                      <span className="error">* {errors.floor}</span>
                    ) : null}
                  </div>
                  <div className="w-full">
                    <label htmlFor="title">{t('upper-value')}</label>
                    <input
                      type="number"
                      name="ceiling"
                      min={0}
                      className={UString.concat_class_name(
                        errors.ceiling && touched.ceiling
                          ? "has-error"
                          : undefined,
                        "my-1"
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t('end-value')!}
                      value={values.ceiling}
                    />
                    {errors.ceiling && touched.ceiling ? (
                      <span className="error">* {errors.ceiling}</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div className="header-bottom">
        <button
          type="submit"
          disabled={Save.loading}
          className="ml-auto classic-btn"
          onClick={() => {
            Form.current.handleSubmit();
          }}
        >
          {Save.loading ? <Spinner className="h-5 m-auto" /> : t('save')}
        </button>
      </div>
    </React.Fragment>
  );
}
