import { Service as HService } from "services/http";

export default class Service {
  private Http: HService | null = null;

  constructor(http: HService) {
    this.Http = http;
  }

  public get endpoint() {
    return `${this.Http?.baseURL}/product`;
  }

  public getAll(
    start: number | undefined = undefined,
    end: number | undefined = undefined
  ) {
    const base: URL = new URL(this.endpoint);

    if (start !== undefined && !Number.isNaN(start))
      base.searchParams.append("_start", String(start));

    if (end !== undefined && !Number.isNaN(end))
      base.searchParams.append("_end", String(end));

    return this.Http?.instance.get(
      base.href.substring(base.href.lastIndexOf("/"))
    );
  }

  public async getAllProducts() {
    return await this.Http?.instance.get(`/product`);
  }

  public async getById(id: string) {
    return await this.Http?.instance.get(`/product/${id}`);
  }

  public async create(data: any) {
    return await this.Http?.instance.post("/product", data);
  }

  public async update(id: string, data: any) {
    return await this.Http?.instance.put(`/product/${id}`, data);
  }

  public async updateQr(id: string, status: boolean) {
    return await this.Http?.instance.put(`/product/qractivate/${id}/${status}`);
  }

  public async delete(id: string) {
    return await this.Http?.instance.delete(`/product/${id}`);
  }

  public async importProducts(data: any) {
    return await this.Http?.instance.put("/import/products", data);
  }

  public async getProducts(page: number, dataPerPage: number, title?: string) {
    const base: URL = new URL(`${this.Http?.baseURL}/products`);

    if (page !== undefined && !Number.isNaN(page))
      base.searchParams.append("page", String(page));

    if (dataPerPage !== undefined && !Number.isNaN(dataPerPage))
      base.searchParams.append("perPage", String(dataPerPage));

    if (title !== undefined) base.searchParams.append("title", String(title));

    return await this.Http?.instance.get(base.href);
  }

  public async setBarcodePlu(
    product: string,
    deptId: number,
    barcode?: string,
    plu?: string
  ) {
    return await this.Http?.instance.post("/hugin/product", {
      barcode,
      plu,
      product,
      deptId,
    });
  }

  public async huginIntegration() {
    return await this.Http?.instance.post("/hugin/integration", {});
  }
}
