import { useState, useEffect } from "react";
import { Box, IconButton, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Typography } from "@mui/material";
import Loading from "components/loading";
import UserService from "api/user";
import { useTranslation } from "react-i18next";
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from "react-toastify";

export const BannedDevices = () => {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const Service = new UserService();
    const { t } = useTranslation();

    useEffect(() => {
        const fetchUsers = async () => {
            let usersData = await Service.getCrew();
            usersData = usersData.filter((user: any) => user.bannedDevices?.length != 0 && user.bannedDevices);
            setUsers(usersData);
            setLoading(false);
        }
        fetchUsers();
    }, [])

    const unbanDevice = async (userId: string, macAddress: string) => {
        let response = await Service.unbanDevice({userId, macAddress});
        if(response){
            response = response.filter((user: any) => user.bannedDevices?.length != 0 && user.bannedDevices);
            toast.success(t('unban-success'));
            setUsers(response);
            return
        }
        toast.error(t('server-error'));

    }

    if (loading) return (<Loading />)
    if (users.length == 0) {
        return (
            <Box sx={{ width: "65%", margin: "auto", marginTop: "1vw" }}>
                <Typography textAlign="center" variant="h5">
                    {t('no-banned-device')}
                </Typography>
            </Box>
        )
    }

    return (
        <Box>
            <Typography textAlign="left" variant="h5" sx={{ marginLeft: "3vw", marginTop: "3vw", fontWeight: "bold" }}>{t('banned-devices')}</Typography>
            <Box sx={{ width: "100%", marginTop: "1vw"}}>
                <TableContainer sx={{margin: "auto", width: "80%"}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('username')}</TableCell>
                                <TableCell>{t('mac-address')}</TableCell>
                                <TableCell>{t('ban-date')}</TableCell>
                                <TableCell>{t('actions')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((user: any) => user.bannedDevices?.map((device: any) => (
                                <TableRow>
                                    <TableCell>{user.name} {user.lastname}</TableCell>
                                    <TableCell>{device.macAddress}</TableCell>
                                    <TableCell>{new Date(device.createdAt)?.toLocaleDateString()}</TableCell>
                                    <TableCell><IconButton onClick={() => unbanDevice(user.id, device.macAddress)} title={t('unban')!}><DeleteIcon /></IconButton></TableCell>
                                </TableRow>
                            )))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>

    )
}