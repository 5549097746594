import { Service as HService } from "services/http"

export default class Notes {
    private Http: HService | null = null

    constructor(http: HService) {
        this.Http = http
    }

    public get endpoint(){
      return `${this.Http?.baseURL}/notes`
    }

    public async getAll() {
        return await this.Http?.instance.get("/notes");
    }

    public async createNote(data:any) {
        return await this.Http?.instance.post("/notes", data);
    }

    public async updateNote(id:string, data:object) {
        return await this.Http?.instance.put(`/notes/${id}`, data);
    }

    public async getNote(id:string) {
        return await this.Http?.instance.get(`/notes/${id}`);
    }

    public async deleteNote(id:string) {
        return await this.Http?.instance.delete(`/notes/${id}`);
    }



}