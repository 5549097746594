import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";

import Http from "services/http";
import Case from "services/case";
import Response from "services/response";
import { Balance } from "services/interfaces/case/caseType";
import { payment_types } from "services/interfaces/case/payment_types";

import Loading from "components/loading";
import NetworkError from "components/network-error";
import { useTranslation } from "react-i18next";
import { convertCurrency} from 'services/helpers/exchange';
import VerticalTable from "components/verticalTable";

export default function () {
  const Service = new Case(useContext(Http.Context)!);
  const CaseResponse = new Response();
  const { t } = useTranslation();
  const { id } = useParams();
  const [balance, setBalance] = useState<Balance[]>([
    { type: 1, amount: 1, currency: "" },
  ]);

  useEffect(
    () => (id && CaseResponse.handle(Service.getById(id!)), undefined),
    [id]
  );
  useEffect(
    () => {
      const summedBalance: Balance[] = [];

      if (CaseResponse.data) {
        CaseResponse.data.balance.forEach((value:any) => {
        const existingItem = summedBalance.find((item:any) => item.type === value.type);
        if (existingItem) {
            existingItem.amount += value.amount;
        } else {
            summedBalance.push(value);
        }
        });
      }
      summedBalance.sort((a, b) => a.type - b.type);
      CaseResponse.data && setBalance(summedBalance||[]), undefined
    },
    [CaseResponse.data]
  );

  const handleTotal = (balance: Balance[]) => {
    let result: number = 0;
    balance.forEach((value) => {
      if (value.type != 5) {
        result += +value.amount;
      }
    });
    return result;
  };

  const handleTotalTurnover = (balance: Balance[]) => {
    let result: number = 0;
    balance.forEach((value) => {
      if (value.type != 5 && value.type != 6 && value.type != 14 && value.type != 16) {
        result += +value.amount;
      }
    });
    return result;
  };

  const handleBalanceFilter = (balance: Balance[]) => {
    const summedBalance:Balance[] = [];

    let result = balance.filter((value) => {
      if (value.type != 5) {
        return true;
      } else {
        return false;
      }
    });
    return result;
  };
  return (
    <>
      <div className="header-top">
        <h2 className="text-xl font-medium pl-2">{`${t('case-detail')} (${new Date(
          CaseResponse.data?.createdAt
        ).toLocaleDateString("tr-TR", {
          minute: "numeric",
          hour: "numeric",
          year: "numeric",
          month: "long",
          day: "numeric",
        })} - ${new Date(CaseResponse.data?.updatedAt).toLocaleDateString(
          "tr-TR",
          {
            minute: "numeric",
            hour: "numeric",
            year: "numeric",
            month: "long",
            day: "numeric",
          }
        )})`}</h2>
        <Link
          to={`/case/salesSlip/${id}`}
          className="flex items-center text-white bg-ebony py-3 px-6 rounded-lg text-sm font-medium ml-auto"
        >
          <svg
            width="20px"
            height="20px"
            viewBox="0 0 17 17"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            className="mr-2"
          >
            <path d="M2 0v16.902l2.028-2.481 1.503 1.88 1.501-1.875 1.499 1.875 1.5-1.875 1.5 1.875 1.499-1.875 1.97 2.46v-16.886h-13zM14 14.036l-0.97-1.211-1.499 1.875-1.5-1.875-1.5 1.875-1.499-1.875-1.501 1.875-1.495-1.87-1.036 1.268v-13.098h11v13.036zM10.997 4h-6v-1h6v1zM8.997 8h-4v-1h4v1zM11.978 6h-7v-1h7v1zM5 10h7v1h-7v-1z" />
          </svg>
          <span>{t('ticket-list')}</span>
        </Link>
      </div>
      <div id="content">
        <Loading done={!CaseResponse.loading} />
        {!CaseResponse.loading ? (
          <NetworkError
            error={CaseResponse.error}
            retry={() => CaseResponse.handle(Service.getById(id!))}
          />
        ) : null}
        <div className="grid grid-cols-4 gap-4">
          {CaseResponse.data && !CaseResponse.loading && !CaseResponse.error && (
            <VerticalTable
              data={{
                thead: {
                  element: t('total-price'),
                  props: { className: "text-center text-xl" },
                },
                head: [
                  { element: t('total'), props: { scope: "col" } },
                  { element: t('total-endrosement'), props: { scope: "col" } },
                ],
                body: [
                  {
                    element: {
                      element: convertCurrency(handleTotal(balance)) +" "+ (balance.length>0?balance[0].currency:""),
                      props: { className: "text-left text-sm text-gray-900" },
                    },
                  },
                  {
                    element: {
                      element: convertCurrency(handleTotalTurnover(balance))+" "+ (balance.length>0?balance[0].currency:""),
                      props: { className: "text-left text-sm text-gray-900" },
                    },
                  },
                ],
              }}
            />
          )}
          {CaseResponse.data && !CaseResponse.loading && !CaseResponse.error && (
            <VerticalTable
              data={{
                thead: {
                  element: t('other-operations'),
                  props: { className: "text-center text-xl" },
                },
                head: [{ element: t('tick'), props: { scope: "col" } }],
                body: [
                  {
                    element: {
                      element: balance.find((value) => value.type == 6)
                        ? convertCurrency(balance.find((value) => value.type == 6)?.amount ?? 0) +
                          " " +
                          balance.find((value) => value.type == 6)?.currency
                        : t('none'),
                      props: { className: "text-left text-sm text-gray-900" },
                    },
                  },
                ],
              }}
            />
          )}
          {CaseResponse.data && !CaseResponse.loading && !CaseResponse.error && (
            <VerticalTable
              data={{
                thead: {
                  element: t('payments'),
                  props: { className: "text-center text-xl" },
                },
                head: handleBalanceFilter(balance).map((value) => ({
                  element: t(payment_types[value.type - 1]?.title),
                  props: { className: "text-left text-sm text-gray-900" },
                })),
                body: handleBalanceFilter(balance).map((value) => ({
                  element: {
                    element: convertCurrency((+value.amount)) + " " + value.currency,
                    props: { className: "text-left text-sm text-gray-900" },
                  },
                })),
              }}
            />
          )}
          {CaseResponse.data && !CaseResponse.loading && !CaseResponse.error && (
            <VerticalTable
              data={{
                thead: {
                  element: t('tick-payments'),
                  props: { className: "text-center text-xl" },
                },
                head: [],
                body: [],
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}
