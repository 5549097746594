import React from 'react'
import { useTranslation } from "react-i18next";
export default function NoReport() {
    const {t} = useTranslation();
    return (
        <div>
            <h3 style={{
                fontSize: "2vw",
                textAlign: "center",
                fontFamily: "verdana",
                color: "#7A7A7A"
            }}>{t("noReport1")} <br /> {t("noReport2")}</h3>
        </div>
    )
}
