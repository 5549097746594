import HttpClient from "api";
import { AxiosRequestConfig } from "axios";

const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
const refreshToken = localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN_NAME!);

class UserService extends HttpClient {
    public constructor() {
        super(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1/manager`);

        this._initializeRequestInterceptor();
    }

    private _initializeRequestInterceptor = () => {
        this.instance.interceptors.request.use(
            this._handleRequest,
            this._handleError
        )
    }

    private _handleRequest = (config: AxiosRequestConfig) => {
        config.headers = {
            Authorization: `Bearer ${token}`,
            "x-refresh": refreshToken!,
        }

        return config;
    }

    public getPairOptions = () => {
        return this.instance.get('/pairing');
    }

    public unpairDevice = (data: {userId: string, macAddress: string}) => {
        return this.instance.post(`/pairing/${data.userId}`, {macAddress: data.macAddress});
    }

    public banDevice = (data: {userId: string, macAddress: string}) => {
        return this.instance.post('/ban-device', data);
    }

    public unbanDevice = (data: {userId: string, macAddress: string}) => {
        return this.instance.post('/unban-device', data);
    }

    public getCrew = () => {
        return this.instance.get('/members');
    }

    public generatePairCode = () => {
        return this.instance.post('/generate-code');
    }

    public getProfile = () => {
        return this.instance.get('/profile');
    }
}

export default UserService;
