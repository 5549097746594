import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  ChangeEvent,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, FieldArray } from "formik";
import { object, string, number, array, boolean } from "yup";
import { Transition, Listbox } from "@headlessui/react";
import { get, values } from "lodash";

import Http from "services/http";
import Product from "services/product";
import Category from "services/category";
import Language from "services/language";
import Option from "services/option";
import Allergen from "services/allergen";

import Response from "services/response";
import Structures from "services/structures";

import Modal from "components/modal";
import Loading from "components/loading";
import Image from "components/image";
import Spinner from "components/spinner";

import UString from "utilities/string";

import { toast } from "react-toastify";
import useCache from "services/useCache";

import { useTranslation } from "react-i18next";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "../../components/crop.css";
import { Box, Button, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

interface IPrice {
  priceName: string;
  currency: string;
  order_type: number[];
  amount: number;
  vat_rate: number;
  price: number;
}

interface ILocale {
  lang: string;
  title: string;
  description: string;
}

type InitialValuesType = {
  product: {
    stock_code: string;
  };
};

type Props = {
  existingStockCodes: string[];
};

export default function () {
  const [modal, setModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const { id } = useParams();
  const [language, setLanguage] = useState<any>();

  const { t } = useTranslation();

  const [lang, setLang] = useState<any>(
    !id
      ? {
          locale: [
            {
              lang: "tr", // TODO: must be branch's default language
              title: "",
              description: "",
            },
          ],
        }
      : undefined
  );

  const [product, setProduct] = useState<any>(
    !id
      ? {
          title: "",
          description: "",
          category: "",
          image: "",
          start_time: "",
          end_time: "",
          stock_code: "",
          options: [],
          active_list: [Number(Object.keys(Structures.types.order)[0])],
          sale_type: Number(Object.keys(Structures.types.product)[0]),
          prices: [
            {
              priceName: "",
              currency: "" || "TL",
              order_type: [Number(Object.keys(Structures.types.order)[0])],
              amount: 1,
              vat_rate: Number(),
              price: Number(),
            },
          ],
          favorite: Boolean(),
          opportunity: Boolean(),
        }
      : undefined
  );

  const [formFileError, setFormFileError] = useState<any>(null);

  const [imageUrl, setImageUrl] = useState<any>();
  const [existingStockCodes, setexistingStockCodes] = useState<any>();
  const findLanguage = () => {
    let lang = language?.items?.find((item: any) =>
      item != null ? item.itemId == id : null
    );
    return lang;
  };

  const fileInputChange: any = (event: any) => {
    if (event.target.files[0].size > 75000) return setFormFileError(true);
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function () {
      setImageUrl(reader.result);
      setProduct({ ...product, image: reader.result });
    };
  };

  let fileInputRef = useRef<any>();

  useEffect(() => {
    if (formFileError) {
      fileInputRef.current.value = null;
      setFormFileError(false);
      setImageUrl(null);
      toast.error(t("max-40-kb")!);
    }
  }, [formFileError]);

  const Service = new Product(useContext(Http.Context)!);
  const Fetch = new Response<any>();
  const Save = new Response();
  const products = useCache(Service);
  const AllergenService = new Allergen(useContext(Http.Context)!);
  const Allergens = useCache(AllergenService);

  const CService = new Category(useContext(Http.Context)!);
  const CFetch = useCache(CService);

  const OService = new Option(useContext(Http.Context)!);
  const OFetch = useCache(OService);

  const LService = new Language(useContext(Http.Context)!);
  const LFetch = new Response<any[]>();

  const Form = useRef<any>();

  const [isModalOpen, setModalState] = React.useState(false);

  const toggleModal = () => setModalState(!isModalOpen);

  const [openCrop, setOpenCrop] = useState(false);
  const [stockCodes, setStockCodes] = useState<string[]>([]);
  const [valuess, setValues] = useState({
    product: {
      stock_code: "",
    },
  });

  useEffect(
    () => (!LFetch.data && LFetch.handle(LService.get()), undefined),
    [LFetch.data]
  );

  useEffect(() => Save.data, [Save.data]);
  useEffect(
    () => (id && !Fetch.data && Fetch.handle(Service.getById(id!)), undefined),
    [Fetch.data]
  );
  useEffect(() => id && Fetch.data && setProduct(Fetch.data), [Fetch.data]);
  useEffect(() => LFetch.data && setLanguage(LFetch.data), [LFetch.data]);
  useEffect(() => id && language && setLang(findLanguage()), [language]);
  useEffect(() => {
    if (products.data !== undefined && stockCodes.length === 0) {
      let stockList: string[] = [];
      products.data.forEach((product: any) => {
        stockList.push(product.stock_code);
      });
      if (stockCodes.length === 0 && stockList.length !== 0) {
        setStockCodes(stockList);
      }
    }
  }, [products, stockCodes]);

  useEffect(() => {
    if (Save.data && !Save.error) {
      toast.success(
        `${t("product")} ${id ? t("editing") : t("adding")} ${t("success")}.`
      );
      navigate("/product");
    }
  }, [Save.data]);

  useEffect(() => {
    if (Save.error) {
      toast.error(
        `${t("product")} ${id ? t("editing") : t("adding")} ${t("failed")}. (${
          Save.error.message
        })`
      );
    }
  }, [Save.error]);

  const [valuesImg, setValuesImg] = useState("");
  const [cropData, setCropData] = useState("#");
  const [image, setImage] = useState("#");
  const [cropper, setCropper] = useState<any>();
  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        Form.current.setFieldValue("product.image", reader.result);
      };
      reader.onerror = (error) => {
        console.error("Error reading file:", error);
      };
      reader.readAsDataURL(files[0]);
    } else {
      console.error("No file selected.");
    }
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };
  const cropsavedata = () => {
    {
      canSave == true
        ? Form.current.setFieldValue("product.image", cropData)
        : "";
    }
  };

  const [rotate, setRotate] = useState<number>(0);

  const handleRotate = () => {
    cropper.rotate(5);
  };
  const handleRotateDecrease = () => {
    cropper.rotate(-5);
  };
  const checkStockCode = (
    stockCode: string,
    existingStockCodes: string[] = []
  ): string | null => {
    if (existingStockCodes.includes(stockCode)) {
      return "Bu stok kodu zaten mevcut.";
    }
    return null;
  };

  const [canEdit, setCanEdit] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const [stockCodeError, setStockCodeError] = useState<string | null>(null);

  useEffect(() => {
    setStockCodeError(
      checkStockCode(product?.stock_code || "", existingStockCodes)
    );
  }, [product?.stock_code, existingStockCodes]);

  const [stockCodeExists, setStockCodeExists] = useState(false);

  const handleStockCodeChange = (value: string) => {
    const enteredStockCode = value;

    if (stockCodes.includes(enteredStockCode)) {
      // Stok kodu zaten mevcut, hata mesajı göster
      setStockCodeExists(true);
    } else {
      // Stok kodu mevcut değil, diziye ekle
      setStockCodeExists(false);
    }
  };

  {
    stockCodeExists && <span className="error">Stok kodu zaten mevcut</span>;
  }

  return (
    <React.Fragment>
      <Loading
        done={!Fetch.loading && CFetch.data && OFetch.data && !LFetch.loading}
      />
      <div className="header-top">
        <h2 className="text-xl font-medium pl-2">
          {t("product")} {id ? t("edit") : t("add")}
        </h2>
      </div>
      <div id="content" className="pb-20">
        {product && (
          <Formik
            innerRef={Form}
            enableReinitialize={true}
            initialValues={{
              product:
                product ||
                Object({
                  title: product?.title || String(),
                  description: product?.description || String(),
                  category: product?.category || String(),
                  image: product?.image || String(),
                  start_time: product?.start_time || String(),
                  end_time: product?.end_time || String(),
                  stock_code: product?.stock_code || String(),
                  options: product?.options || Array(),
                  active_list: product?.active_list || [
                    Number(Object.keys(Structures.types.order)[0]),
                  ],
                  sale_type:
                    product?.sale_type ||
                    Number(Object.keys(Structures.types.product)[0]),
                  allergen: product?.allergen || Array(),
                  calorie: product?.calorie || Number(),
                  prices:
                    product?.prices ||
                    Array({
                      priceName: String(),
                      currency: "TRY" || String(),
                      order_type: [
                        Number(Object.keys(Structures.types.order)[0]),
                      ],
                      amount: 1,
                      vat_rate: Number(),
                      price: Number(),
                    }),
                  favorite: product?.favorite || Boolean(),
                  opportunity: product?.opportunity || Boolean(),
                }),
              lang:
                lang ||
                Object({
                  locale: [
                    {
                      lang: "tr", // TODO: must be branch's default language
                      title: product.title,
                      description: product.description,
                    },
                  ],
                }),
            }}
            validationSchema={object({
              product: object({
                title: string().required(t("required")!),
                //description: string().required("'description' ise required"),
                category: string().required(t("required")!),
                image: string(),
                start_time: string(),
                end_time: string(),
                stock_code: string().required(t("required")!),
                sale_type: number().required(t("required")!),
                allergen: array(),
                calorie: string(),
                prices: array()
                  .of(
                    object({
                      priceName: string().required(t("required")!),
                      currency: string().required(t("required")!),
                      order_type: array().required(t("required")!),
                      amount: number().required(t("required")!),
                      vat_rate: number().required(t("required")!),
                      price: number().required(t("required")!).min(0),
                    }).required()
                  )
                  .required(),
                favorite: boolean().required(t("required")!),
                opportunity: boolean().required(t("required")!),
              }).required(),
              lang: object({
                locale: array()
                  .of(
                    object({
                      lang: string().required(t("required")!),
                      title: string().required(t("required")!),
                      description: string(),
                    }).required()
                  )
                  .required(t("required")!),
              }).required(t("required")!),
            })}
            onSubmit={(values) => {
              Save.handle(
                id && product
                  ? Service.update(id, { ...product, ...values })
                  : Service.create(values)
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form className="action" onSubmit={handleSubmit}>
                <div className="wrapper">
                  <div className="item">
                    <div className="w-full">
                      <div className="flex items-center space-x-6">
                        <div className="shrink-0 rounded-full border border-gray-200 p-0.5">
                          <Image
                            className="h-16 w-16 object-cover rounded-full"
                            src={
                              cropData == "#" ? values.product.image : cropData
                            }
                            onClick={toggleModal}
                          />
                          {cropData == "#"
                            ? setCanSave(false)
                            : setCanSave(true)}
                        </div>
                        <label className="block">
                          <span className="sr-only">
                            {t("select-product-picture")}
                          </span>
                          <input
                            accept="image/*"
                            type="file"
                            ref={fileInputRef}
                            onChange={onChange}
                            className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-ebony file:text-white hover:file:bg-opacity-90"
                          />
                        </label>
                        <IconButton aria-label="delete" color="primary">
                          <EditIcon onClick={() => setCanEdit(true)} />
                        </IconButton>
                      </div>
                    </div>
                    {/* #cropper */}
                    {canEdit == true ? (
                      <div>
                        <div style={{ width: "60%" }}>
                          <Cropper
                            style={{ height: 400, width: "100%" }}
                            zoomTo={0.5}
                            initialAspectRatio={1}
                            preview=".img-preview"
                            src={image != "#" ? image : values.product.image}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false}
                            rotateTo={rotate}
                            onInitialized={(instance) => {
                              setCropper(instance);
                            }}
                            guides={true}
                          />
                        </div>
                        <div>
                          <div
                            className="box"
                            style={{ width: "60%", float: "right" }}
                          ></div>
                          <div
                            className="box"
                            style={{ width: "100%", height: "100px" }}
                          >
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                              <Typography sx={{ marginTop: 2 }}>
                                {t("rotate")}:
                              </Typography>
                              <Button
                                sx={{ fontSize: "25px" }}
                                onClick={handleRotate}
                              >
                                +
                              </Button>
                              <Button
                                sx={{ fontSize: "30px" }}
                                onClick={handleRotateDecrease}
                              >
                                -
                              </Button>
                            </Box>
                            <Typography sx={{ color: "red" }}>
                              {t("warningBranchImage")}
                            </Typography>
                            <Button
                              variant="contained"
                              type="button"
                              sx={{ marginTop: 1 }}
                              onClick={() => {
                                getCropData(),
                                  setValuesImg(values.product.image),
                                  setCanEdit(false);
                              }}
                            >
                              {t("save")}
                            </Button>
                          </div>
                        </div>
                        <br style={{ clear: "both" }} />
                        <br />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="w-full">
                      <FieldArray
                        name="lang.locale"
                        render={({ insert, remove }) => (
                          <>
                            <div className="pt-4 space-y-4">
                              {values?.lang?.locale?.length ? (
                                values?.lang?.locale?.map(
                                  (language: any, index: number) => {
                                    return (
                                      <div
                                        className="flex flex-col"
                                        key={index}
                                      >
                                        <div className="flex flex-col xl:flex-row gap-4 w-full">
                                          <div className="w-full xl:w-1/3">
                                            <label
                                              htmlFor={`lang.locale.[${index}].lang`}
                                            >
                                              {t("language")}
                                            </label>
                                            <Listbox
                                              value={language.lang}
                                              onChange={(value) =>
                                                setFieldValue(
                                                  `lang.locale.[${index}].lang`,
                                                  value
                                                )
                                              }
                                            >
                                              <div className="relative mt-1">
                                                <Listbox.Button className="listbox-btn">
                                                  <span className="listbox-title">
                                                    {
                                                      Structures.types.lang.find(
                                                        (role) =>
                                                          language.lang ===
                                                          role.value
                                                      )?.title
                                                    }
                                                  </span>
                                                  <span className="listbox-selector-icon">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      className="w-5 h-5 text-gray-400"
                                                      viewBox="0 0 20 20"
                                                      fill="currentColor"
                                                    >
                                                      <path
                                                        fillRule="evenodd"
                                                        d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                                        clipRule="evenodd"
                                                      />
                                                    </svg>
                                                  </span>
                                                </Listbox.Button>
                                                <Listbox.Options className="listbox-options">
                                                  {Structures.types.lang.map(
                                                    (value) => (
                                                      <Listbox.Option
                                                        className={UString.concat_class_name(
                                                          language.lang ===
                                                            value.value
                                                            ? "bg-ebony text-white"
                                                            : "hover:bg-ebony hover:text-white",
                                                          "flex items-center cursor-pointer px-6 py-2"
                                                        )}
                                                        key={value.value}
                                                        value={value.value}
                                                      >
                                                        <span>
                                                          {value.title}
                                                        </span>
                                                      </Listbox.Option>
                                                    )
                                                  )}
                                                </Listbox.Options>
                                              </div>
                                            </Listbox>
                                          </div>

                                          <div className="w-full xl:w-2/3">
                                            <label
                                              htmlFor={`lang.locale.[${index}].title`}
                                            >
                                              {t("product-name")}
                                            </label>
                                            <input
                                              type="text"
                                              name={`lang.locale.[${index}].title`}
                                              className={UString.concat_class_name(
                                                get(
                                                  errors,
                                                  `lang.locale.[${index}].title`
                                                ) &&
                                                  get(
                                                    touched,
                                                    `lang.locale.[${index}].title`
                                                  )
                                                  ? "!border-red-500 !ring-red-500"
                                                  : undefined,
                                                "flex mt-1"
                                              )}
                                              onChange={(value) => {
                                                if (index == 0) {
                                                  setFieldValue(
                                                    `product.title`,
                                                    value.target.value
                                                  );
                                                }
                                                handleChange(value);
                                              }}
                                              onBlur={handleBlur}
                                              placeholder={
                                                t("product-name-temp")!
                                              }
                                              value={
                                                language?.title || String()
                                              }
                                            />
                                            {get(
                                              errors,
                                              `lang.locale.[${index}].title`
                                            ) &&
                                            get(
                                              touched,
                                              `lang.locale.[${index}].title`
                                            ) ? (
                                              <span className="error">
                                                *{" "}
                                                {get(
                                                  errors,
                                                  `lang.locale.[${index}].title`
                                                )}
                                              </span>
                                            ) : null}
                                          </div>
                                          {index != 0 && (
                                            <div className="xl:mt-6">
                                              <button
                                                className="classic-btn justify-center red w-full "
                                                type="button"
                                                onClick={() => {
                                                  values.lang.locale.length >
                                                    1 && remove(index);
                                                }}
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  className="h-5 w-5"
                                                  fill="none"
                                                  viewBox="0 0 24 24"
                                                  stroke="currentColor"
                                                >
                                                  <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={1.5}
                                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                                  />
                                                </svg>
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                        <div className="mt-4 w-full">
                                          <div>
                                            <label
                                              htmlFor={`lang.locale.[${index}].description`}
                                            >
                                              {t("product-description")}
                                            </label>
                                            <textarea
                                              rows={3}
                                              name={`lang.locale.[${index}].description`}
                                              className={UString.concat_class_name(
                                                get(
                                                  errors,
                                                  `lang.locale.[${index}].description`
                                                ) &&
                                                  get(
                                                    touched,
                                                    `lang.locale.[${index}].description`
                                                  )
                                                  ? "!border-red-500 !ring-red-500"
                                                  : undefined,
                                                "flex mt-1"
                                              )}
                                              onChange={(value) => {
                                                if (index == 0) {
                                                  setFieldValue(
                                                    `product.description`,
                                                    value.target.value
                                                  );
                                                }
                                                handleChange(value);
                                              }}
                                              onBlur={handleBlur}
                                              placeholder={
                                                t("product-description-temp")!
                                              }
                                              value={
                                                language?.description ||
                                                String()
                                              }
                                            />
                                            {get(
                                              errors,
                                              `lang.locale.[${index}].description`
                                            ) &&
                                            get(
                                              touched,
                                              `lang.locale.[${index}].description`
                                            ) ? (
                                              <span className="error">
                                                *{" "}
                                                {get(
                                                  errors,
                                                  `lang.locale.[${index}].description`
                                                )}
                                              </span>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <div>{t("no-language")}</div>
                              )}
                              <div className="border-t border-gray-300 pt-4">
                                <button
                                  className="w-full xl:ml-auto classic-btn justify-center"
                                  type="button"
                                  onClick={insert.bind(
                                    null,
                                    values?.lang?.locale?.length + 1,
                                    {
                                      lang: "en",
                                      title: String(),
                                      description: String(),
                                    } as ILocale
                                  )}
                                >
                                  {t("add-lang")}
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      />
                    </div>
                    <div className="w-full">
                      <label htmlFor="stock_code">{t("stock_code")}</label>
                      <input
                        type="text"
                        name="product.stock_code"
                        className={UString.concat_class_name(
                          get(errors, "product.stock_code") &&
                            get(touched, "product.stock_code")
                            ? "!border-red-500 !ring-red-500"
                            : undefined,
                          "my-1"
                        )}
                        onChange={(value) => {
                          handleStockCodeChange(value.target.value);
                          setFieldValue(
                            `product.stock_code`,
                            value.target.value
                          );
                          handleChange(value);
                        }}
                        onBlur={handleBlur}
                        placeholder="p_1337"
                        value={values.product.stock_code}
                      />
                      {get(errors, "product.stock_code") &&
                      get(touched, "product.stock_code") ? (
                        <span className="error">
                          {get(errors, "product.stock_code")}
                        </span>
                      ) : null}
                      {stockCodeExists && (
                        <span className="error">Stok kodu zaten mevcut</span>
                      )}
                    </div>
                    <div className="w-full">
                      <label>{t("type")}</label>
                      <Listbox
                        value={values?.product?.sale_type}
                        onChange={(value) =>
                          setFieldValue("product.sale_type", Number(value))
                        }
                      >
                        <div className="relative mt-1">
                          <Listbox.Button className="listbox-btn">
                            <span className="listbox-title">
                              {
                                (Structures.types.product as any)[
                                  values?.product?.sale_type
                                ]
                              }
                            </span>
                            <span className="listbox-selector-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          </Listbox.Button>
                          <Transition
                            as={React.Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="listbox-options">
                              {Object.keys(Structures.types.product).map(
                                (type, index) => (
                                  <Listbox.Option
                                    className={UString.concat_class_name(
                                      values?.product?.sale_type ===
                                        Number(type)
                                        ? "bg-ebony text-white"
                                        : "hover:bg-ebony hover:text-white",
                                      "flex items-center cursor-pointer px-6 py-2"
                                    )}
                                    key={index}
                                    value={Number(type)}
                                  >
                                    <span>
                                      {(Structures.types.product as any)[type]}
                                    </span>
                                  </Listbox.Option>
                                )
                              )}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                    </div>
                    <div className="relative w-full">
                      <label>{t("category")}</label>
                      {CFetch.data ? (
                        <Listbox
                          value={values?.product?.category}
                          onChange={(value) =>
                            setFieldValue("product.category", value)
                          }
                        >
                          <div className="relative mt-1">
                            <Listbox.Button
                              className={UString.concat_class_name(
                                get(errors, `product.category`) &&
                                  get(touched, `product.category`)
                                  ? "!border-red-500 !ring-red-500"
                                  : undefined,
                                "listbox-btn"
                              )}
                            >
                              <span className="listbox-title">
                                {CFetch.data.find(
                                  (category: any) =>
                                    category.id === values?.product?.category
                                )?.title || "Kategoriler"}
                              </span>
                              <span className="listbox-selector-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="w-5 h-5 text-gray-400"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </span>
                            </Listbox.Button>
                            <Listbox.Options className="listbox-options">
                              {CFetch.data &&
                                CFetch.data.map((category: any) => (
                                  <Listbox.Option
                                    className={UString.concat_class_name(
                                      values?.product?.category === category.id
                                        ? "bg-ebony text-white"
                                        : "hover:bg-ebony hover:text-white",
                                      "flex items-center cursor-pointer px-6 py-2"
                                    )}
                                    key={category.id}
                                    value={category.id}
                                  >
                                    <span>{category.title}</span>
                                  </Listbox.Option>
                                ))}
                            </Listbox.Options>
                          </div>
                        </Listbox>
                      ) : (
                        <div className="classic-btn w-full min-h-[38px] mt-1">
                          <Spinner className="w-5 m-auto" />
                        </div>
                      )}
                      {get(errors, `product.category`) &&
                      get(touched, `product.category`) ? (
                        <span className="error">
                          * {get(errors, `product.category`)}
                        </span>
                      ) : null}
                    </div>
                    <div className="w-full">
                      <label htmlFor={`product.allergen`}>
                        {t("allergens")}
                      </label>
                      <Listbox
                        value={values?.product?.allergen}
                        onChange={(value: any) => {
                          if (
                            !Boolean(
                              values?.product?.allergen?.find(
                                (alg: any) => alg === value
                              )
                            )
                          ) {
                            setFieldValue(`product.allergen`, [
                              ...(values?.product?.allergen || []),
                              value,
                            ]);
                          } else {
                            setFieldValue(
                              `product.allergen`,
                              values?.product?.allergen.filter(
                                (alg: any) => alg !== value
                              )
                            );
                          }
                        }}
                      >
                        <div className="relative mt-1">
                          <Listbox.Button className="listbox-btn">
                            <span className="listbox-title">
                              {values?.product?.allergen?.join(", ") ||
                                t("no-allergen")}
                            </span>
                            <span className="listbox-selector-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          </Listbox.Button>
                          <Listbox.Options className="listbox-options">
                            {Allergens.data?.map((alg: any) => (
                              <Listbox.Option
                                className="flex items-center cursor-pointer px-6 py-2 hover:bg-ebony hover:text-white"
                                key={alg.title}
                                value={alg.title}
                              >
                                {Boolean(
                                  values?.product?.allergen?.find(
                                    (al: any) => al === alg.title
                                  )
                                ) ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5 mr-1"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                ) : null}
                                <span>{alg.title}</span>
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </div>
                      </Listbox>
                    </div>
                    <div className="w-full">
                      <label htmlFor="stock_code">{t("calorie")}</label>
                      <input
                        type="number"
                        name="product.calorie"
                        className="my-1"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="1300cal / 1.3kcal"
                        value={values?.product?.calorie}
                      />
                    </div>
                    <div className="w-full">
                      <label htmlFor={`product.favorite`}>
                        {t("add-favourite-question")}
                      </label>
                      <div className="w-full classic-btn mt-1">
                        <div className="flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id={`product.favorite`}
                              name={`product.favorite`}
                              type="checkbox"
                              checked={values?.product?.favorite}
                              onChange={(event) =>
                                setFieldValue(
                                  `product.favorite`,
                                  event.target.checked
                                )
                              }
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded-full"
                            />
                          </div>
                          <div className="ml-2 text-sm select-none">
                            <label
                              htmlFor={`product.favorite`}
                              className="font-medium text-gray-700"
                            >
                              {t("yes")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <label htmlFor={`product.opportunity`}>
                        {t("add-opportunity-question")}
                      </label>
                      <div className="w-full classic-btn mt-1">
                        <div className="flex items-start">
                          <div className="flex items-center h-5">
                            <input
                              id={`product.opportunity`}
                              name={`product.opportunity`}
                              type="checkbox"
                              checked={values?.product?.opportunity}
                              onChange={(event) =>
                                setFieldValue(
                                  `product.opportunity`,
                                  event.target.checked
                                )
                              }
                              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded-full"
                            />
                          </div>
                          <div className="ml-2 text-sm select-none">
                            <label
                              htmlFor={`product.opportunity`}
                              className="font-medium text-gray-700"
                            >
                              {t("yes")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full">
                      <label>{t("active")}</label>
                      <div className="w-full classic-btn mt-1">
                        <FieldArray
                          name="product.active_list"
                          render={(array) => (
                            <div className="w-full flex flex-wrap -my-1 -mx-4">
                              {Object.keys(Structures.types.order).map(
                                (order, index) => (
                                  <div
                                    className="flex items-start my-1 px-4"
                                    key={index}
                                  >
                                    <div className="flex items-center h-5">
                                      <input
                                        id={`active_list.[${index}]`}
                                        name={`active_list.[${index}]`}
                                        type="checkbox"
                                        value={order}
                                        checked={values?.product?.active_list.includes(
                                          Number(order)
                                        )}
                                        onChange={(event) =>
                                          event.target.checked
                                            ? array.push(Number(order))
                                            : array.remove(
                                                values?.product?.active_list.indexOf(
                                                  Number(order)
                                                )
                                              )
                                        }
                                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded-full"
                                      />
                                    </div>
                                    <div className="ml-2 text-sm select-none">
                                      <label
                                        htmlFor={`product.active_list.[${index}]`}
                                        className="font-medium text-gray-700"
                                      >
                                        {(Structures.types.order as any)[order]}
                                      </label>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="item list">
                    <FieldArray
                      name="product.options"
                      render={({ insert, remove }) => (
                        <>
                          <Modal state={[modal, setModal]}>
                            <React.Fragment>
                              <div className="bg-white p-4 sm:p-6">
                                <h5 className="mb-4 font-semibold">
                                  {t("options")}
                                </h5>
                                <div className=" divide-y divide-gray-100">
                                  {OFetch.data &&
                                    OFetch.data
                                      .filter(
                                        (option: any) =>
                                          !values.product?.options.some(
                                            (predicate: any) =>
                                              predicate.option_id === option.id
                                          )
                                      )
                                      .map((option: any, index: number) => (
                                        <button
                                          onClick={() => (
                                            insert(
                                              values.product?.options.length +
                                                1,
                                              {
                                                option_id: option.id,
                                                is_forced_choice: false,
                                              }
                                            ),
                                            setModal(false)
                                          )}
                                          className="w-full block py-2"
                                          key={index}
                                        >
                                          <div className="to-gray-btn active">
                                            {option.special_name}
                                          </div>
                                        </button>
                                      ))}
                                </div>
                              </div>
                            </React.Fragment>
                          </Modal>
                          <div className="flex flex-col space-y-4 divide-y divide-gray-200 xl:divide-y-0">
                            {values.product?.options.length ? (
                              values.product?.options.map(
                                (item: any, index: number) => {
                                  const option = OFetch.data?.find(
                                    (option: any) =>
                                      option.id === item.option_id
                                  );

                                  return (
                                    <div
                                      className="flex flex-col xl:flex-row gap-4 w-full py-4 xl:py-0"
                                      key={index}
                                    >
                                      <div className="w-full xl:w-1/2">
                                        <label
                                          htmlFor={`product.options.[${index}].special_name`}
                                        >
                                          İsim
                                        </label>
                                        <input
                                          type="text"
                                          className="mt-1"
                                          value={
                                            option?.special_name || String()
                                          }
                                          disabled={true}
                                        />
                                      </div>
                                      <div className="w-full xl:w-1/2">
                                        <label
                                          htmlFor={`product.options.[${index}].is_forced_choice`}
                                        >
                                          {t("req")}
                                        </label>
                                        <div className="w-full classic-btn mt-1">
                                          <div
                                            className="flex items-start"
                                            key={index}
                                          >
                                            <div className="flex items-center h-5">
                                              <input
                                                id={`product.options.[${index}].is_forced_choice`}
                                                name={`product.options.[${index}].is_forced_choice`}
                                                type="checkbox"
                                                checked={
                                                  values?.product?.options[
                                                    index
                                                  ].is_forced_choice
                                                }
                                                onChange={(event) =>
                                                  setFieldValue(
                                                    `product.options.[${index}].is_forced_choice`,
                                                    event.target.checked
                                                  )
                                                }
                                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded-full"
                                              />
                                            </div>
                                            <div className="ml-2 text-sm select-none">
                                              <label
                                                htmlFor={`product.options.[${index}].is_forced_choice`}
                                                className="font-medium text-gray-700"
                                              >
                                                Evet
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="xl:mt-6">
                                        <button
                                          className="classic-btn justify-center red w-full xl:w-auto"
                                          type="button"
                                          onClick={remove.bind(null, index)}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth={1.5}
                                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                            />
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  );
                                }
                              )
                            ) : (
                              <div>{t("no-option")}</div>
                            )}
                          </div>
                          <div className="w-full pt-4 border-t border-gray-200">
                            <button
                              className="w-full xl:ml-auto classic-btn justify-center"
                              type="button"
                              onClick={setModal.bind(null, true)}
                            >
                              {t("add-option")}
                            </button>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <div className="item fluid mt-4">
                    <FieldArray
                      name="product.prices"
                      render={({ insert, remove }) => (
                        <>
                          <div className="flex flex-col space-y-4">
                            {values?.product?.prices?.map(
                              (price: any, index: number) => {
                                return (
                                  <div
                                    className="flex flex-col xl:flex-row gap-4 w-full"
                                    key={index}
                                  >
                                    <div className="w-full xl:w-1/6">
                                      <label
                                        htmlFor={`product.prices.[${index}].priceName`}
                                      >
                                        {t("price-name")}
                                      </label>
                                      <input
                                        type="text"
                                        name={`product.prices.[${index}].priceName`}
                                        className={UString.concat_class_name(
                                          get(
                                            errors,
                                            `product.prices.[${index}].priceName`
                                          ) &&
                                            get(
                                              touched,
                                              `product.prices.[${index}].priceName`
                                            )
                                            ? "!border-red-500 !ring-red-500"
                                            : undefined,
                                          "mt-1"
                                        )}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={t("general-price")!}
                                        value={price?.priceName || String()}
                                      />
                                      {get(
                                        errors,
                                        `product.prices.[${index}].priceName`
                                      ) &&
                                      get(
                                        touched,
                                        `product.prices.[${index}].priceName`
                                      ) ? (
                                        <span className="error">
                                          *{" "}
                                          {get(
                                            errors,
                                            `product.prices.[${index}].priceName`
                                          )}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="w-full xl:w-1/6">
                                      <label
                                        htmlFor={`product.prices.[${index}].order_type`}
                                      >
                                        {t("listing-name")}
                                      </label>
                                      <Listbox
                                        value={price.order_type}
                                        onChange={(value: any) => {
                                          if (
                                            !Boolean(
                                              price.order_type.find(
                                                (type: any) => type === value
                                              )
                                            )
                                          )
                                            setFieldValue(
                                              `product.prices.[${index}].order_type`,
                                              [...price.order_type, value]
                                            );
                                          else if (price.order_type.length > 1)
                                            setFieldValue(
                                              `product.prices.[${index}].order_type`,
                                              price.order_type.filter(
                                                (type: any) => type !== value
                                              )
                                            );
                                        }}
                                      >
                                        <div className="relative mt-1">
                                          <Listbox.Button className="listbox-btn">
                                            <span className="listbox-title">
                                              {price.order_type
                                                ?.map(
                                                  (type: any) =>
                                                    (
                                                      Structures.types
                                                        .order as any
                                                    )[type]
                                                )
                                                .join(", ")}
                                            </span>
                                            <span className="listbox-selector-icon">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="w-5 h-5 text-gray-400"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                                  clipRule="evenodd"
                                                />
                                              </svg>
                                            </span>
                                          </Listbox.Button>
                                          <Listbox.Options className="listbox-options">
                                            {Object.keys(
                                              Structures.types.order
                                            ).map((order, index) => (
                                              <Listbox.Option
                                                className="flex items-center cursor-pointer px-6 py-2 hover:bg-ebony hover:text-white"
                                                key={index}
                                                value={Number(order)}
                                              >
                                                {Boolean(
                                                  price.order_type.find(
                                                    (type: any) =>
                                                      type === Number(order)
                                                  )
                                                ) ? (
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-5 w-5 mr-1"
                                                    viewBox="0 0 20 20"
                                                    fill="currentColor"
                                                  >
                                                    <path
                                                      fillRule="evenodd"
                                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                      clipRule="evenodd"
                                                    />
                                                  </svg>
                                                ) : null}
                                                <span>
                                                  {
                                                    (
                                                      Structures.types
                                                        .order as any
                                                    )[order]
                                                  }
                                                </span>
                                              </Listbox.Option>
                                            ))}
                                          </Listbox.Options>
                                        </div>
                                      </Listbox>
                                      {get(
                                        errors,
                                        `product.prices.[${index}].order_type`
                                      ) &&
                                      get(
                                        touched,
                                        `product.prices.[${index}].order_type`
                                      ) ? (
                                        <span className="error">
                                          *{" "}
                                          {get(
                                            errors,
                                            `product.prices.[${index}].order_type`
                                          )}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="w-full xl:w-1/6">
                                      <label
                                        htmlFor={`prices.[${index}].amount`}
                                      >
                                        {t("quantity")}
                                      </label>
                                      <input
                                        type="number"
                                        name={`product.prices.[${index}].amount`}
                                        className="mt-1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="0"
                                        value={price?.amount || 1}
                                      />
                                      {get(
                                        errors,
                                        `product.prices.[${index}].amount`
                                      ) &&
                                      get(
                                        touched,
                                        `product.prices.[${index}].amount`
                                      ) ? (
                                        <span className="error">
                                          *{" "}
                                          {get(
                                            errors,
                                            `product.prices.[${index}].amount`
                                          )}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="w-full xl:w-1/6">
                                      <label
                                        htmlFor={`prices.[${index}].price`}
                                      >
                                        {t("price")}
                                      </label>
                                      <input
                                        type="number"
                                        name={`product.prices.[${index}].price`}
                                        className="mt-1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="0"
                                        value={price?.price || String()}
                                        min="0"
                                      />
                                      {get(
                                        errors,
                                        `product.prices.[${index}].price`
                                      ) &&
                                      get(
                                        touched,
                                        `product.prices.[${index}].price`
                                      ) ? (
                                        <span className="error">
                                          *{" "}
                                          {get(
                                            errors,
                                            `product.prices.[${index}].price`
                                          )}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="w-full xl:w-1/6">
                                      <label
                                        htmlFor={`prices.[${index}].currency`}
                                      >
                                        {t("currency")}
                                      </label>
                                      <Listbox
                                        value={values.product.prices}
                                        onChange={(value) =>
                                          setFieldValue(
                                            `product.prices.[${index}].currency`,
                                            value
                                          )
                                        }
                                      >
                                        <div className="relative mt-1">
                                          <Listbox.Button className="listbox-btn">
                                            <span className="listbox-title">
                                              {
                                                Structures.types.currencies.find(
                                                  (role) =>
                                                    values.product.prices[index]
                                                      .currency === role.value
                                                )?.value
                                              }
                                            </span>
                                            <span className="listbox-selector-icon">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="w-5 h-5 text-gray-400"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                                  clipRule="evenodd"
                                                />
                                              </svg>
                                            </span>
                                          </Listbox.Button>
                                          <Listbox.Options className="listbox-options">
                                            {Structures.types.currencies.map(
                                              (currency) => (
                                                <Listbox.Option
                                                  className={UString.concat_class_name(
                                                    values.product.prices[index]
                                                      .currency ===
                                                      currency.value
                                                      ? "bg-ebony text-white"
                                                      : "hover:bg-ebony hover:text-white",
                                                    "flex items-center cursor-pointer px-6 py-2"
                                                  )}
                                                  key={currency.value}
                                                  value={currency.value}
                                                >
                                                  <span>{currency.value}</span>
                                                </Listbox.Option>
                                              )
                                            )}
                                          </Listbox.Options>
                                        </div>
                                      </Listbox>
                                      {get(
                                        errors,
                                        `product.prices.[${index}].currency`
                                      ) &&
                                      get(
                                        touched,
                                        `product.prices.[${index}].currency`
                                      ) ? (
                                        <span className="error">
                                          *{" "}
                                          {get(
                                            errors,
                                            `product.prices.[${index}].currency`
                                          )}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="w-full xl:w-1/6">
                                      <label
                                        htmlFor={`prices.[${index}].vat_rate`}
                                      >
                                        {t("vat")}
                                      </label>
                                      <input
                                        type="number"
                                        name={`product.prices.[${index}].vat_rate`}
                                        className="mt-1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="0"
                                        value={price?.vat_rate || String()}
                                      />
                                      {get(
                                        errors,
                                        `product.prices.[${index}].vat_rate`
                                      ) &&
                                      get(
                                        touched,
                                        `product.prices.[${index}].vat_rate`
                                      ) ? (
                                        <span className="error">
                                          *{" "}
                                          {get(
                                            errors,
                                            `product.prices.[${index}].vat_rate`
                                          )}
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className="xl:mt-6">
                                      <button
                                        className="classic-btn justify-center red w-full xl:w-auto"
                                        type="button"
                                        onClick={() =>
                                          values?.product?.prices.length > 1 &&
                                          remove(index)
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-5 w-5"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={1.5}
                                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                            <div className="w-full mt-4 pt-4 border-t border-gray-200">
                              <button
                                className="w-full xl:ml-auto classic-btn justify-center"
                                type="button"
                                onClick={insert.bind(
                                  null,
                                  values?.product?.prices?.length + 1,
                                  {
                                    priceName: String(),
                                    currency: "TL",
                                    order_type: [
                                      Number(
                                        Object.keys(Structures.types.order)[0]
                                      ),
                                    ],
                                    amount: 1,
                                    vat_rate: Number(),
                                    price: Number(),
                                  } as IPrice
                                )}
                              >
                                {t("add-price-type")}
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    />
                  </div>
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>
      <div className="header-bottom">
        <button
          type="submit"
          disabled={Save.loading || stockCodeExists}
          className="ml-auto classic-btn"
          onClick={() => {
            Form.current.handleSubmit();
            cropsavedata();
          }}
        >
          {Save.loading ? <Spinner className="h-5 m-auto" /> : t("save")}
        </button>
      </div>
    </React.Fragment>
  );
}
