import React from 'react';
import axios from 'axios';
import { Box, Dialog, Typography, CircularProgress, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { WithTranslation, withTranslation } from 'react-i18next';

interface SubbranchState {
    branches: any[],
    havePermission: any
}
const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
const refreshToken = localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN_NAME!);

interface IProps extends WithTranslation {

}
class Subbranch extends React.Component<IProps> {

    state: SubbranchState = {
        branches: [],
        havePermission: null
    }
    async componentDidMount() {
        let subBranches = await axios.get('manager/subbranch', {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-refresh': refreshToken!
            }
        });
        let permission = await axios.get('manager/permission', {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-refresh': refreshToken!
            }
        });
        subBranches.data.map(async (b: any) => {
            let users = await axios.get(`manager/crew/${b._id}`, {
                baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'x-refresh': refreshToken!
                }
            });
            b.crew = users.data
        })

        this.setState({
            branches: subBranches.data,
            havePermission: permission.data.includes(301)
        })
    }

    Row = (branch: any) => {
        const [open, setOpen] = React.useState(false);
        branch = branch.branch
        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {branch.title}
                    </TableCell>
                    <TableCell align="right">{branch.country?.name}</TableCell>
                    <TableCell align="right">{branch.city?.name}</TableCell>
                    <TableCell align="right">{branch.district?.name}</TableCell>
                    <TableCell align="right">{new Date(branch.createdAt).toLocaleString()}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    {this.props.t('workers-of-branch')}
                                </Typography>
                                {branch.crew?.managers.length == 0 && branch.crew?.members.length == 0 && branch.crew?.couriers.length == 0 ? (
                                    <Typography gutterBottom component="div">
                                        {this.props.t('no-worker')}
                                    </Typography>
                                ) : (
                                    <Table size="small" aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>İsim</TableCell>
                                                <TableCell>Rol</TableCell>
                                                <TableCell align="right">{this.props.t('join-date')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {branch.crew?.managers.map((member: any) => (
                                                <TableRow key={member._id}>
                                                    <TableCell component="th" scope="row">
                                                        {member.name + " " + member.lastname}
                                                    </TableCell>
                                                    <TableCell>{this.props.t('manager')}</TableCell>
                                                    <TableCell align="right">{new Date(member.createdAt).toLocaleString()}</TableCell>
                                                </TableRow>
                                            ))}
                                            {branch.crew?.members.map((member: any) => (
                                                <TableRow key={member._id}>
                                                    <TableCell component="th" scope="row">
                                                        {member.name + " " + member.lastname}
                                                    </TableCell>
                                                    <TableCell>{this.props.t(member.role)}</TableCell>
                                                    <TableCell align="right">{new Date(member.createdAt).toLocaleString()}</TableCell>
                                                </TableRow>
                                            ))}
                                            {branch.crew?.couriers.map((member: any) => (
                                                <TableRow key={member._id}>
                                                    <TableCell component="th" scope="row">
                                                        {member.title}
                                                    </TableCell>
                                                    <TableCell>{this.props.t('courier')}</TableCell>
                                                    <TableCell align="right">{new Date(member.createdAt).toLocaleString()}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                )}

                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment >
        );
    }

    render() {
        if (this.state.havePermission === null) {
            return (
                <Dialog open={true} sx={{ width: "100%" }}>
                    <CircularProgress sx={{ margin: "auto" }} color="inherit" /> {this.props.t('checking-permission')}
                </Dialog>
            )
        }
        if (this.state.havePermission) {
            return (
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>{this.props.t('branch-title')}</TableCell>
                                <TableCell align="right">{this.props.t('country')}</TableCell>
                                <TableCell align="right">{this.props.t('city')}</TableCell>
                                <TableCell align="right">{this.props.t('district')}</TableCell>
                                <TableCell align="right">{this.props.t('created-at')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.branches.map((branch: any) => (
                                <this.Row key={branch.title} branch={branch} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
        }
        return (
            <Box sx={{ margin: "auto", display: "flex", flexDirection: "column", textAlign: "center" }}>
                <Typography variant="h2">403</Typography>
                <Typography variant="h2">Forbidden</Typography>
                <hr />
                <Typography variant="h5">{this.props.t('no-permission')}</Typography>
            </Box>
        )

    }
}

export default withTranslation()(Subbranch);