import React, { Component, useEffect, useState } from 'react';
import axios from 'axios'
import { Select, MenuItem, Box, Button, InputLabel, TextField, FormHelperText, Dialog, DialogTitle, DialogContent, Grid, Paper, Collapse, Typography, Stack, Avatar, Toolbar, ButtonGroup } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { withTranslation, WithTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Loading from 'components/loading';
import { convertCurrency, defaultCurrency } from 'services/helpers/exchange';
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { PaymentTypes } from "services/interfaces/report/table";

interface CaseReportInterface {
    reportData: any[],
    selectedBranch: string,
    branches: any[],
    userBranch: any;
    subBranches: any;
    sectionList: any[],
    cancelList: any[],
    loading: boolean
}

interface Props extends WithTranslation { }

const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
const refreshToken = localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN_NAME!);
class OpenTicket extends Component<Props> {

    state: CaseReportInterface = {
        reportData: [],
        userBranch: {},
        subBranches: [],
        selectedBranch: "",
        branches: [],
        sectionList: [],
        cancelList:[],
        loading: true

    }
    async componentDidMount() {
        let branch: any = await axios.get(`manager/branches`, {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                Authorization: `Bearer ${token}`,
                "x-refresh": refreshToken!,
            },
        });
        let res: any = await axios.get(`manager/report/open-account/table/${branch?.data[0]?._id}`, {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-refresh': refreshToken!
            }
        });

        let sectionList = await axios.get(`manager/sections/${branch?.data[0]?._id}`, {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-refresh': refreshToken!
            }
        });

        let cancelList = await axios.get(`manager/cancel-reasons/${branch?.data[0]?._id}`, {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-refresh': refreshToken!
            }
        });
        
        this.setState({
            reportData: res.data,
            userBranch: branch?.data[0],
            subBranches: branch?.data[0]?.subBranch,
            selectedBranch: branch?.data[0]?._id,
            branches: [branch.data[0]].concat(branch.data[0]?.subBranch),
            sectionList: sectionList.data,
            cancelList: cancelList.data,
            loading: false
        })
    }

    syncData = async () => {
        
        let res: any = await axios.get(`manager/report/open-account/table/${this.state.selectedBranch}`, {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-refresh': refreshToken!
            }
        });

        let sectionList = await axios.get(`manager/sections/${this.state.selectedBranch}`, {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-refresh': refreshToken!
            }
        });

        let cancelList = await axios.get(`manager/cancel-reasons/${this.state.selectedBranch}`, {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-refresh': refreshToken!
            }
        });
        this.setState({ reportData: res.data, sectionList: sectionList.data,cancelList: cancelList.data, loading: false })
    }
    getTotalInfo = (db: any) => {
        let totalOpenTicket = 0;
        let totalOpenTables = 0;
        let totalAmount = 0;
        let totalPaid = 0;
        let totalDiscount = 0;
        let totalCancel = 0;
        
        db?.map((data: any) => {

        totalOpenTables += (data.orders.length > 0 ? 1 : 0)  
        
        totalAmount += (data.remainingPrice || data.totalPrice)
        
        totalOpenTicket += (data.orders.length || 0)

        data?.orders?.map((dis: any) => {
            dis.products.map((product: any) => {
                if(!product.status){
                  totalCancel += product.price  
                }
             });
         });

        data?.payments?.map((dis: any) => {
            totalPaid += dis.amount
         });

        data?.discount?.map((dis: any) => {
           totalDiscount += dis.amount
         });

        });
        return { totalDiscount, totalAmount, totalPaid, totalOpenTicket, totalOpenTables, totalCancel};
    }
    getTableInfo = (data: any) => {
        let subTotal = 0;
        let servisAmount= 0;
        let discountTotal = 0;
        let coverTotal = 0;
        let AllTotal = 0;    
        let totalPaid = 0;
        let totalOrderPaid = 0;
        let remaining = 0;
        let totalCancel = 0;
        let totalCatering = 0;

        let totalProductList:any[] = [];
        let totalPaymentList:any[] = [];
        let totalPaidList:any[] = [];
        let totalCateringList:any[] = [];
        let totalDiscountList:any[] = [];
        let totalCancelList:any[] = [];
        let totalServiceList:any[] = [];
        let totalCoverList:any[] = [];

            remaining += data.remainingPrice;

            AllTotal += data.totalPrice;
            
            data.discount?.map((dis: any) => {
            totalDiscountList.push(dis);
            discountTotal += dis.amount;
            })

            data.serviceFee?.map((ser: any) => {
                totalServiceList.push(ser);
                servisAmount += ser.amount;
            })

            data.paid_orders?.map((paid: any) => {
                paid.products.map((product:any)=>{
                totalOrderPaid += product.price    
                totalPaidList.push(product);
                })
            })

            data.payments?.map((pay: any) => {
                totalPaymentList.push(pay);
                totalPaid += (pay.type === 6 ? 0 : pay.amount);
            })

            data.cover?.map((cover: any) => {
                totalCoverList.push(cover);
                coverTotal += (cover.price);
            })

            data.orders?.map((order: any) => {
                order.products.map((product: any) => {
                    if(!product.status){
                        totalCancelList.push(product);
                        totalCancel += product.price;
                        return
                    }
                    if(product.isServe){
                        totalCateringList.push(product);
                        totalCatering += product.price;
                        return    
                    }
                    subTotal += product.price;
                    totalProductList.push(product);
                })
            })
        return {totalCatering,totalCancel,totalOrderPaid,remaining,AllTotal,subTotal,coverTotal,totalCoverList, totalPaid,totalPaymentList,totalPaidList,servisAmount,totalServiceList,discountTotal,totalDiscountList,totalCancelList,totalCateringList,totalProductList};
    }
    Report = (data: any) => {      
        let db = this.getTableInfo(data);
        const [expanded, setExpanded] = useState(false);
        
        return (
            (data.orders.length>0?(<Box sx={{ marginTop: "2vw" }}>
            <Typography component="div" sx={{ cursor: "pointer" }} onClick={() => setExpanded(!expanded)}>
                <Box sx={{ textAlign: "center", backgroundColor: "#ededed", borderRadius: "10px 10px 0 0", color: "#000", fontWeight: "bold", fontSize: "22" }}
                >{this.state.sectionList.find((section:any)=> data.section===section._id).title} {data.title} {expanded ? (<KeyboardArrowUpIcon fontSize='large' />) : (<KeyboardArrowDownIcon fontSize='large' />)}</Box>
            </Typography>
            <Collapse in={expanded}>
                <Box>
                    <Box sx={{
                            overflow: "auto",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "space-evenly",
                        }}>
                        <Box sx={{ width: "45%", marginLeft: "0.1vw"  }}>
                                <Toolbar>
                                    <Typography
                                        sx={{ flex: "1 1 100%" }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >{this.props.t('tableAmount')}</Typography>
                                </Toolbar>
                                <TableContainer sx={{ minminHeight: 250 }}>
                                    <Table stickyHeader sx={{ border: 2, borderRadius: 1, borderColor: "primary.main" }}>
                                        <TableBody>
                                            {data.orders.length>0?
                                                <TableRow>
                                                    <TableCell>{this.props.t("subTotal")}</TableCell>
                                                    <TableCell>{convertCurrency(db.subTotal)} {defaultCurrency()||""}</TableCell>
                                                </TableRow>:<></>
                                                }
                                            {data.serviceFee.length>0? <TableRow>
                                                <TableCell>{this.props.t('ServisAmount')}</TableCell>
                                                <TableCell>{convertCurrency(db.servisAmount)} {defaultCurrency()||""}</TableCell>
                                            </TableRow>:<></>}
                                            {data.discount.length>0?<TableRow>
                                                <TableCell>{this.props.t('discountTotal')}</TableCell>
                                                <TableCell>{convertCurrency(db.discountTotal)} {defaultCurrency()||""}</TableCell>
                                            </TableRow>:<></>}
                                            {data.orders.length>0?<TableRow>
                                                <TableCell>{this.props.t('total')}</TableCell>
                                                <TableCell>{convertCurrency(db.AllTotal)} {defaultCurrency()||""}</TableCell>
                                            </TableRow>:<></>}
                                            {data.payments.length>0?<TableRow>
                                                <TableCell>{this.props.t('paidTotal')}</TableCell>
                                                <TableCell>{convertCurrency(db.totalPaid)} {defaultCurrency()||""}</TableCell>
                                            </TableRow>:<></>}
                                            {data.orders.length>0?<TableRow>
                                                <TableCell>{this.props.t('remainingTotal')}</TableCell>
                                                <TableCell>{convertCurrency(db.remaining)} {defaultCurrency()||""}</TableCell>
                                            </TableRow>:<></>}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                          {db.totalProductList?.length > 0 ? (
                            <Box sx={{ width: "45%", marginLeft: "0.1vw" }}>
                                <Toolbar>
                                    <Typography
                                        sx={{ flex: "1 1 100%" }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >{this.props.t('products')}</Typography>
                                </Toolbar>
                                <TableContainer sx={{ minminHeight: 250 }}>
                                    <Table stickyHeader sx={{ border: 2, borderRadius: 1, borderColor: "primary.main" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{this.props.t('product')}</TableCell>
                                                <TableCell>{this.props.t('amount')}</TableCell>
                                                <TableCell>{this.props.t('price')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {db.totalProductList.map((product: any) => (
                                                <TableRow>
                                                    <TableCell>{product.productName}</TableCell>
                                                    <TableCell>{product.quantity}</TableCell>
                                                    <TableCell>{product.price} {defaultCurrency()||""}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell>{this.props.t('total')}</TableCell>
                                                <TableCell>{convertCurrency(db.subTotal)} {defaultCurrency()||""}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ) : null}
                          {db.totalPaidList?.length > 0 ? (
                            <Box sx={{ width: "45%", marginLeft: "0.1vw" }}>
                                <Toolbar>
                                    <Typography
                                        sx={{ flex: "1 1 100%" }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >{this.props.t('paidProduct')}</Typography>
                                </Toolbar>
                                <TableContainer sx={{ minminHeight: 250 }}>
                                    <Table stickyHeader sx={{ border: 2, borderRadius: 1, borderColor: "primary.main" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{this.props.t('product')}</TableCell>
                                                <TableCell>{this.props.t('quantity')}</TableCell>
                                                <TableCell>{this.props.t('price')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {db.totalPaidList.map((product: any) => (
                                                    <TableRow>
                                                    <TableCell>{db.totalProductList.find((pro:any)=>pro._id===product._id).productName}</TableCell>
                                                    <TableCell>{product.quantity}</TableCell>
                                                    <TableCell>{product.price} {defaultCurrency()||""}</TableCell>
                                                </TableRow> 
                                                
                                            ))}
                                            <TableRow>
                                                <TableCell>{this.props.t('total')}</TableCell>
                                                <TableCell>{convertCurrency(db.totalOrderPaid)} {defaultCurrency()||""}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ) : null}
                          {db.totalPaymentList?.length > 0 ? (
                            <Box sx={{ width: "45%", marginLeft: "0.1vw" }}>
                                <Toolbar>
                                    <Typography
                                        sx={{ flex: "1 1 100%" }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >{this.props.t('checkouts')}</Typography>
                                </Toolbar>
                                <TableContainer sx={{ minminHeight: 250 }}>
                                    <Table stickyHeader sx={{ border: 2, borderRadius: 1, borderColor: "primary.main" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{this.props.t('payment_type')}</TableCell>
                                                <TableCell>{this.props.t('amount')}</TableCell>
                                                <TableCell>{this.props.t('currency')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {db.totalPaymentList.map((payment: any) => (
                                                <TableRow>
                                                    <TableCell>{this.props.t(PaymentTypes[(payment.type) as keyof typeof PaymentTypes])}</TableCell>
                                                    <TableCell>{payment.amount}</TableCell>
                                                    <TableCell>{payment.currency}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell>{this.props.t('total')}</TableCell>
                                                <TableCell>{convertCurrency(db.totalPaid)} {defaultCurrency()||""}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ) : null}
                          {db.totalCancelList?.length > 0 ? (
                            <Box sx={{ width: "45%", marginLeft: "0.1vw" }}>
                                <Toolbar>
                                    <Typography
                                        sx={{ flex: "1 1 100%" }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >{this.props.t('cancel')}</Typography>
                                </Toolbar>
                                <TableContainer sx={{ minminHeight: 250 }}>
                                    <Table stickyHeader sx={{ border: 2, borderRadius: 1, borderColor: "primary.main" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{this.props.t('cancel-reason')}</TableCell>
                                                <TableCell>{this.props.t('product')}</TableCell>
                                                <TableCell>{this.props.t('quantity')}</TableCell>
                                                <TableCell>{this.props.t('price')}</TableCell>
                                                <TableCell>{this.props.t('cancellation-time')}</TableCell>
                                                <TableCell>{this.props.t('order-time')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {db.totalCancelList.map((product: any) => (
                                                <TableRow>
                                                    <TableCell>{this.state.cancelList.find((_:any)=>_._id === product.cancelReason)?.title||""}</TableCell>
                                                    <TableCell>{product.productName}</TableCell>
                                                    <TableCell>{product.quantity}</TableCell>
                                                    <TableCell>{product.price} {defaultCurrency()||""}</TableCell>
                                                    <TableCell>{new Date(product.cancelledDate||"").toLocaleString()}</TableCell>
                                                    <TableCell>{new Date(product.createdAt||"").toLocaleString()}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell>{this.props.t('total')}</TableCell>
                                                <TableCell>{convertCurrency(db.totalCancel)} {defaultCurrency()||""}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ) : null}
                          {db.totalCateringList?.length > 0 ? (
                            <Box sx={{ width: "45%", marginLeft: "0.1vw" }}>
                                <Toolbar>
                                    <Typography
                                        sx={{ flex: "1 1 100%" }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >{this.props.t('serve')}</Typography>
                                </Toolbar>
                                <TableContainer sx={{ minminHeight: 250 }}>
                                    <Table stickyHeader sx={{ border: 2, borderRadius: 1, borderColor: "primary.main" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{this.props.t('product')}</TableCell>
                                                <TableCell>{this.props.t('quantity')}</TableCell>
                                                <TableCell>{this.props.t('price')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {db.totalCateringList.map((product: any) => (
                                                <TableRow>
                                                    <TableCell>{product.productName}</TableCell>
                                                    <TableCell>{product.quantity}</TableCell>
                                                    <TableCell>{product.price} {defaultCurrency()||""}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell>{this.props.t('total')}</TableCell>
                                                <TableCell>{convertCurrency(db.totalCatering)} {defaultCurrency()||""}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ) : null}
                          {db.totalDiscountList?.length > 0 ? (
                            <Box sx={{ width: "45%", marginLeft: "0.1vw" }}>
                                <Toolbar>
                                    <Typography
                                        sx={{ flex: "1 1 100%" }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >{this.props.t('discount')}</Typography>
                                </Toolbar>
                                <TableContainer sx={{ minminHeight: 250 }}>
                                    <Table stickyHeader sx={{ border: 2, borderRadius: 1, borderColor: "primary.main" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{this.props.t('note')}</TableCell>
                                                <TableCell>{this.props.t('amount')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {db.totalDiscountList.map((dis: any) => (
                                                <TableRow>
                                                    <TableCell>{dis.note}</TableCell>
                                                    <TableCell>{dis.amount} {defaultCurrency()||""}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell>{this.props.t('total')}</TableCell>
                                                <TableCell>{convertCurrency(db.discountTotal)} {defaultCurrency()||""}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ) : null}
                          {db.totalServiceList?.length > 0 ? (
                            <Box sx={{ width: "45%", marginLeft: "0.1vw" }}>
                                <Toolbar>
                                    <Typography
                                        sx={{ flex: "1 1 100%" }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >{this.props.t('service')}</Typography>
                                </Toolbar>
                                <TableContainer sx={{ minminHeight: 250 }}>
                                    <Table stickyHeader sx={{ border: 2, borderRadius: 1, borderColor: "primary.main" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{this.props.t('percentageService')}</TableCell>
                                                <TableCell>{this.props.t('amount')}</TableCell>
                                                <TableCell>{this.props.t('open-date')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {db.totalServiceList.map((service: any) => (
                                                <TableRow>
                                                    <TableCell>{service.percentile} {" %"}</TableCell>
                                                    <TableCell>{service.amount} {defaultCurrency()||""}</TableCell>
                                                    <TableCell>{new Date(service.updatedAt||"").toLocaleDateString()}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell>{this.props.t('total')}</TableCell>
                                                <TableCell>{convertCurrency(db.servisAmount)} {defaultCurrency()||""}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ) : null}
                          {db.totalCoverList?.length > 0 ? (
                            <Box sx={{ width: "45%", marginLeft: "0.1vw"}}>
                                <Toolbar>
                                    <Typography
                                        sx={{ flex: "1 1 100%" }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >{this.props.t('checkouts')}</Typography>
                                </Toolbar>
                                <TableContainer sx={{ minminHeight: 250 }}>
                                    <Table stickyHeader sx={{ border: 2, borderRadius: 1, borderColor: "primary.main" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{this.props.t('price')}</TableCell>
                                                <TableCell>{this.props.t('quantity')}</TableCell>
                                                <TableCell>{this.props.t('status')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {db.totalCoverList.map((cover: any) => (
                                                <TableRow>
                                                    <TableCell>{cover.price} {defaultCurrency()||""}</TableCell>
                                                    <TableCell>{cover.quantity}</TableCell>
                                                    <TableCell>{this.props.t(cover.is_paid?"isPaid":"isNotPaid")}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell>{this.props.t('total')}</TableCell>
                                                <TableCell>{convertCurrency(db.coverTotal)} {defaultCurrency()||""}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ) : null}
                    </Box>
                </Box>

            </Collapse>
        </Box>):<></>)
        )
    }
    render() {
        if (this.state.loading) {
            return (
                <Loading />
            )
        }
        let openTicketData = this.getTotalInfo(this.state.reportData)
        return (
            <Box sx={{ overflow: "auto" }}>
                <Grid container justifyContent="center" sx={{marginLeft: "0%"}}>
                    <Box sx={{ fontSize:"22px" }}>
                    {[this.state.userBranch]
                        .concat(this.state.subBranches).length>1? <Select
                        labelId="branchLabel"
                        value={this.state.selectedBranch}
                        onChange={async (e: any) => {
                            await this.setState({
                                selectedBranch: e.target.value,
                                loading:true
                            });
                            await this.syncData();
                        }}
                    >
                        {[this.state.userBranch]
                            .concat(this.state.subBranches)
                            .map((branch: any) => (
                                <MenuItem value={branch._id}>{branch.title}</MenuItem>
                            ))}
                    </Select>:this.state.userBranch.title}  
                </Box>
                </Grid>
                <Typography
                      sx={{textAlign:"center" }}
                      variant="h5"
                      id="tableTitle"
                      component="div"> {this.props.t('openTicket')}</Typography>
                <Box
                        sx={{
                            overflow: "auto",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "space-evenly",
                        }}
                    > 
                <Box sx={{ width: "45%" }}>
                <Box sx={{  padding:"5px" }}>
                                <TableContainer>
                                <Table  stickyHeader sx={{ border: 1, borderRadius: 1, borderColor: "primary.main" }}>
                                    <TableBody>
                                            <TableRow>
                                                <TableCell sx={{ width: "30%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{this.props.t("totalOpenTicket")}</TableCell>
                                                <TableCell sx={{ width: "10%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{openTicketData.totalOpenTicket}</TableCell>
                                            </TableRow>
                                        <TableRow>
                                                <TableCell sx={{ width: "30%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{this.props.t("totalOpenTable")}</TableCell>
                                                <TableCell sx={{ width: "10%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{openTicketData.totalOpenTables}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                                <TableCell sx={{ width: "30%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{this.props.t("totalOpenAmount")}</TableCell>
                                                <TableCell sx={{ width: "10%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{convertCurrency(openTicketData.totalAmount)} {defaultCurrency()}</TableCell>
                                            </TableRow>
                                    </TableBody>
                                </Table>
                                </TableContainer>
                                </Box>
                            </Box>
                <Box sx={{ width: "45%" }}>
                <Box sx={{  padding:"5px" }}>
                                <TableContainer>
                                <Table  stickyHeader sx={{ border: 1, borderRadius: 1, borderColor: "primary.main" }}>
                                    <TableBody>
                                    <TableRow>
                                                <TableCell sx={{ width: "30%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{this.props.t("totalOpenPaid")}</TableCell>
                                                <TableCell sx={{ width: "10%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{convertCurrency(openTicketData.totalPaid)} {defaultCurrency()||""}</TableCell>
                                        </TableRow>
                                    <TableRow>
                                                <TableCell sx={{ width: "30%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{this.props.t("totalOpenDiscount")}</TableCell>
                                                <TableCell sx={{ width: "10%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{convertCurrency(openTicketData.totalDiscount)} {defaultCurrency()||""}</TableCell>
                                        </TableRow>
                                    <TableRow>
                                                <TableCell sx={{ width: "30%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{this.props.t("totalOpenCancel")}</TableCell>
                                                <TableCell sx={{ width: "10%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{convertCurrency(openTicketData.totalCancel)} {defaultCurrency()}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                </TableContainer>
                                </Box>
                            </Box>
                </Box>            
                <Box>
                    {this.state.reportData.map((report: any) => (
                        <this.Report {...report}  />
                    ))}
                </Box>
            </Box>
        )
    }
}

export default withTranslation()(OpenTicket);
