import {
  Box,
  Button,
  Typography,
  Grid,
  Stack,
  FormHelperText,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { blue, blueGrey, green, orange } from "@mui/material/colors";
import Loading from "components/loading";
import { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import BranchService from "services/branch";
import Http from "services/http";
import Response from "services/response";

const payment_types = {
  1: "pos_bank",
  2: "pos_cash",
  3: "app_payment",
  6: "tick",
  7: "sodexo",
  8: "smart",
  9: "winwin",
  10: "multinet",
  11: "setcard",
  12: "metropol",
  13: "edended",
  14: "tips",
  15: "jetKurye",
  16: "not-payable",
  17: "migros-hemen",
  18: "getir-online",
  19: "trendyol",
  20: "yemek-sepeti",
  33: "bank",
};

export default function PaymentMethodsSettings() {
  const [selectedTypes, setSelectedTypes]: any = useState([]);
  const [loading, setLoading] = useState(false);
  const [editRank, setEditRank] = useState(false);
  const [editRankDialog, setEditRankDialog] = useState(false);
  const [selectedType, setSelectedType]: any = useState(null);

  const BService = new BranchService(useContext(Http.Context)!);
  const BFetch = new Response();

  const { t } = useTranslation();

  useEffect(() => (BFetch.handle(BService.getPaymentMethods()), undefined), []);

  useEffect(() => {
    if (BFetch.data) {
      setSelectedTypes(BFetch.data);
    }
  }, [BFetch.data]);

  const handleSubmit = async () => {
    setLoading(true);
    let response = await BService.editPaymentMethods({
      paymentMethods: selectedTypes,
    });
    if (response.status == 200) {
      toast.success(t("payment-method-edit-success"));
      setLoading(false);
      return;
    }
    setLoading(false);
    toast.error(t("payment-method-edit-error"));
  };

  const checkDisable = (type: any) => {
    if (loading) return true;
    if (editRank) {
      let find = selectedTypes.find((t: any) => t.type == type);
      if (!find) return true;
    }
    return false;
  };

  const handleRankClick = (type: any) => {
    let find = selectedTypes.find((t: any) => t.type == type);
    if (!find) return;
    setSelectedType(find);
    setEditRankDialog(true);
  };

  const handleTypeClick = (type: any) => {
    let isSelected = selectedTypes.find((t: any) => t.type == type);
    if (isSelected) {
      setSelectedTypes((prev: any) => prev.filter((t: any) => t.type !== type));
      return;
    }
    setSelectedTypes((prev: any) => [...prev, { type: type, rank: 1 }]);
  };

  const handleClick = (type: any) => {
    if (editRank) {
      handleRankClick(type);
      return;
    }
    handleTypeClick(type);
  };

  const EditRankDialog = () => {
    const [rank, setRank] = useState(selectedType?.rank || 1);

    const handleDialogSubmit = () => {
      if (rank <= 0 || isNaN(rank)) {
        toast.warning(t("payment-method-edit-rank-error"));
        return;
      }
      setSelectedTypes((prev: any) =>
        prev.map((t: any) => {
          if (t.type == selectedType.type) {
            t.rank = Number(rank);
          }
          return t;
        })
      );
      toast.success(t("payment-method-rank-success"));
      setSelectedType(null);
      setEditRankDialog(false);
    };

    const handleClose = () => {
      setEditRankDialog(false);
      setSelectedType(null);
    };

    return (
      <Dialog
        open={editRankDialog}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          <Box
            sx={{
              padding: "1rem",
              width: "80%",
              margin: "auto",
              borderRadius: "8vw",
              backgroundColor: blue[700],
              color: "#fff",
            }}
          >
            <Typography fontSize="1.1vw" textAlign="center">
              {t((payment_types as any)[selectedType?.type])}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ margin: "auto", width: "50%" }}>
            <TextField
              value={rank}
              size="small"
              type="number"
              onChange={(e: any) => setRank(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleDialogSubmit}>
            {t("save")}
          </Button>
          <Button variant="outlined" color="error" onClick={handleClose}>
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box>
      <EditRankDialog />
      <Loading done={!BFetch.loading} />
      <Box>
        <Typography fontSize="2vw" padding="0.7rem" paddingLeft="0.4rem">
          {t("payment-methods-settings")}
        </Typography>
      </Box>
      <hr />
      <Box>
        <Stack direction="column" spacing={3}>
          <Box sx={{ marginLeft: "2rem", marginTop: "1rem" }}>
            <FormHelperText sx={{ fontSize: "1.2rem" }}>
              <span style={{ color: orange[700], fontWeight: "bold" }}>
                {t("warning")}: &nbsp;
              </span>
              {t("payment-method-warning")}
            </FormHelperText>
            {editRank ? (
              <>
                <FormHelperText sx={{ fontSize: "1.2rem" }}>
                  <span style={{ color: blue[700], fontWeight: "bold" }}>
                    {t("information")}: &nbsp;
                  </span>
                  {t("payment-method-rank-information")}
                </FormHelperText>
              </>
            ) : (
              <>
                <FormHelperText sx={{ fontSize: "1.2rem" }}>
                  <span style={{ color: blue[700], fontWeight: "bold" }}>
                    {t("information")}: &nbsp;
                  </span>
                  {t("payment-method-information")}
                </FormHelperText>
              </>
            )}
          </Box>
          <Paper elevation={2} sx={{ padding: "1.2rem", margin: "2rem" }}>
            <Box sx={{ width: "80%", margin: "auto" }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{ overflowY: "scroll" }}
              >
                {Object.keys(payment_types).map((type: any, index: number) => (
                  <Grid
                    item
                    xs={2}
                    sm={4}
                    md={3}
                    key={index}
                    className="handle"
                  >
                    <Button
                      className="handle"
                      disabled={checkDisable(Number(type))}
                      onClick={() => handleClick(Number(type))}
                      variant="contained"
                      sx={{
                        backgroundColor: selectedTypes.find(
                          (t: any) => t.type == type
                        )
                          ? green[800]
                          : blueGrey[800],
                        borderRadius: "4rem",
                        width: "12rem",
                        height: "3rem",
                        fontSize: "0.8rem",
                        padding: "0.7rem",
                        "&:hover": {
                          backgroundColor: selectedTypes.find(
                            (t: any) => t.type == type
                          )
                            ? green[600]
                            : blueGrey[600],
                        },
                      }}
                    >
                      {t((payment_types as any)[type])}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Paper>
        </Stack>
        <Box>
          <Button
            disabled={loading}
            variant="contained"
            onClick={handleSubmit}
            sx={{
              width: "10rem",
              fontSize: "0.9rem",
              padding: "0.5rem",
              marginTop: "1rem",
              marginRight: "1rem",
              float: "right",
              backgroundColor: green[800],
              "&:hover": { backgroundColor: green[600] },
            }}
          >
            {t("save")}
          </Button>
          <Button
            disabled={loading}
            onClick={() => setEditRank(!editRank)}
            variant="contained"
            sx={{
              width: "14rem",
              fontSize: "0.9rem",
              padding: "0.5rem",
              marginTop: "1rem",
              marginRight: "1rem",
              float: "right",
              backgroundColor: editRank ? blue[900] : orange[900],
              "&:hover": {
                backgroundColor: editRank ? blue[800] : orange[800],
              },
            }}
          >
            {t("edit-rank")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
