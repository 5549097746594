import HttpClient from "api";
import { AxiosRequestConfig } from "axios";

const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
const refreshToken = localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN_NAME!);

class ProductService extends HttpClient {
    public constructor() {
        super(`${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1/manager`);

        this._initializeRequestInterceptor();
    }

    private _initializeRequestInterceptor = () => {
        this.instance.interceptors.request.use(
            this._handleRequest,
            this._handleError
        )
    }

    private _handleRequest = (config: AxiosRequestConfig) => {
        config.headers = {
            Authorization: `Bearer ${token}`,
            "x-refresh": refreshToken!,
        }

        return config;
    }

    public syncProductGroup = (id: string) => {
        return this.instance.post(`/product-group/${id}`);
    }

}

export default ProductService;
