import { ICaseReportExcel } from './../interfaces/report/case';
import { PaymentTypes } from 'services/interfaces/report/table';
import Papa from 'papaparse';
import { Parser } from "json2csv";
import fileDownload from "js-file-download";
import { t } from "i18next";
import JSZip from "jszip";
import { tableRowClasses } from '@mui/material';
const XLSX = require('xlsx');
export function numberWithCommas(x: any) {
  return x?.toFixed(2).replace(/\.?0+$/, '');
}
function convertToXLSX(data: any, fields: any, columnWidths: any, title: any) {
  const headers = fields.map((field: any) => field.label);
  const rows = data.map((row: any) => {
    const rowData = fields.map((field: any) => {
      const value = field.value(row, field);
      return value !== undefined ? value : '';
    });
    return rowData;
  });

  const worksheetData = [headers, ...rows];

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  if (columnWidths && columnWidths.length > 0) {
    for (let i = 0; i < columnWidths.length; i++) {
      if (columnWidths[i]) {
        worksheet['!cols'] = worksheet['!cols'] || [];
        worksheet['!cols'].push({ wch: columnWidths[i] });
      }
    }
  }
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, title);
  const xlsxFile = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
  return xlsxFile;
}
function compressReports(
  report: any,
  branchTitle: any,
  type: any,
  zipFile: any,
  columnWidths: any,
  fields: any
) {
  let xlsx = convertToXLSX(report, fields, columnWidths, type);
  zipFile.file(`${branchTitle}_${type}.xlsx`, xlsx, {createFolders: false});
  return zipFile;
}

function downloadFile(file: any, fileName: string) {
  fileDownload(file, fileName);
}

//report data to csv (excel) file && download
export const exportReports = (
  fields: any[],
  data: any[],
  type: string,
  startdate: any,
  enddate: any,
  branchTitle: string
) => {
  let opts = { fields, withBOM: true };
  const jsonParser = new Parser(opts);
  const csv: any = jsonParser.parse(data);
  
  fileDownload(csv, `${branchTitle}-${type}-${startdate}-${enddate}.xlsx`);
};

//for product report
export const parseProductReport = (data: any) => {
  var zip = new JSZip();
  const columnWidths = [29, 12, 12, 14];
  const fields = [
    {
      label: t("product-name"),
      value: (row: any, field: any) => row?.product?.title,
    },
    {
      label: t("sale-count"),
      value: (row: any, field: any) => numberWithCommas(row.saleCount),
    },
    {
      label: "SPH",
      value: (row: any, field: any) => numberWithCommas(row.averagePrice),
    },
    {
      label: t("total-price"),
      value: (row: any, field: any) => numberWithCommas(row.totalPrice),
    },
  ];
  const fieldsTotal = [
    {
      label: t("product-name"),
      value: (row: any, field: any) => row?.product?.title,
    },
    {
      label: t("sale-count"),
      value: (row: any, field: any) => numberWithCommas(row.saleCount),
    },
    {
      label: "SPH",
      value: (row: any, field: any) => numberWithCommas(row.averagePrice),
    },
    {
      label: t("total-price"),
      value: (row: any, field: any) => numberWithCommas(row.totalPrice),
    },
  ];
  //if more than one branch - create all branch's report and create total report
  if (data.length > 1) {
    let total: any[] = [];
    data.map((d: any) => {
      fieldsTotal.push( {
        label:  d.branchTitle +" "+ t("sale-count"),
        value: (row: any, field: any) => numberWithCommas(row[d.branchId+"-sale-count"])})
        fieldsTotal.push( {
          label: d.branchTitle +" SPH",
          value: (row: any, field: any) => numberWithCommas(row[d.branchId+"-average-price"])})
      fieldsTotal.push( {
        label: d.branchTitle +" "+ t("total-price"),
        value: (row: any, field: any) => numberWithCommas(row[d.branchId+"-total-price"])})
        zip = compressReports(d.reports, d.branchTitle, t('product-report'), zip, columnWidths, fields);
      d.reports.map((report: any) => {

        let title1= d.branchId + "-sale-count";
        let title2= d.branchId + "-total-price";
        let title3= d.branchId + "-average-price";
    
        report[title1]= report.saleCount
        report[title2]= report.totalPrice       
        report[title3]= report.averagePrice                 
       
        let i = total.findIndex(
          (p: any) => p.product?.title == report.product?.title);
        if (i > -1) {
          total[i][title1]= report.saleCount
          total[i][title2]= report.totalPrice
          total[i][title3]= report.averagePrice
          total[i].saleCount += report.saleCount;
          total[i].totalPrice += report.totalPrice;
          return;
        }
        
        total.push(report);
      });
    });
    
    zip = compressReports(total, t('general'), t('product-report'), zip, columnWidths, fieldsTotal);
    zip.generateAsync({type: "blob"}).then((content: any) => {
        downloadFile(content, `${t('product-report')}-${data[0].from}-${data[0].to}.zip`)
    })
    return;
  }
  if(data[0]){
    const xlsxData = convertToXLSX(data[0].reports, fields,columnWidths,t('product-report'));
    const xlsxFile = new Blob([xlsxData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    downloadFile(xlsxFile, `${t('product-report')}-${data[0].from}-${data[0].to}.xlsx`);
  }
};

export const parseCategoryReport = (data: any) => {
  var zip = new JSZip();
  const columnWidths = [25, 12, 12, 12];
  const fields = [
    {
      label: t("category"),
      value: (row: any, field: any) => row.categoryTitle,
    },
    {
      label: t("sale-count"),
      value: (row: any, field: any) => numberWithCommas(row.saleCount),
    },
    {
      label: "SPH",
      value: (row: any, field: any) => numberWithCommas(row.averagePrice),
    },
    {
      label: t("total-price"),
      value: (row: any, field: any) => numberWithCommas(row.totalPrice),
    },
  ];
  const fieldsTotal = [
    {
      label: t("category"),
      value: (row: any, field: any) => row.categoryTitle,
    },
    {
      label: t("sale-count"),
      value: (row: any, field: any) => numberWithCommas(row.saleCount),
    },
    {
      label: "SPH",
      value: (row: any, field: any) => numberWithCommas(row.averagePrice),
    },
    {
      label: t("total-price"),
      value: (row: any, field: any) => numberWithCommas(row.totalPrice),
    },
  ];

  if (data.length > 1) {
    let total: any[] = [];   
    data.map((d: any) => {
      fieldsTotal.push( {
        label:  d.branchTitle +" "+ t("sale-count"),
        value: (row: any, field: any) => numberWithCommas(row[d.id+"-sale-count"])})
        fieldsTotal.push( {
          label: d.branchTitle +" SPH",
          value: (row: any, field: any) => numberWithCommas(row[d.id+"-average-price"])})
      fieldsTotal.push( {
        label: d.branchTitle +" "+ t("total-price"),
        value: (row: any, field: any) => numberWithCommas(row[d.id+"-total-price"])})
        zip = compressReports(d.reports, d.branchTitle, t('category-report'), zip, columnWidths, fields);
      d.reports.map((report: any) => {
        let title1= d.id + "-sale-count";
        let title2= d.id + "-total-price";
        let title3= d.id + "-average-price";
    
        report[title1]= report.saleCount
        report[title2]= report.totalPrice       
        report[title3]= report.averagePrice    
        let i = total.findIndex(
          (c: any) => c.categoryTitle == report.categoryTitle
        );
        if (i > -1) {
          total[i][title1]= report.saleCount
          total[i][title2]= report.totalPrice
          total[i][title3]= report.averagePrice
          total[i].saleCount += report.saleCount;
          total[i].totalPrice += report.totalPrice;
          return;
        }
        total.push(report);
      });
    });
    zip = compressReports(total, t('general'), t('category-report'), zip, columnWidths, fieldsTotal);
    zip.generateAsync({type: "blob"}).then((content: any) => {
        downloadFile(content, `${t('category-report')}-${data[0].from}-${data[0].to}.zip`)
    })
    return;
  }
  if(data[0]){
      let xlsxFile = convertToXLSX(data[0].reports, fields,columnWidths,t('category-report'));
  downloadFile(xlsxFile, `${data[0].branchTitle}-${t('category-report')}-${data[0].from}-${data[0].to}`);
  }
};

export const parseOrderTypeReport = (data: any) => {
  var zip = new JSZip();
  const columnWidths = [25, 19, 19];
  const fields = [
    {
      label: t('payment-type'),
      value: (row: any, field: any) =>  row.name
    },
    {
      label: t('sale-count'),
      value: (row: any, field: any) => numberWithCommas(row.saleCount)
    },
    {
      label: t('total-price'),
      value: (row: any, field: any) => numberWithCommas(row.totalPrice)
    }
  ];
  const fieldsTotal = [
    {
      label: t('payment-type'),
      value: (row: any, field: any) =>  row.name
    },
    {
      label: t('sale-count'),
      value: (row: any, field: any) => numberWithCommas(row.saleCount)
    },
    {
      label: t('total-price'),
      value: (row: any, field: any) => numberWithCommas(row.totalPrice)
    }
  ];
  if(data.length > 1){
    let total: any[] = [];
    data.map((d: any) => {
      fieldsTotal.push( {
        label:  d.branchTitle +" "+ t("sale-count"),
        value: (row: any, field: any) => numberWithCommas(row[d.id+"-sale-count"])})
      fieldsTotal.push( {
        label: d.branchTitle +" "+ t("total-price"),
        value: (row: any, field: any) => numberWithCommas(row[d.id+"-total-price"])})
      zip = compressReports(d.reports, d.branchTitle, t('order-type-report'), zip, columnWidths, fields);
      d.reports.map((report: any) => {
        let title1= d.id + "-sale-count";
        let title2= d.id + "-total-price";

        report[title1]= report.saleCount
        report[title2]= report.totalPrice  
        let i = total.findIndex((order: any) => order.name == report.name);
        if(i > -1){
          total[i][title1]= report.saleCount
          total[i][title2]= report.totalPrice
          total[i].saleCount += report.saleCount;
          total[i].totalPrice += report.totalPrice;
          return;
        }
        total.push(report);
      });
    });
    zip = compressReports(total, t('general'), t('order-type-report'), zip, columnWidths, fieldsTotal);
    zip.generateAsync({type: "blob"}).then((content: any) => {
      downloadFile(content, `${t('order-type-report')}.zip`);
    });
    return;
  }
  let xlsxFile = convertToXLSX(data[0].reports, fields,columnWidths,t('order-type-report'));
  downloadFile(xlsxFile, `${data[0].branchTitle}-${t('order-type-report')}.xlsx`);
}

export const parsePaymentTypeReport = (data: any) => {
  var zip = new JSZip();
  const columnWidths = [20, 19];
  const fields = [
    {
      label: t('payment-type'),
      value: (row: any, field: any) => row.name
    },
    {
      label: t('total-price'),
      value: (row: any, field: any) => numberWithCommas(row.totalPrice)
    }
  ];
  const fieldsTotal = [
    {
      label: t('payment-type'),
      value: (row: any, field: any) => row.name
    },
    {
      label: t('total-price'),
      value: (row: any, field: any) => numberWithCommas(row.totalPrice)
    }
  ];
  if(data.length > 1){
    let total: any[] = [];
    data.map((d: any) => {
      fieldsTotal.push( {
        label: d.branchTitle +" "+ t("total-price"),
        value: (row: any, field: any) => numberWithCommas(row[d.id+"-total-price"])})
      zip = compressReports(d.reports, d.branchTitle, t('payment-type-report'), zip, columnWidths, fields);
      d.reports.map((report: any) => {
        let title1= d.id + "-total-price";
        report[title1]= report.totalPrice
          let i = total.findIndex((r: any) => r.name == report.name);
          if(i > -1){
            total[i][title1] = report.totalPrice;
            total[i].totalPrice += report.totalPrice;
            return;
          }
          total.push(report);
      });
      
    })
    zip = compressReports(total, t('general'), t('payment-type-report'), zip, columnWidths, fieldsTotal);
    zip.generateAsync({type: "blob"}).then((content: any) => {
    downloadFile(content, `${t('payment-type-report')}.zip`);
      })
    return;
  }
  let xlsx = convertToXLSX(data[0].reports, fields, columnWidths, t('payment-type-report'));
  downloadFile(xlsx, `${data[0].branchTitle}-${t('payment-type-report')}.xlsx`);
}

export const parseCrewReport = (data: any, branchTitle: string) => {
  let reports: any[] = [];
  const columnWidths = [19,15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15];
  data.map((worker: any) => {
    let report = {
      name: worker.name,
      totalPayment: 0,
      totalTips: 0,
      totalNotPayable: 0,
      totalTick: 0,
      soldProductCount: 0,
      soldProductAmount: 0,
      expenseCount: 0,
      expenseAmount: 0,
      cancelledProductCount: 0,
      cancelledProductAmount: 0,
      cateringCount: 0,
      cateringAmount: 0,
      discountCount: 0,
      discountAmount: 0
    };

    worker.payments?.map((payment: any) => {
      if(payment.type == 14){
        report.totalTips += payment.amount;
        return;
      }
      if(payment.type == 16){
        report.totalNotPayable += payment.amount;
        return;
      }
      report.totalPayment += payment.amount;
    });
    worker.ticks?.map((tick: any) => {
      report.totalTick += tick.amount;
    });
    worker.products?.map((product: any) => {
      report.soldProductCount += product.quantity;
      report.soldProductAmount += product.price;
    });
    worker.cancelledProducts?.map((product: any) => {
      report.cancelledProductCount += product.quantity;
      report.cancelledProductAmount += product.price;
    });
    worker.caterings?.map((catering: any) => {
      report.cateringCount += catering.quantity;
      report.cateringAmount += catering.price;
    });
    worker.discounts?.map((discount: any) => {
      report.discountCount++;
      report.discountAmount += discount.amount;
    });
    worker.expenses?.map((expense: any) => {
      report.expenseCount++;
      report.expenseAmount += expense.amount;
    });

    reports.push(report);
  });

  const fields = [
    {
      label: t('name'),
      value: (row: any, field: any) => row.name
    },
    {
      label: t('total-payment'),
      value: (row: any, field: any) => numberWithCommas(row.totalPayment)
    },
    {
      label: t('total-tips'),
      value: (row: any, field: any) => numberWithCommas(row.totalTips)
    },
    {
      label: t('total-not-payable'),
      value: (row: any, field: any) => numberWithCommas(row.totalNotPayable)
    },
    {
      label: t('total-tick'),
      value: (row: any, field: any) => numberWithCommas(row.totalTick)
    },
    {
      label: t('sold-product-count'),
      value: (row: any, field: any) => row.soldProductCount
    },
    {
      label: t('sold-product-amount'),
      value: (row: any, field: any) => numberWithCommas(row.soldProductAmount)
    },
    {
      label: t('cancelled-product-count'),
      value: (row: any, field: any) => row.cancelledProductCount
    },
    {
      label: t('cancelled-product-amount'),
      value: (row: any, field: any) => numberWithCommas(row.cancelledProductAmount)
    },
    {
      label: t('total-expense-count'),
      value: (row: any, field: any) => numberWithCommas(row.expenseCount)
    },
    {
      label: t('total-expense-amount'),
      value: (row: any, field: any) => numberWithCommas(row.expenseAmount)
    },
    {
      label: t('catering-count'),
      value: (row: any, field: any) => row.cateringCount
    },
    {
      label: t('catering-amount'),
      value: (row: any, field: any) => numberWithCommas(row.cateringAmount)
    },
    {
      label: t('discount-count'),
      value: (row: any, field: any) => row.discountCount
    },
    {
      label: t('discount-amount'),
      value: (row: any, field: any) => numberWithCommas(row.discountAmount)
    },
  ];

  let xlsx = convertToXLSX(reports, fields, columnWidths, t('worker-report'));
  downloadFile(xlsx, `${branchTitle}-${t('worker-report')}.xlsx`)
}

export const parseCancelReport = (data: any, branchTitle: string) => {
  const columnWidths = [25, 10, 10, 19, 19, 15, 15];
  const fields = [
    {
      label: t('product'),
      value: (row: any, field: any) => row.product.title
    },
    {
      label: t('quantity'),
      value: (row: any, field: any) => numberWithCommas(row.product.quantity)
    },
    {
      label: t('price'),
      value: (row: any, field: any) => numberWithCommas(row.product.price)
    },
    {
      label: t('order-time'),
      value: (row: any, field: any) => new Date(row.orderDate).toLocaleString()
    },
    {
      label: t('cancellation-time'),
      value: (row: any, field: any) => new Date(row.cancelledDate).toLocaleString()
    },
    {
      label: t('user'),
      value: (row: any, field: any) => row.cancelledBy
    },
    {
      label: t('reason'),
      value: (row: any, field: any) => row.cancelReason
    }
  ];

  let xlsx = convertToXLSX(data, fields, columnWidths, t('cancel-report'));
  downloadFile(xlsx, `${branchTitle}-${t('cancel-report')}.xlsx`)
}
export const parseCaseReport = (data: any) => {
  var zip = new JSZip();
  const columnWidths = [15,15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15];
  const fields = [
    {
      label: t('case-open-date'),
      value: (row: any, field: any) => row.caseDate
    },
    {
      label: t('total-payment'),
      value: (row: any, field: any) => numberWithCommas(row.totalPayment)
    },
    {
      label: t('total-tips'),
      value: (row: any, field: any) => numberWithCommas(row.totalTips)
    },
    {
      label: t('total-not-payable'),
      value: (row: any, field: any) => numberWithCommas(row.totalNotPayable)
    },
    {
      label: t('total-tick'),
      value: (row: any, field: any) => numberWithCommas(row.totalTicks)
    },
    {
      label: t('total-expense-count'),
      value: (row: any, field: any) => row.expenseCount
    },
    {
      label: t('total-expense-amount'),
      value: (row: any, field: any) => numberWithCommas(row.expenseAmount)
    },
    {
      label: t('total-catering-count'),
      value: (row: any, field: any) => row.cateringCount
    },
    {
      label: t('total-catering-amount'),
      value: (row: any, field: any) => numberWithCommas(row.cateringAmount)
    },
    {
      label: t('total-discount-count'),
      value: (row: any, field: any) => row.discountCount
    },
    {
      label: t('total-discount-amount'),
      value: (row: any, field: any) => numberWithCommas(row.discountAmount)
    },
    {
      label: t('total-cancellation-count'),
      value: (row: any, field: any) => row.cancellationsCount
    },
    {
      label: t('total-cancellation-amount'),
      value: (row: any, field: any) => numberWithCommas(row.cancellationsAmount)
    },
    
  ]

  data?.map((data: any) => {
    let reports: ICaseReportExcel[] = [];
    let total = {
      caseDate: "Total",
      totalPayment: 0,
      totalTips: 0,
      totalNotPayable: 0,
      totalTicks: 0,
      expenseAmount: 0,
      expenseCount: 0,
      cateringAmount: 0,
      cateringCount: 0,
      discountAmount: 0,
      discountCount: 0,
      cancellationsAmount: 0,
      cancellationsCount: 0
    }
    data.data?.map((report: any) => {
      let modifiedReport: ICaseReportExcel = {
        caseDate: report.label,
        totalPayment: 0,
        totalTips: 0,
        totalNotPayable: 0,
        totalTicks: 0,
        expenseAmount: 0,
        expenseCount: 0,
        cateringAmount: 0,
        cateringCount: 0,
        discountAmount: 0,
        discountCount: 0,
        cancellationsAmount: 0,
        cancellationsCount: 0
      };
      report.result?.map((payment: any) => {
        if(payment.type == 14){
          modifiedReport.totalTicks += payment.amount;
          total.totalTicks += payment.amount;
          return;
        }
        if(payment.type == 16){
          modifiedReport.totalNotPayable += payment.amount;
          total.totalNotPayable += payment.amount;
          return;
        }
        if(payment.type == 6){
          modifiedReport.totalTicks += payment.amount;
          total.totalTicks += payment.amount;
          return;
        }
        modifiedReport.totalPayment += payment.amount;
        total.totalPayment += payment.amount;
      });

      report.exs?.map((cost: any) => {
          switch(cost.name){
            case "expenses":
              modifiedReport.expenseAmount += cost.amount;
              modifiedReport.expenseCount += cost.quantity;
              total.expenseCount += cost.quantity;
              total.expenseAmount += cost.amount;
              break;
            case "discounts":
              modifiedReport.discountAmount += cost.amount;
              modifiedReport.discountCount += cost.quantity;
              total.discountAmount += cost.amount;
              total.discountCount += cost.quantity;
              break;
            case "caterings":
              modifiedReport.cateringAmount += cost.amount;
              modifiedReport.cateringCount += cost.quantity;
              total.cateringAmount += cost.amount;
              total.cateringCount += cost.quantity;
              break;
            case "cancellations":
              modifiedReport.cancellationsAmount += cost.amount;
              modifiedReport.cancellationsCount += cost.quantity;
              total.cancellationsAmount += cost.amount;
              total.cancellationsCount += cost.quantity;
              break;
            default: 
              break;
          }
      });
      reports.push(modifiedReport);
    });
    reports.push(total);
    zip = compressReports(reports, data.title, t('case-report'), zip, columnWidths, fields);
  });
  zip.generateAsync({type: "blob"}).then((content: any) => {
    fileDownload(content, `${t('case-report')}.zip`);
  })
}

export const parseTableReport = (data: any, branchTitle: string) => {
  const columnWidths = [15,15, 15, 15, 15, 15, 15, 15, 15, 15, 15];
  const fields = [
    {
      label: t('table'),
      value: (row: any, field: any) => row.tableTitle
    },
    {
      label: t('section'),
      value: (row: any, field: any) => row.sectionTitle
    },
    {
        label: t('total-order-count'),
        value: (row: any, field: any) => numberWithCommas(row.totalOrder)
    },
    {
      label: t('total-sold-product'),
      value: (row: any, field: any) => numberWithCommas(row.totalSoldProduct)
    },
    {
        label: t('total-revenue'),
        value: (row: any, field: any) => numberWithCommas(row.totalRevenue)
      },
    {
      label: t('total-tips'),
      value: (row: any, field: any) => numberWithCommas(row.totalTips)
    },
    {
      label: t('total-cancellation-count'),
      value: (row: any, field: any) => numberWithCommas(row.totalCancellationCount)
    },
    {
      label: t('total-cancellation-amount'),
      value: (row: any, field: any) => numberWithCommas(row.totalCancellationAmount)
    },
    {
      label: t('total-catering-count'),
      value: (row: any, field: any) => numberWithCommas(row.totalCateringCount)
    },
    {
      label: t('total-catering-amount'),
      value: (row: any, field: any) => numberWithCommas(row.totalCateringAmount)
    },
    {
      label: t('total-not-payable'),
      value: (row: any, field: any) => numberWithCommas(row.totalNotPayable)
    },
  ]
  let xlsx = convertToXLSX(data, fields, columnWidths, t('table-report'));
  downloadFile(xlsx, `${branchTitle}-${t('table-report')}.csv`);
}

export const parseExpenseReport = (data: any, branchTitle: string) => {
  const columnWidths = [19, 19, 10, 10, 15, 15, 15, 15];
  const fields = [
    {
      label: t('title'),
      value: (row: any, field: any) => row.title
    },
    {
      label: t('description'),
      value: (row: any, field: any) => row.description
    },
    {
      label: t('amount'),
      value: (row: any, field: any) => numberWithCommas(row.expense_amount)
    },
    {
      label: t('currency'),
      value: (row: any, field: any) => row.currency
    },
    {
      label: t('payment-type'),
      value: (row: any, field: any) => row.expense_type == 2 ? t('open-account') : t('pos_cash')
    },
    {
      label: t('open-account'),
      value: (row: any, field: any) => row.expense_type == 2 ? row.openAccount?.name : "-"
    },
    {
      label: t('createdBy'),
      value: (row: any, field: any) => row.user?.name
    },
    {
      label: t('created-at'),
      value: (row: any, field: any) => new Date(row.createdAt)?.toLocaleDateString()
    },
  ];

  let xlsx = convertToXLSX(data, fields, columnWidths, t('expense-report'));
  downloadFile(xlsx, `${branchTitle}-${t('expense-report')}.xlsx`);
}

export const parseOpenAccountReport = (data: any, branchTitle: string) => {
  const columnWidths = [19, 15, 15, 15];
  const fields = [
    {
      label: t('name'),
      value: (row: any, field: any) => row.name
    },
    {
      label: t('total-amount'),
      value: (row: any, field: any) => numberWithCommas(row.totalAmount)
    },
    {
      label: t('createdBy'),
      value: (row: any, field: any) => row.createdBy?.name + " " + row.createdBy?.lastname 
    },
    {
      label: t('created-at'),
      value: (row: any, field: any) => new Date(row.createdAt).toLocaleDateString()
    }
  ];

  let xlsx = convertToXLSX(data, fields, columnWidths, t('open-account-report'));
  downloadFile(xlsx, `${branchTitle}-${t('open-account-report')}.xlsx`);
}

export const parseOpenAccountDetailReport = (data: any) => {
  const columnWidths = [19, 15, 15, 15, 15, 15];
  const fields = [
    {
      label: t('title'),
      value: (row: any, field: any) => row.title
    },
    {
      label: t('description'),
      value: (row: any, field: any) => row.description
    },
    {
      label: t('amount'),
      value: (row: any, field: any) => numberWithCommas(row.expense_amount)
    },
    {
      label: t('currency'),
      value: (row: any, field: any) => row.currency
    },
    {
      label: t('createdBy'),
      value: (row: any, field: any) => row.user?.name
    },
    {
      label: t('created-at'),
      value: (row: any, field: any) => new Date(row.createdAt)?.toLocaleDateString()
    },
  ];
  
  let xlsx = convertToXLSX(data.expenses, fields, columnWidths, t('open-account-detail'));
  downloadFile(xlsx, `${data.name}-${t('open-account-detail')}.xlsx`);
}

export const parseOdenmezReport = (data: any, branchTitle: string) => {
  const columnWidths = [19, 15, 15];
  const fields = [
    {
      label: t('customer'),
      value: (row: any, field: any) => row?.specialCustomer?.name
    },
    {
      label: t('note'),
      value: (row: any, field: any) => row?.note?.note
    },
    {
      label: t('_amount'),
      value: (row: any, field: any) => numberWithCommas(row.amount)
    }
  ];

  let xlsx = convertToXLSX(data, fields, columnWidths, t('odenmez-report'));
  fileDownload(xlsx, `${branchTitle}-${t('odenmez-report')}.xlsx`);
}

export const parseSpecialCustomerReport = (data: any, branchTitle: string) => {
  const columnWidths = [19, 15, 15, 15, 15 ,15];
  const fields = [
    {
      label: t('name'),
      value: (row: any, field: any) => row.name
    },
    {
      label: t('phone_number'),
      value: (row: any, field: any) => row.gsm_no
    },
    {
      label: t('total-amount'),
      value: (row: any, field: any) => numberWithCommas(row.totalAmount)
    },
    {
      label: t('applied-discount-percentile'),
      value: (row: any, field: any) => row.discount
    },
    {
      label: t('createdBy'),
      value: (row: any, field: any) => row.createdBy?.name + " " + row.createdBy?.lastname
    },
    {
      label: t('created-at'),
      value: (row: any, field: any) => new Date(row.createdAt)?.toLocaleDateString()
    }
  ];

  let xlsx = convertToXLSX(data, fields, columnWidths, t('special-customer-report'));
  fileDownload(xlsx, `${branchTitle}-${t('special-customer-report')}.xlsx`)
}

export const parseSpecialCustomerDetail = (data: any) => {

  const calculatePayment = (payments: any[], notPayable: boolean) => {
    let total = 0;
    payments.map((payment: any) => {
        if(payment.type == 16){
            if(notPayable) total += payment.amount;
        }
        if(!notPayable) total += payment.amount;
    });
    return total;
}
  const columnWidths = [15, 15, 15, 17, 15];
  const fields = [
    {
      label: t('table'),
      value: (row: any, field: any) => row.table?.title
    },
    {
      label: t('total-order-revenue'),
      value: (row: any, field: any) => numberWithCommas(calculatePayment(row.payments, false))
    },
    {
      label: t('total-not-payable'),
      value: (row: any, field: any) => numberWithCommas(calculatePayment(row.payments, true))
    },
    {
      label: t('worker'),
      value: (row: any, field: any) => row.user?.name + " " + row.user?.lastname
    },
    {
      label: t('created-at'),
      value: (row: any, field: any) => new Date(row.createdAt)?.toLocaleDateString()
    }
  ];

  let xlsx = convertToXLSX(data?.checks, fields, columnWidths, t('detail'));
  fileDownload(xlsx, `${data.name}-${t('detail')}.xlsx`)
}
