import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import SyncIcon from "@mui/icons-material/Sync";
import ProductService from "api/product";
import Authenticate from "services/authenticate";
import ProductGroupService from "services/product-group";
import BranchService from "services/branch";
import Http from "services/http";
import Response from "services/response";
import Loading from "components/loading";
import { blue, green, red } from "@mui/material/colors";

const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
const refreshToken = localStorage.getItem(
  process.env.REACT_APP_REFRESH_TOKEN_NAME!
);

export default function ProductGroup() {
  const [editBranches, setEditBranches] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [sync, setSync]: any = useState(false);
  const groups: any = useRef([]);
  const branches: any = useRef([]);

  const { t } = useTranslation();

  const context = useContext(Authenticate.Context!);
  const PService = new ProductGroupService(useContext(Http?.Context)!);
  const BService = new BranchService(useContext(Http?.Context)!);

  const PFetch = new Response();
  const BFetch = new Response();

  useEffect(() => (PFetch.handle(PService.getAll()), undefined), []);
  useEffect(() => (BFetch.handle(BService.getSubBranches()), undefined), []);

  useEffect(() => {
    if (PFetch.data) {
      groups.current = PFetch.data;
    }
  }, [PFetch.data]);

  useEffect(() => {
    if (BFetch.data) {
      branches.current = BFetch.data;
    }
  }, [BFetch.data]);

  const EditUsingBranchDialog = () => {
    const [group, setGroup]: any = useState(null);
    const [selectedBranch, setBranch]: any = useState(null);
    const [quest, setQuest] = useState(false);
    const [del, setDel] = useState(false);
    const [load, setLoad] = useState(false);
    const [response, setResponse]: any = useState(null);

    useEffect(() => {
      if (!group) {
        const g = groups?.current?.find((p: any) => p._id == selectedGroup);
        setGroup(g);
      }
    }, []);

    const checkBranchStatus = (branch: any) => {
      let status = {
        usingSameGroup: false,
        usingDifferentGroup: false,
      };
      if (
        branch.productGroup &&
        branch.productGroup?.toString() != group?._id?.toString()
      ) {
        status.usingDifferentGroup = true;
      }
      if (
        group?.branchesUsing?.find((b: any) => b.branch?._id == branch._id) ||
        branch?.productGroup?.toString() == group?._id?.toString()
      ) {
        status.usingSameGroup = true;
      }
      return status;
    };

    const editBranch = (productGroup: any) => {
      let b = branches?.current?.map((b: any) => {
        if (b._id == selectedBranch._id) {
          b.productGroup = productGroup;
        }
        return b;
      });
      branches.current = b;
    };

    const editGroup = (group: any) => {
      let g = groups?.current?.map((p: any) => {
        if (p._id == group._id) {
          p = group;
          setGroup(group);
        }
        return p;
      });
      groups.current = g;
    };

    const deleteBranchFromGroup = async (): Promise<void> => {
      try {
        let res = await PService.deleteFromTargetBranch(selectedBranch._id, {
          product_group: selectedGroup,
        });
        setResponse(res);
        editBranch(undefined);
        editGroup(res.data);
        setGroup(res.data);
        setLoad(false);
      } catch (e: any) {
        setResponse(e);
        setLoad(false);
      }
    };

    const importProductGroup = async (): Promise<void> => {
      try {
        let res = await PService.copyTargetBranch(selectedBranch._id, {
          product_group: selectedGroup,
        });
        setResponse(res);
        editBranch(selectedGroup);
        editGroup(res.data);
        setGroup(res.data);
        setLoad(false);
      } catch (e: any) {
        setResponse(e);
        setLoad(false);
      }
    };

    const handleBranchClick = (branch: any, del: boolean): void => {
      setResponse(null);
      setBranch(branch);
      setDel(del);
      setQuest(true);
    };

    const handleButtonClick = (confirm: boolean): void => {
      if (confirm) {
        setLoad(true);
        if (del) {
          deleteBranchFromGroup();
          return;
        }
        importProductGroup();
        return;
      }
      setBranch(null);
      setQuest(false);
      setLoad(false);
    };

    return (
      <Dialog
        open={editBranches}
        onClose={() => setEditBranches(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>{!quest ? <>{t("edit-branches")}</> : null}</DialogTitle>
        {quest ? (
          <DialogContent>
            <Loading done={!load} />
            {response ? (
              <>
                {response.status !== 200 ? (
                  <>
                    {del ? (
                      <Typography fontSize="1.4rem" textAlign="center">
                        {t("product-group-del-error")}
                      </Typography>
                    ) : (
                      <Typography fontSize="1.4rem" textAlign="center">
                        {t("product-group-import-error")}
                      </Typography>
                    )}
                  </>
                ) : (
                  <>
                    {del ? (
                      <Typography fontSize="1.4rem" textAlign="center">
                        {t("product-group-del-success")}
                      </Typography>
                    ) : (
                      <Typography fontSize="1.4rem" textAlign="center">
                        {t("product-group-import-success")}
                      </Typography>
                    )}
                  </>
                )}
              </>
            ) : (
              <Typography fontSize="1.4rem" textAlign="center">
                {del
                  ? t("product-group-branch-delete-question")
                  : t("product-group-import-branch-question")}
              </Typography>
            )}
          </DialogContent>
        ) : (
          <DialogContent>
            {branches?.current?.length == 0 ? (
              <>{t("no-sub-branch")}</>
            ) : (
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{ overflowY: "scroll" }}
              >
                {branches?.current?.map((branch: any, index: number) => (
                  <Grid item xs={2} sm={4} md={3} key={index}>
                    <Button
                      disabled={checkBranchStatus(branch).usingDifferentGroup}
                      onClick={() => {
                        if (!checkBranchStatus(branch).usingDifferentGroup) {
                          handleBranchClick(
                            branch,
                            checkBranchStatus(branch).usingSameGroup
                          );
                        }
                      }}
                      sx={{
                        fontSize: "0.8rem",
                        width: "12rem",
                        height: "5rem",
                        cursor: checkBranchStatus(branch)?.usingDifferentGroup
                          ? "not-allowed"
                          : "pointer",
                        "&:hover": {
                          backgroundColor: checkBranchStatus(branch)
                            ?.usingSameGroup
                            ? green[700]
                            : checkBranchStatus(branch)?.usingDifferentGroup
                            ? red[700]
                            : blue[800],
                        },
                        backgroundColor: checkBranchStatus(branch)
                          ?.usingSameGroup
                          ? green[800]
                          : checkBranchStatus(branch)?.usingDifferentGroup
                          ? red[800]
                          : blue[900],
                      }}
                      variant="contained"
                    >
                      {branch.title}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            )}
          </DialogContent>
        )}

        <DialogActions>
          {quest ? (
            <>
              {response ? (
                <>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleButtonClick(false)}
                    sx={{
                      fontSize: "1vw",
                      width: "8vw",
                      backgroundColor: red[900],
                      "&:hover": { backgroundColor: red[700] },
                    }}
                  >
                    {t("back")}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleButtonClick(true)}
                    sx={{
                      fontSize: "1vw",
                      width: "8vw",
                      backgroundColor: green[900],
                      "&:hover": { backgroundColor: green[700] },
                    }}
                  >
                    {t("yes")}
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleButtonClick(false)}
                    sx={{
                      fontSize: "1vw",
                      width: "8vw",
                      backgroundColor: red[900],
                      "&:hover": { backgroundColor: red[700] },
                    }}
                  >
                    {t("cancel")}
                  </Button>
                </>
              )}
            </>
          ) : (
            <>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setEditBranches(false)}
              >
                {t("close-it")}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#757575",
      },
      secondary: {
        main: "#11cb5f",
      },
    },
  });

  const showToastify = (message: string, type: string) => {
    switch (type) {
      case "success":
        toast.success(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
      case "error":
        toast.error(message, {
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        break;
    }
  };

  const Row = (props: any) => {
    const [open, setOpen] = useState(false);
    const { row } = props;

    const deleteProductGroup = async (id: string) => {
      Swal.fire({
        icon: "question",
        title: t("delete-product-group-question")!,
        text: t("delete-product-group-text")!,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: t("cancel")!,
        confirmButtonText: t("yes")!,
      }).then(async (res: any) => {
        if (res.isConfirmed) {
          let response = await axios.delete(`manager/product-group/${id}`, {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
              Authorization: `Bearer ${token}`,
              "x-refresh": refreshToken!,
            },
          });
          if (response.status == 200) {
            PFetch.handle(PService.getAll());
            showToastify(t("delete-product-group-success")!, "success");
            return;
          }
          showToastify(t("server-error")!, "error");
        }
      });
    };
    const syncFunction = async (id: string) => {
      setSync(true);
      const Service = new ProductService();
      let response = await Service.syncProductGroup(id);
      const toa: any = toast.loading(t("sync-in-progress"));

      toast.update(toa, {
        render: t("sync-success"),
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        draggable: true,
        pauseOnHover: false,
      });
      setSync(false);
    };

    const addOnClick = (row: any) => {
      setEditBranches(true);
      setSelectedGroup(row._id);
    };

    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.title}
          </TableCell>
          <TableCell align="right">
            {row.author.name} {row.author.lastname}
          </TableCell>
          <TableCell align="right">
            {new Date(row.createdAt).toLocaleString()}
          </TableCell>
          <TableCell align="right">
            {new Date(row.updatedAt).toLocaleString()}
          </TableCell>
          <ThemeProvider theme={theme}>
            <Link to={`action/${row._id}`}>
              <TableCell align="right">
                <Button color="primary" startIcon={<EditIcon />}>
                  {t("EDIT")}
                </Button>
              </TableCell>
            </Link>
            <TableCell align="right">
              <Button
                disabled={sync || false}
                color="primary"
                onClick={() => syncFunction(row._id)}
                startIcon={<SyncIcon />}
              >
                {t("SYNC")}
              </Button>
            </TableCell>
            <TableCell align="right">
              <Button
                color="primary"
                startIcon={<DeleteIcon />}
                onClick={() => deleteProductGroup(row._id)}
              >
                {t("DELETE")}
              </Button>
            </TableCell>
          </ThemeProvider>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  {t("using-branches")}
                </Typography>
                {row.branchesUsing.length == 0 ? (
                  <>
                    {t("no-branch-using-group")}
                    <Button
                      startIcon={<AddCircleIcon />}
                      sx={{ margin: "auto", display: "block" }}
                      onClick={(e: any) => addOnClick(row)}
                    >
                      {t("add-or-remove-branch")}
                    </Button>
                  </>
                ) : (
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("branch-title")}</TableCell>
                        <TableCell>{t("branch-city")}</TableCell>
                        <TableCell>{t("branch-district")}</TableCell>
                        <TableCell>{t("start-using-date")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.branchesUsing?.map((branch: any) => (
                        <TableRow key={branch.title}>
                          <TableCell component="th" scope="row">
                            {branch.branch?.title}
                          </TableCell>
                          <TableCell>{branch.branch?.city?.name}</TableCell>
                          <TableCell>{branch.branch?.district?.name}</TableCell>
                          <TableCell>
                            {new Date(branch.date)?.toLocaleString()}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <Button
                      startIcon={<AddCircleIcon />}
                      sx={{ margin: "auto" }}
                      onClick={(e: any) => addOnClick(row)}
                    >
                      {t("add-or-remove-branch")}
                    </Button>
                  </Table>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  if (context?.User?.permissions?.includes(301)) {
    return (
      <>
        <Loading done={!PFetch.loading && !BFetch.loading} />

        <Link
          to="/product-group/action"
          className="flex items-center text-white bg-ebony py-3 px-6 rounded-lg text-sm font-medium ml-auto"
          style={{ marginTop: "1.2rem", marginRight: "1.2rem" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
              clipRule="evenodd"
            />
          </svg>
          <span>{t("add-product-group")}</span>
        </Link>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>{t("product-group-name")}</TableCell>
                <TableCell align="right">{t("author")}</TableCell>
                <TableCell align="right">{t("created-at")}</TableCell>
                <TableCell align="right">{t("updated-at")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groups?.current?.map((row: any) => (
                <Row key={row.title} row={row} />
              ))}
            </TableBody>
          </Table>
          <ToastContainer />
          <EditUsingBranchDialog />
        </TableContainer>
      </>
    );
  }

  return (
    <Box
      sx={{
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <Typography variant="h2">403</Typography>
      <Typography variant="h2">Forbidden</Typography>
      <hr />
      <Typography variant="h5">{t("no-permission")}</Typography>
    </Box>
  );
}
