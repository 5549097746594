import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Select, MenuItem, Box, Button, InputLabel, TextField, Dialog, DialogTitle, DialogContent, Grid, Collapse, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Toolbar, Stack, ButtonGroup } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import Http from "services/http";
import Response from "services/response";
import { toast } from 'react-toastify';
import Loading from "components/loading";
import FileDownload from '@mui/icons-material/FileDownload';
import { parseTableReport } from 'services/helpers/excel';
import { convertCurrency, defaultCurrency } from 'services/helpers/exchange';

//user token info for api
const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
const refreshToken = localStorage.getItem(
    process.env.REACT_APP_REFRESH_TOKEN_NAME!
);

export default function TableReport() {
    const { t } = useTranslation();

    //report range - last 1 day by default
    const [to, setTo] = useState(new Date());
    const [from, setFrom] = useState(new Date(new Date().getTime() - 86400000));

    //loading info for fetching
    const [loading, setLoading] = useState(false);
    //all branches accessible by the user
    const [branches, setBranches]: any[] = useState([]);
    //branch to fetch reports
    const [selectedBranch, setSelectedBranch] = useState("");
    //report data
    const [report, setReport] = useState([]);

    //only works once for first report data
    useEffect(() => {
        //fetch branch then fetch report
        const fetchBranch = async () => {
            let branch: any = await axios.get(`manager/branches`, {
                baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "x-refresh": refreshToken!,
                },
            });
            await setBranches([branch?.data[0]].concat(branch?.data[0]?.subBranch));
            await setSelectedBranch(branch?.data[0]?._id);
            const fetchReports = async () => {
                let reportData: any = await axios.get(`manager/report/table/${branch?.data[0]?._id}`, {
                    baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "x-refresh": refreshToken!,
                    },
                    params: {
                        from: new Date(from).getTime(),
                        to: new Date(to).getTime()
                    }
                })
                setReport(reportData.data);
            }
            fetchReports();
        }

        fetchBranch();
    }, [])

    const exportReports = () => {
        if (report.length == 0) {
            toast.warning(t('no-ready-report'));
            return;
        }
        parseTableReport(report, branches?.find((b: any) => b._id == selectedBranch).title);
    }

    //handle button click - runs auto on every branch changes
    const syncData = async () => {
        if (
            !new Date(from).getTime() ||
            !new Date(to).getTime()
        ) {
            toast.warning(t('please-check-date'));
            return;
        }
        if (from > to) {
            toast.warning(t('date-warning'));
            return;
        }
        setLoading(true);
        let reportData: any = await axios.get(`manager/report/table/${selectedBranch}`, {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                Authorization: `Bearer ${token}`,
                "x-refresh": refreshToken!,
            },
            params: {
                from: new Date(from).getTime(),
                to: new Date(to).getTime()
            }
        })
        setReport(reportData.data);
        setLoading(false);
    }
    return (
        <Box sx={{ overflow: "auto" }}>
            <Stack direction="row" sx={{ marginTop: "3vw" }}>
                <Grid container justifyContent="left">
                {branches.length > 1 ?
                    <Box sx={{marginLeft: "1vw", marginRight: "1vw"}}>
                        <InputLabel id="branchLabel">{t('branch')}</InputLabel>
                        <Select sx={{ minWidth: "10vw" }} labelId="branchLabel" value={selectedBranch} onChange={async (e: any) => {
                            await setSelectedBranch(e.target.value);
                            syncData();
                        }}>
                            {branches?.map((branch: any) => (
                                <MenuItem value={branch._id}>
                                    {branch.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>:<></>}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack direction="row" spacing={1} sx={{marginTop:3}}>
                            <DateTimePicker
                                label={t('start-date')!}
                                inputFormat="DD/MM/YYYY HH:mm"
                                value={from}
                                ampm={false}
                                onChange={async (value: any) => {
                                    await setFrom(value)
                                }}
                                renderInput={(params: any) => <TextField {...params} />}
                            />
                            <DateTimePicker
                                label={t('end-date')!}
                                inputFormat="DD/MM/YYYY HH:mm"
                                value={to}
                                ampm={false}
                                onChange={async (value: any) => {
                                    await setTo(value)
                                }}
                                renderInput={(params: any) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </Grid>
                <Grid container justifyContent="right">
                    <ButtonGroup orientation='vertical' sx={{ marginRight: "1vw" }}>
                        <Button variant="contained" color="inherit" onClick={() => {
                            syncData();
                        }}>{t('get-reports')}
                        </Button>
                        <Button startIcon={<FileDownload />} variant='contained' onClick={() => exportReports()} sx={{ backgroundColor: "#717D8C" }}>{t('export-reports')}</Button>
                    </ButtonGroup>
                </Grid>
            </Stack>




            <Box>
                {loading ? (
                    <Loading />
                ) : (
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('table')}</TableCell>
                                    <TableCell>{t('section')}</TableCell>
                                    <TableCell>{t('total-order')}</TableCell>
                                    <TableCell>{t('total-sold-products')}</TableCell>
                                    <TableCell>{t('all-revenue')}</TableCell>
                                    <TableCell>{t('total-tips')}</TableCell>
                                    <TableCell>{t('total-cancellation-count')}</TableCell>
                                    <TableCell>{t('total-cancellation-amount')}</TableCell>
                                    <TableCell>{t('total-catering-count')}</TableCell>
                                    <TableCell>{t('total-catering-amount')}</TableCell>
                                    <TableCell>{t('not-payable-amount')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {report?.map((report: any) => (
                                    <TableRow>
                                        <TableCell>{t(report.tableTitle)}</TableCell>
                                        <TableCell>{report.sectionTitle}</TableCell>
                                        <TableCell>{report.totalOrder}</TableCell>
                                        <TableCell>{report.totalSoldProduct}</TableCell>
                                        <TableCell>{convertCurrency(report.totalRevenue)} {defaultCurrency()}</TableCell>
                                        <TableCell>{convertCurrency(report.totalTips)} {defaultCurrency()}</TableCell>
                                        <TableCell>{report.totalCancellationCount}</TableCell>
                                        <TableCell>{convertCurrency(report.totalCancellationAmount)} {defaultCurrency()}</TableCell>
                                        <TableCell>{report.totalCateringCount}</TableCell>
                                        <TableCell>{convertCurrency(report.totalCateringAmount)} {defaultCurrency()}</TableCell>
                                        <TableCell>{convertCurrency(report.totalNotPayable)} {defaultCurrency()}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Box>
        </Box>
    )
}
