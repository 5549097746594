import React from "react";
import ReactDOM from "react-dom";

import Providers from "providers";
import Routes from "routes";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import "./styles/index.css";

import "./utilities/i18n";

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes />
      </>
    </Providers>
  </React.StrictMode>,

  document.getElementById("root")
);
