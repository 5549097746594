import React, { Component } from "react";
import axios from "axios";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Avatar, Button, TextField } from "@mui/material";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { withTranslation, WithTranslation } from "react-i18next";
import { t } from "i18next";

interface ProductState {
  data: any[];
  ranks: number[];
  type: string;
}

interface IProps extends WithTranslation {}

const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
const refreshToken = localStorage.getItem(
  process.env.REACT_APP_REFRESH_TOKEN_NAME!
);

class Product extends Component<IProps> {
  state: ProductState = {
    data: [],
    ranks: [],
    type: "",
  };
  async componentDidMount() {
    let type = window.location.pathname.split("/")[1];
    let products = await axios.get("/manager/product", {
      baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
      headers: {
        Authorization: `Bearer ${token}`,
        "x-refresh": refreshToken!,
      },
    });
    let categories = await axios.get("/manager/category", {
      baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
      headers: {
        Authorization: `Bearer ${token}`,
        "x-refresh": refreshToken!,
      },
    });
    products.data.map((p: any) => {
      p.createdAt = new Date(p.createdAt).toLocaleString();
      p.category = categories.data.find((c: any) => c.id == p.category)?.title;
    });
    this.setState({
      type: type,
    });
    switch (this.state.type) {
      case "product":
        this.setState({
          data: products.data,
        });
        break;
      case "opportunity":
        this.setState({
          data: products.data.filter((p: any) => p.opportunity),
        });
        break;
      case "favorite":
        this.setState({
          data: products.data.filter((p: any) => p.favorite),
        });
        break;
      case "category":
        categories.data.map(
          (c: any) => (c.createdAt = new Date(c.createdAt).toLocaleString())
        );
        this.setState({
          data: categories.data,
        });
        break;
      default:
        break;
    }
  }
  render() {
    const handleRankChange = (id: string, e: any) => {
      this.setState({
        products: this.state.data.map((p: any) => {
          if (p.id == id) {
            switch (this.state.type) {
              case "product":
                p.rank = Number(e.target.value);
                break;
              case "favorite":
                p.favoriteRank = Number(e.target.value);
                break;
              case "opportunity":
                p.opportunityRank = Number(e.target.value);
                break;
              case "category":
                p.rank = Number(e.target.value);
                break;
              default:
                break;
            }
          }
          return p;
        }),
      });
    };

    const handleSave = async () => {
      let check: any[] = [];

      switch (this.state.type) {
        case "product":
          check = this.state.data.filter((p: any) => p.rank == 0 || !p.rank);
          break;
        case "favorite":
          check = this.state.data.filter(
            (p: any) => p.favoriteRank == 0 || !p.favoriteRank
          );
          break;
        case "opportunity":
          check = this.state.data.filter(
            (p: any) => p.opportunityRank == 0 || !p.opportunityRank
          );
          break;
        case "category":
          check = this.state.data.filter((p: any) => p.rank == 0 || !p.rank);
          break;
        default:
          break;
      }
      if (check?.length > 0) {
        toast.error(this.props.t("edit-rank-error"));
        return;
      }
      let response = await axios.put(
        `/manager/rank/${this.state.type}`,
        {
          data: this.state.data,
        },
        {
          baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
          headers: {
            Authorization: `Bearer ${token}`,
            "x-refresh": refreshToken!,
          },
        }
      );
      if (response.status) {
        toast.success(this.props.t("edit-rank-success"));
        return;
      }
      toast.error(this.props.t("server-error"));
    };
    const columns: GridColDef[] = [
      {
        field: "product",
        headerName:
          this.state.type == "category"
            ? this.props.t("category-name")!
            : this.props.t("product-name")!,
        flex: 2,
        headerAlign: "center",
        renderCell: (params: any) => {
          return (
            <>
              <Avatar sx={{ marginRight: 2 }} src={params.row.image} />
              {params.row.title}
            </>
          );
        },
      },
      { field: "category", headerName: this.props.t("category")!, flex: 1 },
      { field: "createdAt", headerName: this.props.t("created-at")!, flex: 2 },
      {
        field: "rank",
        headerName: this.props.t("rank")!,
        flex: 1,
        renderCell: (params: any) => {
          return (
            <TextField
              type="number"
              defaultValue={
                this.state.type == "favorite"
                  ? params.row.favoriteRank
                  : this.state.type == "opportunity"
                  ? params.row.opportunityRank
                  : params.row.rank
              }
              onChange={(e: any) => handleRankChange(params.row.id, e)}
            />
          );
        },
      },
    ];
    return (
      <div style={{ height: "75%", width: "100%" }}>
        {this.state.type == "category"
          ? this.props.t("total-category-count")
          : this.props.t("total-product-count")}
        {this.state.data.length}
        <DataGrid rows={this.state.data} columns={columns} />
        <div style={{ marginLeft: "65vw", marginTop: "2vw" }}>
          <Button
            variant="contained"
            color="success"
            sx={{ marginRight: 3 }}
            onClick={() => handleSave()}
          >
            Kaydet
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              Swal.fire({
                icon: "question",
                title: this.props.t("cancel-question")!,
                text: this.props.t("cancel-text")!,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: this.props.t("yes")!,
                cancelButtonText: this.props.t("no")!,
              }).then((response) => {
                if (response.isConfirmed) {
                  window.location.href = "/product";
                }
              });
            }}
          >
            {this.props.t("cancel")}
          </Button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Product);
