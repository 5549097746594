import React, { Component, useEffect, useState } from 'react';
import axios from 'axios'
import { Select, MenuItem, Box, Button, InputLabel, TextField, FormHelperText, Dialog, DialogTitle, DialogContent, Grid, Paper, Collapse, Typography, Stack, Avatar, Toolbar, ButtonGroup, FormControl } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { toast } from 'react-toastify';
import { withTranslation, WithTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Loading from 'components/loading';
import { PaymentTypes } from 'services/interfaces/report/table';
import FileDownload from '@mui/icons-material/FileDownload';
import { parseCaseReport } from 'services/helpers/excel';
import { convertCurrency, defaultCurrency } from 'services/helpers/exchange';

interface CaseReportInterface {
    reportData: any[],
    selectedBranch: string,
    branches: any[],
    startdate: any,
    enddate: any,
    openCheckDialog: boolean,
    openCheckReport: any,
    loading: boolean
}

interface Props extends WithTranslation { }

const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
const refreshToken = localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN_NAME!);
const startdate = new Date(Date.now() - 86400000);
const enddate = new Date();
startdate.setHours(0);
startdate.setMinutes(0);
startdate.setSeconds(0);
startdate.setMilliseconds(0);
enddate.setHours(23);
enddate.setMinutes(59);
enddate.setSeconds(59);
class CaseReport extends Component<Props> {

    state: CaseReportInterface = {
        reportData: [],
        selectedBranch: "",
        branches: [],
        startdate: startdate,
        enddate: enddate,
        openCheckDialog: false,
        openCheckReport: {},
        loading: true

    }
    async componentDidMount() {
        let res: any = await axios.get('manager/report/casebased', {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-refresh': refreshToken!
            },
            params: {
                startdate: new Date(this.state.startdate).getTime(),
                enddate: new Date(this.state.enddate).getTime(),
            }
        });
        let branch: any = await axios.get(`manager/branches`, {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-refresh': refreshToken!
            }
        });

        let openCheckReport = await axios.get(`manager/report/open-checks/${branch?.data[0]?._id}`, {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'x-refresh': refreshToken!
            }
        });

        let allReports: any[] = this.editReportData(res.data);

        this.setState({
            reportData: allReports,
            selectedBranch: branch?.data[0]?._id,
            branches: [branch.data[0]].concat(branch.data[0]?.subBranch),
            openCheckReport: openCheckReport.data,
            loading: false
        })
    }

    OpenCheckDialog = () => {
        const syncOpenCheckData = async () => {
            let openCheckReport = await axios.get(`manager/report/open-checks/${this.state.selectedBranch}`, {
                baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'x-refresh': refreshToken!
                }
            });
            this.setState({
                openCheckReport: openCheckReport.data
            });
        }
        return (
            <Dialog open={this.state.openCheckDialog} onClose={() => {
                this.setState({ openCheckDialog: false });
            }} fullWidth sx={{ backdropFilter: "blur(5px)", backgroundColor: "rgb(240,230,0,0.4)" }}>
                <DialogTitle>{this.props.t("openTicket")}</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}>
                        {this.state.branches.length>1?<Box>
                            <InputLabel id="lbl">{this.props.t("branch")}</InputLabel>
                            <Select labelId="lbl" value={this.state.selectedBranch} onChange={async (e: any) => {
                                await this.setState({ selectedBranch: e.target.value })
                                await syncOpenCheckData();
                            }}>
                                {this.state.branches?.map((branch: any) => (
                                    <MenuItem value={branch._id}>{branch.title}</MenuItem>
                                ))}
                            </Select>
                        </Box>:<></>}
                        <Box>
                            <Toolbar>
                                <Typography
                                    sx={{ flex: '1 1 100%' }}
                                    variant="h6"
                                    id="tableTitle"
                                    component="div"
                                >
                                    {this.state.openCheckReport.title}
                                </Typography>
                            </Toolbar>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{this.props.t('type')}</TableCell>
                                        <TableCell>{this.props.t('revenue-quantity')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{this.props.t('type')}</TableCell>
                                        <TableCell>{this.state.openCheckReport.openChecks}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{this.props.t('total-revenue')}</TableCell>
                                        <TableCell>{convertCurrency(this.state.openCheckReport.totalPrice)} {defaultCurrency()}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{this.props.t('catering-quantity')}</TableCell>
                                        <TableCell>{convertCurrency(this.state.openCheckReport.totalCatering)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{this.props.t('catering-amnt')}</TableCell>
                                        <TableCell>{convertCurrency(this.state.openCheckReport.cateringPrice)} {defaultCurrency()}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{this.props.t('cancellation-quantity')}</TableCell>
                                        <TableCell>{convertCurrency(this.state.openCheckReport.totalCancellation)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{this.props.t('cancellation-amount')}</TableCell>
                                        <TableCell>{convertCurrency(this.state.openCheckReport.cancellationPrice)} {defaultCurrency()}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        )
    }

    editReportData = (d: any) => {
        let allReports: any[] = [];
        d.report.map((report: any) => {
            let reports: any = {
                _id: report._id,
                title: report.title,
                data: [],
            }
            report.data.map((data: any) => {
                let index = reports.data?.findIndex((r: any) => r.label == new Date(data.label).toLocaleDateString());
                if (index > -1) {
                    data.result.map((res: any) => {
                        let rIndex = reports?.data[index]?.result.findIndex((_: any) => _.type == res.type && _.currency == res.currency);
                        if (rIndex > -1) {
                            reports.data[index].result[rIndex].amount += res.amount;
                            return;
                        }
                        reports.data[index]?.result?.push(res);
                    });
                    data.exs?.map((ex: any) => {
                        reports.data[index]?.exs.map((e: any) => {
                            if (e.name == ex.name) {
                                e.quantity += ex.quantity;
                                e.amount += ex.amount;
                            }
                        })
                    })
                    return;
                }
                reports.data.push({ label: new Date(data.label).toLocaleDateString(), result: data.result, exs: data.exs });
            });
            allReports.push(reports);
        });
        return allReports;
    }

    syncData = async () => {
        if (!new Date(this.state.startdate)?.getTime() || !new Date(this.state.enddate)?.getTime()) {
            this.setState({
                reportDate: [],
            });
            return;
        }
        if (this.state.startdate > this.state.enddate) {
            toast.warning(this.props.t("date-warning"));
            return;
        }
        this.setState({
            loading: true
        })
        if (this.state.enddate >= this.state.startdate) {
            let res: any = await axios.get('manager/report/casebased', {
                baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'x-refresh': refreshToken!
                },
                params: {
                    startdate: new Date(this.state.startdate).getTime(),
                    enddate: new Date(this.state.enddate).getTime(),
                }
            });
            let allReports: any[] = this.editReportData(res.data)
            this.setState({ reportData: allReports, loading: false })
        }

    }

    exportReports = () => {
        if (this.state.reportData?.length == 0) {
            toast.warning(this.props.t('no-ready-report'));
            return;
        }
        parseCaseReport(this.state.reportData);
    }

    Report = (data: any) => {
        const [expanded, setExpanded] = useState(false);

        const getTotal = (data: any) => {
            let total = 0;
            data.result.map((res: any) => total += res.amount);
            return total;
        }

        const getTotalRevenue = (data: any) => {
            let total = 0;
            data.result.map((res: any) => {
                if (res.type != 14 && res.type != 16) {
                    total += res.amount;
                }
            });
            return total;
        }

        const getTotalCost = (data: any) => {
            let total = 0;
            let quantity = 0;
            data.exs.map((ex: any) => {
                total += ex.amount;
                quantity += ex.quantity;
            });
            return { total, quantity };
        }

        const getTotalRCost = (data: any) => {
            let total = 0;
            let quantity = 0;
            data.exs.map((ex: any) => {
                if (ex.name == "expenses") {
                    total += ex.amount;
                    quantity += ex.quantity;
                }
            });
            return { total, quantity };
        }

        return (
            <Box sx={{ marginTop: "6vw" }}>
                <Typography component="div" sx={{ cursor: "pointer" }} onClick={() => setExpanded(!expanded)}>
                    <Box sx={{ textAlign: "center", backgroundColor: "#ededed", borderRadius: "10px 10px 0 0", color: "#000", fontWeight: "bold", fontSize: "1.23vw" }}>{data.label} {expanded ? (<KeyboardArrowUpIcon fontSize='large' />) : (<KeyboardArrowDownIcon fontSize='large' />)}</Box>
                </Typography>
                <Collapse in={expanded}>
                    <Box>
                        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Box sx={{ marginLeft: "1.5vw" }}>
                                <Toolbar>
                                    <Typography
                                        sx={{ flex: '1 1 100%' }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >
                                        {this.props.t('revenues')}
                                    </Typography>
                                </Toolbar>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{this.props.t('payment-type')}</TableCell>
                                            <TableCell>{this.props.t('amount')}</TableCell>
                                            <TableCell>{this.props.t('currency')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.result?.map((res: any) => (
                                            <TableRow>
                                                <TableCell>{this.props.t(PaymentTypes[(res.type) as keyof typeof PaymentTypes])}</TableCell>
                                                <TableCell>{convertCurrency(res.amount)}</TableCell>
                                                <TableCell>{res.currency}</TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell>{this.props.t('total')}</TableCell>
                                            <TableCell>{convertCurrency(getTotal(data))}</TableCell>
                                            <TableCell>{defaultCurrency()}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{this.props.t('total_revenue')} <FormHelperText>{this.props.t('total-revenue-info')}</FormHelperText></TableCell>
                                            <TableCell>{convertCurrency(getTotalRevenue(data))}</TableCell>
                                            <TableCell>{defaultCurrency()}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                            <Box sx={{ marginRight: "1.5vw" }}>
                                <Toolbar>
                                    <Typography
                                        sx={{ flex: '1 1 100%' }}
                                        variant="h6"
                                        id="tableTitle"
                                        component="div"
                                    >
                                        {this.props.t('costs')}
                                    </Typography>
                                </Toolbar>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{this.props.t('cost-type')}</TableCell>
                                            <TableCell>{this.props.t('cost-quantity')}</TableCell>
                                            <TableCell>{this.props.t('cost-amount')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.exs?.map((exp: any) => (
                                            <TableRow>
                                                <TableCell>{this.props.t(exp.name)} {exp.name == "discounts" ? (<FormHelperText>{this.props.t('discount-info')}</FormHelperText>) : null}</TableCell>
                                                <TableCell>{convertCurrency(exp.quantity)}</TableCell>
                                                <TableCell>{convertCurrency(exp.amount)} {defaultCurrency()}</TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow>
                                            <TableCell>{this.props.t('total-cost')}</TableCell>
                                            <TableCell>{convertCurrency(getTotalCost(data).quantity)}</TableCell>
                                            <TableCell>{convertCurrency(getTotalCost(data).total)} {defaultCurrency()}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>{this.props.t('total-edited-cost')} <FormHelperText>{this.props.t('total-edited-info')}</FormHelperText></TableCell>
                                            <TableCell>{convertCurrency(getTotalRCost(data).quantity)}</TableCell>
                                            <TableCell>{convertCurrency(getTotalRCost(data).total)} {defaultCurrency()}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Box>
                        </Box>
                        <Box>
                            <Toolbar>
                                <Typography
                                    sx={{ flex: '1 1 100%' }}
                                    variant="h6"
                                    id="tableTitle"
                                    component="div"
                                >
                                    {this.props.t('result')}
                                </Typography>
                            </Toolbar>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{this.props.t("income")} - {this.props.t("expense")}</TableCell>
                                        <TableCell>{this.props.t('amount')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{this.props.t('total_revenue')} <FormHelperText>{this.props.t('total-revenue-info')}</FormHelperText></TableCell>
                                        <TableCell>{convertCurrency(getTotalRevenue(data))} {defaultCurrency()}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{this.props.t('total-edited-cost')} <FormHelperText>{this.props.t('total-edited-info')}</FormHelperText></TableCell>
                                        <TableCell>{convertCurrency(getTotalRCost(data).total)} {defaultCurrency()}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>{this.props.t('endorsement')}</TableCell>
                                        <TableCell>{convertCurrency(getTotalRevenue(data) - getTotalRCost(data).total)} {defaultCurrency()}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>

                </Collapse>
            </Box>

        )
    }
    render() {
        if (this.state.loading) {
            return (
                <Loading />
            )
        }
        return (
            <Box sx={{ overflow: "auto" }}>
                <this.OpenCheckDialog />
                <Stack direction="row" sx={{marginTop: "3vw"}}>
                    <Grid container justifyContent="left" sx={{marginLeft: "1vw"}}>
                        <Box sx={{ marginRight: "1vw" }}>
                        {this.state.branches.length>1? <FormControl sx={{  marginTop:1, marginLeft:1, marginRight: "1vw"}}  >
                             <InputLabel id="branchLabel">{this.props.t('branch')}</InputLabel>
                             <Select sx={{ marginRight: "2vw" }} value={this.state.selectedBranch} labelId="branchLabel" onChange={(e: any) => this.setState({ selectedBranch: e.target.value })}>
                                {this.state.branches.map((branch: any) => (
                                    <MenuItem value={branch._id}>{branch.title}</MenuItem>
                                ))}
                            </Select>
                            </FormControl>:<></>}                    
                        </Box>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack direction="row" spacing={1} sx={{marginTop:3}}>
                                <DateTimePicker
                                    label={this.props.t('start-date')}
                                    inputFormat="DD/MM/YYYY HH:mm"
                                    value={this.state.startdate}
                                    ampm={false}
                                    onChange={async (value: any) => {
                                        await this.setState({
                                            startdate: value,
                                        });
                                    }}
                                    renderInput={(params: any) => <TextField {...params} />}
                                />
                                <DateTimePicker
                                    label={this.props.t('end-date')}
                                    inputFormat="DD/MM/YYYY HH:mm"
                                    value={this.state.enddate}
                                    ampm={false}
                                    onChange={async (value: any) => {
                                        await this.setState({
                                            startdate: value,
                                        });
                                    }}
                                    renderInput={(params: any) => <TextField {...params} />}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </Grid>
                    <Grid container justifyContent="right">
                        <ButtonGroup orientation='vertical' sx={{ marginRight: "1vw" }}>
                            <Button variant="contained" color="inherit" onClick={() => {
                                this.syncData();
                            }}>{this.props.t('get-reports')}
                            </Button>
                            <Button variant="contained" color="inherit" onClick={() => this.setState({ openCheckDialog: true })}>{this.props.t("open-checks")}</Button>
                            <Button variant='contained' onClick={() => this.exportReports()} sx={{ backgroundColor: "#717D8C" }} startIcon={<FileDownload />}>{this.props.t('export-reports')}</Button>
                        </ButtonGroup>
                    </Grid>
                </Stack>




                <Box sx={{ marginTop: "4vw" }}>
                    {this.state.reportData.find((report: any) => report._id == this.state.selectedBranch)?.data?.map((report: any) => (
                        <this.Report {...report} />
                    ))}
                </Box>
            </Box>
        )
    }
}

export default withTranslation()(CaseReport);
