import { Currencies } from "services/interfaces/currencies";

export const convertCurrency = (
  value: number,
  currency: string = localStorage.getItem("reportCurrency")!
) => {
  if (!currency || !localStorage.getItem("currencySettings")) return new Intl.NumberFormat('de-DE').format(parseFloat(value.toFixed(2).replace(/\.?0+$/, '')));
  let currencySettings = JSON.parse(localStorage.getItem("currencySettings")!);

  if (currency == currencySettings.defaultCurrency) return new Intl.NumberFormat('de-DE').format(parseFloat(value.toFixed(2).replace(/\.?0+$/, '')));

  let currencyValue = 1;

  switch (currency) {
    case "TL":
      currencyValue = currencySettings?.currencies?.TL;
      break;
    case "USD":
      currencyValue = currencySettings?.currencies?.USD;
      break;
    case "EUR":
      currencyValue = currencySettings?.currencies?.EUR;
      break;
    case "GBP":
      currencyValue = currencySettings?.currencies?.GBP;
      break;
    default:
      break;
  }

  return (value / currencyValue).toFixed(2);
};

export const defaultCurrency = () => { 
    if(localStorage.getItem("reportCurrency")){
        return Currencies[localStorage.getItem("reportCurrency")!];
    }
    if(localStorage.getItem("currencySettings")){
        let settings = JSON.parse(localStorage.getItem("currencySettings")!);
        return Currencies[settings.defaultCurrency] || "₺";
    }
    return "₺";
}
