
import IntegrationList from "components/integrationList"
import { useTranslation } from "react-i18next"
export default function () {
    const { t } = useTranslation();

    return (
			<>
				<div className="header-top">
					<h2 className="text-xl font-medium pl-2">{t('match-product')}</h2>
				</div>
				<div id="content" className="p-6 flex flex-col gap-y-4 ">

					<h3>{t('can-match-products')}</h3>
					<IntegrationList 
						linkBase="/pair-products/action" 
						emptyMessage={t('pair-product-message')}
					/>
						
				</div>
			</>
    )
}