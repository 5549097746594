import React, { useContext, useState, useEffect } from "react";

import Http from "services/http";
import Response from "services/response";

import Report from "services/report";
import Table from "services/table";
import Worker from "services/member";

import Modal from "components/modal";
import Loading from "components/loading";
import NetworkError from "components/network-error";
import Spinner from "components/spinner";
import TableComponent from "components/table";

import Structures from "services/structures";
import IResponseProductReport from "services/interfaces/report/product";
import IResponseCaseReport from "services/interfaces/report/case";
import IResponseTableReport from "services/interfaces/report/table";
import IResponseWorkerReport from "services/interfaces/report/worker";
import IResponseOrdertypeReport from "services/interfaces/report/ordertype";

import { Doughnut, Line } from "react-chartjs-2";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { payment_types } from "services/interfaces/case/payment_types";

import { CSVLink } from "react-csv";
import {
  Chart,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
} from "chart.js";
Chart.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip
);

import {
  parseCaseBased,
  parseOrderTypeBased,
  parseProductBased,
  parseTableBased,
  parseWorkerBased,
  productCSV,
  caseCSV,
  tableCSV,
  workerCSV,
  orderTypeCSV,
} from "./parseFunctions";
import useCache from "services/useCache";

export default function () {
  const [exportModal, setExportModal] = useState(false);
  const [reportType, setReportType] = useState("casebased");
  const [headerOfCSV, setHeaderOfCSV] = useState<any>();
  const [parsedForCSV, setParsedForCSV] = useState<any>({
    productbased: null,
    casebased: null,
    tablebased: null,
    workerbased: null,
    ordertypebased: null,
  });
  const [dates, setDates] = useState({
    start: "1641041306000",
    end: (Date.now() - 60000).toString(),
  });
  const [displayType, setDisplayType] = useState<string>("table");
  const Services = {
    Report: new Report(useContext(Http.Context)!),
    Table: new Table(useContext(Http.Context)!),
    Worker: new Worker(useContext(Http.Context)!),
  };

  let Fetchs = {
    Reports: {
      Product: new Response<IResponseProductReport>(),
      Case: new Response<IResponseCaseReport>(),
      Table: new Response<IResponseTableReport>(),
      Worker: new Response<IResponseWorkerReport>(),
      Ordertype: new Response<IResponseOrdertypeReport>(),
    },
    Tables: useCache(Services.Table),
    Workers: useCache(Services.Worker),
  };

  switch (reportType) {
    case "productbased":
      useEffect(() => {
        if (!Fetchs.Reports.Product.data)
          Fetchs.Reports.Product.handle(
            Services.Report.getReport(reportType, dates.start, dates.end)
          );
        else {
          let { header: h, data: d } = productCSV(
            Fetchs.Reports.Product.data.report
          );
          setHeaderOfCSV(h);
          setParsedForCSV({ ...parsedForCSV, productbased: d });
        }
      }, [Fetchs.Reports.Product.data]);
      break;
    case "casebased":
      useEffect(() => {
        if (!Fetchs.Reports.Case.data)
          Fetchs.Reports.Case.handle(
            Services.Report.getReport(reportType, dates.start, dates.end)
          );
        else {
          let { header: h, data: d } = caseCSV(Fetchs.Reports.Case.data.report);
          setHeaderOfCSV(h);
          setParsedForCSV({ ...parsedForCSV, casebased: d });
        }
      }, [Fetchs.Reports.Case.data]);
      break;
    case "tablebased":
      useEffect(() => {
        if (!Fetchs.Reports.Table.data)
          Fetchs.Reports.Table.handle(
            Services.Report.getReport(reportType, dates.start, dates.end)
          );
        else {
          let { header: h, data: d } = tableCSV(
            Fetchs.Reports.Table.data.report,
            Fetchs.Tables.data
          );
          setHeaderOfCSV(h);
          setParsedForCSV({ ...parsedForCSV, tablebased: d });
        }
      }, [Fetchs.Reports.Table.data]);
      break;
    case "workerbased":
      useEffect(() => {
        if (!Fetchs.Reports.Worker.data)
          Fetchs.Reports.Worker.handle(
            Services.Report.getReport(reportType, dates.start, dates.end)
          );
        else {
          let { header: h, data: d } = workerCSV(
            Fetchs.Reports.Worker.data.report,
            Fetchs.Workers.data
          );
          setHeaderOfCSV(h);
          setParsedForCSV({ ...parsedForCSV, workerbased: d });
        }
      }, [Fetchs.Reports.Worker.data]);
      break;
    case "ordertypebased":
      useEffect(() => {
        if (!Fetchs.Reports.Ordertype.data)
          Fetchs.Reports.Ordertype.handle(
            Services.Report.getReport(reportType, dates.start, dates.end)
          );
        else {
          let { header: h, data: d } = orderTypeCSV(
            Fetchs.Reports.Ordertype.data.report
          );
          setHeaderOfCSV(h);
          setParsedForCSV({ ...parsedForCSV, ordertypebased: d });
        }
      }, [Fetchs.Reports.Ordertype.data]);
      break;
    default:
      break;
  }

  function randomColors(total: number) {
    let colors = new Set<string>();

    while (colors.size < total) {
      let r = Math.floor(Math.random() * 16);
      let g = Math.floor(Math.random() * 16);
      let b = Math.floor(Math.random() * 16);
      let rgb = "#" + r.toString(16) + g.toString(16) + b.toString(16);
      colors.add(rgb);
    }
    return Array.from(colors);
  }

  async function setDateRange(
    event: any,
    n: number,
    doNotSet: undefined | { start: string; end: string }
  ) {
    let oldDates = doNotSet || dates;

    if (doNotSet === undefined) {
      // if selected day is today, end date is 1 minute ago
      let end1 = (new Date().getTime() - 60000).toString();
      // if not today, end date is 23:59:00 of selected day
      let end2 = new Date(event.target.value)
        .setHours(23, 59, 59, 0)
        .toString();

      n == 0
        ? (oldDates.start = new Date(event.target.value)
            .setHours(0, 1, 0, 0)
            .toString())
        : (oldDates.end =
            new Date(event.target.value).toLocaleDateString() ==
            new Date().toLocaleDateString()
              ? end1
              : end2);
    }
    setDates(oldDates);
    await handleFetch();
  }

  useEffect(() => {
    handleFetch();
  }, [reportType]);

  const handleFetch = async () => {
    switch (reportType) {
      case "productbased":
        await Fetchs.Reports.Product.handle(
          Services.Report.getReport(reportType, dates.start, dates.end)
        );
        break;
      case "casebased":
        await Fetchs.Reports.Case.handle(
          Services.Report.getReport(reportType, dates.start, dates.end)
        );
        break;
      case "tablebased":
        Fetchs.Reports.Table.handle(
          Services.Report.getReport(reportType, dates.start, dates.end)
        );
        break;
      case "workerbased":
        await Fetchs.Reports.Worker.handle(
          Services.Report.getReport(reportType, dates.start, dates.end)
        );
        break;
      case "ordertypebased":
        await Fetchs.Reports.Ordertype.handle(
          Services.Report.getReport(reportType, dates.start, dates.end)
        );
        break;
      default:
        break;
    }
  };

  function timeStampToDate(stamp: any) {
    let date = new Date(Number(stamp));
    return (
      date.getFullYear() +
      (date.getMonth() < 11 ? "-0" : "-") +
      (date.getMonth() + 1) +
      (date.getDate() < 10 ? "-0" : "-") +
      date.getDate()
    );
  }

  const handleWeeklyProductReport = () => {
    setReportType("productbased");
    setDisplayType("pie");
    let day = new Date().getDay() || 7;
    let oneDay = 24 * 60 * 60 * 1000;
    let compS = new Date().setHours(0, 1, 0, 0) - oneDay * (day - 1);
    setCompStart(compS);
    let startDay = new Date().setHours(0, 1, 0, 0) - oneDay * (day + 6);
    let targetDates = {
      start: startDay.toString(),
      end: (Date.now() - 60000).toString(),
    };
    setDateRange(null, 0, targetDates);
    setWeeklyReport(true);
  };

  const [weeklyReport, setWeeklyReport] = useState(false);
  const [compStart, setCompStart] = useState<number>();

  return (
    <React.Fragment>
      <Modal state={[exportModal, setExportModal]}>
        <React.Fragment>
          <div className="bg-white p-4 sm:p-6">
            <h5 className="mb-4 font-semibold">
              Dışa Aktarılacak Şubeyi Seçiniz
            </h5>
            <div className=" divide-y divide-gray-100">
              {Fetchs.Reports.Case.data &&
                Fetchs.Reports.Case.data.report.map(
                  (branch: any, index: number) =>
                    parsedForCSV[reportType] && (
                      <div
                        key={index + branch.title}
                        className="w-full block py-2"
                        onClick={() => setExportModal(false)}
                      >
                        <CSVLink
                          className="to-gray-btn active"
                          filename={`${
                            branch.title
                          }_report_${reportType}_from_${timeStampToDate(
                            +dates.start
                          )
                            .split("-")
                            .reverse()
                            .join(".")}_to_${timeStampToDate(+dates.end)
                            .split("-")
                            .reverse()
                            .join(".")}.csv`}
                          headers={headerOfCSV}
                          data={parsedForCSV[reportType][index] || []} // TODO: 2 must be changed with branch code
                        >
                          {branch.title}
                        </CSVLink>
                      </div>
                    )
                )}
            </div>
          </div>
        </React.Fragment>
      </Modal>
      <div className="header-top !items-end gap-x-2">
        <div>
          <span>Başlangıç Tarihi</span>
          <br />
          <input
            max={timeStampToDate(dates.end)}
            value={timeStampToDate(dates.start)}
            type="date"
            onChange={(event) => {
              setWeeklyReport(false);
              setDateRange(event, 0, undefined);
            }}
            id="startDate"
            name="date-interval"
          />
        </div>
        <div>
          <span>Bitiş Tarihi</span>
          <br />
          <input
            min={timeStampToDate(dates.start)}
            max={timeStampToDate(Date.now().toString())}
            value={timeStampToDate(dates.end)}
            type="date"
            onChange={(event) => {
              setWeeklyReport(false);
              setDateRange(event, 1, undefined);
            }}
            id="endDate"
            name="date-interval"
          />
        </div>
        <div>
          <span>Rapor tipi</span>
          <br />
          <select
            onChange={(event) => {
              setWeeklyReport(false);
              setReportType(event.target.value);
            }}
            value={reportType}
            id="report_type"
          >
            <option value={"casebased"}>Kasalar</option>
            <option value={"workerbased"}>Çalışanlar</option>
            <option value={"tablebased"}>Masalar</option>
            <option value={"ordertypebased"}>Siparişler</option>
            <option value={"productbased"}>Ürünler</option>
          </select>
        </div>
        <div>
          <span>Gösterim tipi</span>
          <br />
          <select
            onChange={(event) => {
              setWeeklyReport(false);
              setDisplayType(event.target.value);
            }}
            value={displayType}
            id="report_type"
          >
            <option value={"table"}>Tablo</option>
            <option value={"pie"}>Pasta Dilimi</option>
            <option value={"line"}>Çizgi Grafiği</option>
          </select>
        </div>
        {parsedForCSV[reportType] && (
          <button
            className="to-gray-btn"
            onClick={() => {
              setExportModal(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 xl:mr-2"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span className="hidden xl:block">Dışarı aktar</span>
          </button>
        )}
        {/* <div style={{ display: "inline-block" }}>
            <button className="to-gray-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 xl:mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="hidden xl:block">Dışarı aktar</span>
            </button>
          </div> */}
        <div
          onClick={handleWeeklyProductReport}
          className="space-x-2 ml-auto cursor-pointer flex items-center text-white bg-ebony py-3 px-6 rounded-lg text-sm font-medium mr-4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
            />
          </svg>
          <span>Son 2 Haftada Satılanlar</span>
        </div>
      </div>
      <div id="content" className="p-6 space-y-4 overflow-x-hidden">
        <Loading done={!Fetchs.Reports.Case.loading} />
        {!Fetchs.Reports.Case.loading ? (
          <NetworkError
            error={Fetchs.Reports.Case.error}
            retry={() => {
              Fetchs.Reports.Case.handle(
                Services.Report.getReport(reportType, dates.start, dates.end)
              );
            }}
          />
        ) : (
          <Spinner className="h-20 m-20" />
        )}
        <Loading done={!Fetchs.Reports.Worker.loading} />
        {!Fetchs.Reports.Worker.loading ? (
          <NetworkError
            error={Fetchs.Reports.Worker.error}
            retry={() => {
              Fetchs.Reports.Worker.handle(
                Services.Report.getReport(reportType, dates.start, dates.end)
              );
            }}
          />
        ) : (
          <Spinner className="h-20 m-20" />
        )}
        <Loading done={!Fetchs.Reports.Table.loading} />
        {!Fetchs.Reports.Table.loading ? (
          <NetworkError
            error={Fetchs.Reports.Table.error}
            retry={() => {
              Fetchs.Reports.Table.handle(
                Services.Report.getReport(reportType, dates.start, dates.end)
              );
            }}
          />
        ) : (
          <Spinner className="h-20 m-20" />
        )}
        <Loading done={!Fetchs.Reports.Ordertype.loading} />
        {!Fetchs.Reports.Ordertype.loading ? (
          <NetworkError
            error={Fetchs.Reports.Ordertype.error}
            retry={() => {
              Fetchs.Reports.Ordertype.handle(
                Services.Report.getReport(reportType, dates.start, dates.end)
              );
            }}
          />
        ) : (
          <Spinner className="h-20 m-20" />
        )}
        <Loading done={!Fetchs.Reports.Product.loading} />
        {!Fetchs.Reports.Product.loading ? (
          <NetworkError
            error={Fetchs.Reports.Product.error}
            retry={() => {
              Fetchs.Reports.Product.handle(
                Services.Report.getReport(reportType, dates.start, dates.end)
              );
            }}
          />
        ) : (
          <Spinner className="h-20 m-20" />
        )}

        {weeklyReport ? (
          <>
            {Fetchs.Reports.Product.data &&
              !Fetchs.Reports.Product.error &&
              !Fetchs.Reports.Product.loading &&
              reportType == "productbased" &&
              parsedForCSV["productbased"] &&
              Fetchs.Reports.Product.data.report.map(
                (branch: any, index: number) => {
                  return (
                    <div key={branch.title} className="w-full px-4 pt-1">
                      <div className="w-full p-2 mx-auto bg-white rounded-2xl">
                        <Disclosure>
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left bg-slate-800 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus-visible:ring focus-visible:ring-slate-800 focus-visible:ring-opacity-75">
                                <span>{branch.title}</span>
                                <ChevronUpIcon
                                  className={`${
                                    open ? "transform rotate-180" : ""
                                  } w-5 h-5 text-white`}
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="grid px-24 pt-4 pb-2 text-sm text-gray-500">
                                {parsedForCSV["productbased"][index]
                                  ?.reduce((acc: any, curr: any) => {
                                    if (
                                      new Date(
                                        curr.date.split(".").reverse().join("-")
                                      ).getTime() < compStart!
                                    ) {
                                      if (acc[0]) {
                                        let exist = acc[0].data.find(
                                          (f: any) => f.name == curr.name
                                        );
                                        if (exist) {
                                          exist.price += +curr.price;
                                        } else {
                                          acc[0].data.push({
                                            name: curr.name,
                                            price: +curr.price,
                                          });
                                        }
                                      } else {
                                        acc[0] = {
                                          label:
                                            new Date(
                                              +dates.start
                                            ).toLocaleDateString() +
                                            " ile " +
                                            new Date(
                                              compStart!
                                            ).toLocaleDateString() +
                                            " tarihleri arası",
                                          data: [
                                            {
                                              name: curr.name,
                                              price: +curr.price,
                                            },
                                          ],
                                        };
                                      }
                                    } else {
                                      if (acc[1]) {
                                        let exist = acc[1].data.find(
                                          (f: any) => f.name == curr.name
                                        );
                                        if (exist) {
                                          exist.price += curr.price;
                                        } else {
                                          acc[1].data.push({
                                            name: curr.name,
                                            price: +curr.price,
                                          });
                                        }
                                      } else {
                                        acc[1] = {
                                          label:
                                            new Date(
                                              compStart!
                                            ).toLocaleDateString() +
                                            " ile " +
                                            new Date(
                                              +dates.end
                                            ).toLocaleDateString() +
                                            " tarihleri arası",
                                          data: [
                                            {
                                              name: curr.name,
                                              price: +curr.price,
                                            },
                                          ],
                                        };
                                      }
                                    }
                                    return acc;
                                  }, [])
                                  .map((period: any) => {
                                    return (
                                      <div key={period.label}>
                                        <h3 className="text-center text-2xl">
                                          {period.label}
                                        </h3>
                                        <Doughnut
                                          options={{
                                            plugins: {
                                              legend: {
                                                display: true,
                                                position: "left",
                                              },
                                            },
                                          }}
                                          data={{
                                            labels: period.data
                                              .filter((p: any) => p.price > 0)
                                              .map(
                                                (product: any) => product.name
                                              ),
                                            datasets: [
                                              {
                                                data: period.data
                                                  .filter(
                                                    (p: any) => p.price > 0
                                                  )
                                                  .map(
                                                    (product: any) =>
                                                      product.price
                                                  ),
                                                backgroundColor: randomColors(
                                                  period.data.filter(
                                                    (p: any) => p.price > 0
                                                  ).length
                                                ),
                                              },
                                            ],
                                          }}
                                        />
                                      </div>
                                    );
                                  })}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      </div>
                    </div>
                  );
                }
              )}
          </>
        ) : displayType == "pie" ? (
          <>
            {Fetchs.Reports.Case.data &&
              !Fetchs.Reports.Case.error &&
              !Fetchs.Reports.Case.loading &&
              reportType == "casebased" &&
              Fetchs.Reports.Case.data.report.map((branch: any) => {
                return (
                  <div key={branch.title} className="w-full px-4 pt-1">
                    <div className="w-full p-2 mx-auto bg-white rounded-2xl">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left bg-slate-800 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus-visible:ring focus-visible:ring-slate-800 focus-visible:ring-opacity-75">
                              <span>{branch.title}</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? "transform rotate-180" : ""
                                } w-5 h-5 text-white`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pb-2 text-sm text-gray-500">
                              {branch.data?.map((period: any) => {
                                return (
                                  <div key={period.label}>
                                    <h3 className="text-center text-2xl">
                                      {period.label}
                                    </h3>
                                    <Doughnut
                                      options={{
                                        plugins: {
                                          legend: {
                                            display: true,
                                            position: "left",
                                          },
                                        },
                                      }}
                                      data={{
                                        labels: period.result
                                          .filter((p: any) => p.amount > 0)
                                          .map(
                                            (product: any) =>
                                              payment_types[product.type - 1]
                                                .title
                                          ),
                                        datasets: [
                                          {
                                            data: period.result
                                              .filter((p: any) => p.amount > 0)
                                              .map(
                                                (product: any) => product.amount
                                              ),
                                            backgroundColor: randomColors(
                                              period.result.filter(
                                                (p: any) => p.amount > 0
                                              ).length
                                            ),
                                          },
                                        ],
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  </div>
                );
              })}

            {Fetchs.Reports.Worker.data &&
              !Fetchs.Reports.Worker.error &&
              !Fetchs.Reports.Worker.loading &&
              reportType == "workerbased" &&
              Fetchs.Reports.Worker.data.report.map((branch: any) => {
                return (
                  <div key={branch.title} className="w-full px-4 pt-1">
                    <div className="w-full p-2 mx-auto bg-white rounded-2xl">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left bg-slate-800 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus-visible:ring focus-visible:ring-slate-800 focus-visible:ring-opacity-75">
                              <span>{branch.title}</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? "transform rotate-180" : ""
                                } w-5 h-5 text-white`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pb-2 text-sm text-gray-500">
                              {branch.data?.map((period: any) => {
                                return (
                                  <div key={period.label}>
                                    <h3 className="text-center text-2xl">
                                      {period.label}
                                    </h3>
                                    <Doughnut
                                      options={{
                                        plugins: {
                                          legend: {
                                            display: true,
                                            position: "left",
                                          },
                                        },
                                      }}
                                      data={{
                                        labels: period.result.map(
                                          (product: any) =>
                                            Fetchs.Workers.data?.find(
                                              (w: any) => w.id == product.user
                                            )?.name
                                        ),
                                        datasets: [
                                          {
                                            data: period.result.map(
                                              (product: any) =>
                                                product.payments[0]?.amount || 0
                                            ),
                                            backgroundColor: randomColors(
                                              period.result.length
                                            ),
                                          },
                                        ],
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  </div>
                );
              })}

            {Fetchs.Reports.Table.data &&
              !Fetchs.Reports.Table.error &&
              !Fetchs.Reports.Table.loading &&
              Fetchs.Tables.data &&
              !Fetchs.Tables.error &&
              Fetchs.Tables.data &&
              reportType == "tablebased" &&
              Fetchs.Reports.Table.data.report.map((branch: any) => {
                return (
                  <div key={branch.title} className="w-full px-4 pt-1">
                    <div className="w-full p-2 mx-auto bg-white rounded-2xl">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left bg-slate-800 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus-visible:ring focus-visible:ring-slate-800 focus-visible:ring-opacity-75">
                              <span>{branch.title}</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? "transform rotate-180" : ""
                                } w-5 h-5 text-white`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pb-2 text-sm text-gray-500">
                              {branch.data?.map((period: any) => {
                                return (
                                  <div key={period.label}>
                                    <h3 className="text-center text-2xl">
                                      {period.label}
                                    </h3>
                                    <Doughnut
                                      options={{
                                        plugins: {
                                          legend: {
                                            display: true,
                                            position: "left",
                                          },
                                        },
                                      }}
                                      data={{
                                        labels: period.result.map(
                                          (product: any) =>
                                            Fetchs.Tables.data?.find(
                                              (t: any) => t.id == product.table
                                            )?.title
                                        ),
                                        datasets: [
                                          {
                                            data: period.result.map(
                                              (product: any) => product.quantity
                                            ),
                                            backgroundColor: randomColors(
                                              period.result.length
                                            ),
                                          },
                                        ],
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  </div>
                );
              })}

            {Fetchs.Reports.Ordertype.data &&
              !Fetchs.Reports.Ordertype.error &&
              !Fetchs.Reports.Ordertype.loading &&
              reportType == "ordertypebased" &&
              Fetchs.Reports.Ordertype.data.report.map((branch: any) => {
                return (
                  <div key={branch.title} className="w-full px-4 pt-1">
                    <div className="w-full p-2 mx-auto bg-white rounded-2xl">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left bg-slate-800 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus-visible:ring focus-visible:ring-slate-800 focus-visible:ring-opacity-75">
                              <span>{branch.title}</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? "transform rotate-180" : ""
                                } w-5 h-5 text-white`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pb-2 text-sm text-gray-500">
                              {branch.data?.map((period: any) => {
                                return (
                                  <div key={period.label}>
                                    <h3 className="text-center text-2xl">
                                      {period.label}
                                    </h3>
                                    <Doughnut
                                      options={{
                                        plugins: {
                                          legend: {
                                            display: true,
                                            position: "left",
                                          },
                                        },
                                      }}
                                      data={{
                                        labels: period.result.map(
                                          (product: any) =>
                                            Structures.types.order[
                                              product.order_type as keyof typeof Structures.types.order
                                            ]
                                        ),
                                        datasets: [
                                          {
                                            data: period.result.map(
                                              (product: any) =>
                                                product.payments[0]?.amount || 0
                                            ),
                                            backgroundColor: randomColors(
                                              period.result.length
                                            ),
                                          },
                                        ],
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  </div>
                );
              })}

            {Fetchs.Reports.Product.data &&
              !Fetchs.Reports.Product.error &&
              !Fetchs.Reports.Product.loading &&
              reportType == "productbased" &&
              Fetchs.Reports.Product.data.report.map((branch: any) => {
                return (
                  <div key={branch.title} className="w-full px-4 pt-1">
                    <div className="w-full p-2 mx-auto bg-white rounded-2xl">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left bg-slate-800 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus-visible:ring focus-visible:ring-slate-800 focus-visible:ring-opacity-75">
                              <span>{branch.title}</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? "transform rotate-180" : ""
                                } w-5 h-5 text-white`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pb-2 text-sm text-gray-500">
                              {branch.data?.map((period: any) => {
                                let prods = period.result
                                  .filter((p: any) => p.isPaid)
                                  .reduce((acc: any, curr: any) => {
                                    let exists = acc.find(
                                      (p: any) => p.name == curr.name
                                    );
                                    if (exists) exists.price += +curr.price;
                                    else
                                      acc.push({
                                        name: curr.name,
                                        price: +curr.price,
                                      });
                                    return acc;
                                  }, []);
                                if (prods.length > 0) {
                                  return (
                                    <div key={period.label}>
                                      <h3 className="text-center text-2xl">
                                        {period.label}
                                      </h3>
                                      <Doughnut
                                        options={{
                                          plugins: {
                                            legend: {
                                              display: true,
                                              position: "left",
                                            },
                                          },
                                        }}
                                        data={{
                                          labels: prods.map(
                                            (product: any) => product.name
                                          ),
                                          datasets: [
                                            {
                                              data: prods.map(
                                                (product: any) => product.price
                                              ),
                                              backgroundColor: randomColors(
                                                prods.length
                                              ),
                                            },
                                          ],
                                        }}
                                      />
                                    </div>
                                  );
                                }
                              })}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  </div>
                );
              })}
          </> // END OF GRAPH REPORTS
        ) : displayType == "table" ? (
          <>
            {Fetchs.Reports.Case.data &&
              !Fetchs.Reports.Case.error &&
              !Fetchs.Reports.Case.loading &&
              reportType == "casebased" &&
              Fetchs.Reports.Case.data.report.map((branch, index) => (
                <div key={branch.title} className="w-full px-4 pt-1">
                  <div className="w-full p-2 mx-auto bg-white rounded-2xl">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left bg-slate-800 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus-visible:ring focus-visible:ring-slate-800 focus-visible:ring-opacity-75">
                            <span>{branch.title}</span>
                            <ChevronUpIcon
                              className={`${
                                open ? "transform rotate-180" : ""
                              } w-5 h-5 text-white`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                            <div className="flex-col border border-gray-200 rounded-lg col-span-4 xl:col-span-3 overflow-x-auto overflow-hidden flex-grow align-middle inline-block min-w-full">
                              <TableComponent
                                data={{
                                  head: [
                                    {
                                      element: "Tarih",
                                      props: { scope: "col" },
                                    },
                                    {
                                      element: "Gelir Tipi",
                                      props: { scope: "col" },
                                    },
                                    {
                                      element: "Kazanç",
                                      props: { scope: "col" },
                                    },
                                  ],
                                  body: parsedForCSV[reportType][index]?.map(
                                    (res: any) => {
                                      return {
                                        id: res.date + res.type,
                                        elements: [
                                          {
                                            element: res.date,
                                            props: {
                                              className:
                                                "text-left text-sm text-gray-900",
                                            },
                                          },
                                          {
                                            element: res.type,
                                            props: {
                                              className:
                                                "text-left text-sm text-gray-900",
                                            },
                                          },
                                          {
                                            element: res.payment,
                                            props: {
                                              className:
                                                "text-left text-sm text-gray-900",
                                            },
                                          },
                                        ],
                                      };
                                    }
                                  ),
                                }}
                              />
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              ))}

            {Fetchs.Reports.Product.data &&
              !Fetchs.Reports.Product.error &&
              !Fetchs.Reports.Product.loading &&
              reportType == "productbased" &&
              Fetchs.Reports.Product.data.report.map((branch, index) => (
                <div key={branch.title} className="w-full px-4 pt-1">
                  <div className="w-full p-2 mx-auto bg-white rounded-2xl">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left bg-slate-800 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus-visible:ring focus-visible:ring-slate-800 focus-visible:ring-opacity-75">
                            <span>{branch.title}</span>
                            <ChevronUpIcon
                              className={`${
                                open ? "transform rotate-180" : ""
                              } w-5 h-5 text-white`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                            <div className="flex-col border border-gray-200 rounded-lg col-span-4 xl:col-span-3 overflow-x-auto overflow-hidden flex-grow align-middle inline-block min-w-full">
                              <TableComponent
                                data={{
                                  head: [
                                    {
                                      element: "Tarih",
                                      props: { scope: "col" },
                                    },
                                    {
                                      element: "Ürün Adı",
                                      props: { scope: "col" },
                                    },
                                    {
                                      element: "Kategori",
                                      props: { scope: "col" },
                                    },
                                    {
                                      element: "Adet",
                                      props: { scope: "col" },
                                    },
                                    {
                                      element: "Fiyat",
                                      props: { scope: "col" },
                                    },
                                    {
                                      element: "Ödeme",
                                      props: { scope: "col" },
                                    },
                                  ],
                                  body: parsedForCSV[reportType][index]?.map(
                                    (product: any) => {
                                      return {
                                        id: product.date + product.name,
                                        elements: [
                                          {
                                            element: product.date,
                                            props: {
                                              className:
                                                "text-left text-sm text-gray-900",
                                            },
                                          },
                                          {
                                            element: product.name,
                                            props: {
                                              className:
                                                "text-left text-sm text-gray-900",
                                            },
                                          },
                                          {
                                            element: product.categoryTitle,
                                            props: {
                                              className:
                                                "text-left text-sm text-gray-900",
                                            },
                                          },
                                          {
                                            element: product.quantity,
                                            props: {
                                              className:
                                                "text-left text-sm text-gray-900",
                                            },
                                          },
                                          {
                                            element: product.price,
                                            props: {
                                              className:
                                                "text-left text-sm text-gray-900",
                                            },
                                          },
                                          {
                                            element: product.isPaidTitle,
                                            props: {
                                              className:
                                                "text-left text-sm text-gray-900",
                                            },
                                          },
                                        ],
                                      };
                                    }
                                  ),
                                }}
                              />
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              ))}

            {Fetchs.Reports.Worker.data &&
              !Fetchs.Reports.Worker.error &&
              !Fetchs.Reports.Worker.loading &&
              Fetchs.Workers.data &&
              !Fetchs.Workers.error &&
              reportType == "workerbased" &&
              Fetchs.Reports.Worker.data.report.map((branch, index) => (
                <div key={branch.title} className="w-full px-4 pt-1">
                  <div className="w-full p-2 mx-auto bg-white rounded-2xl">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left bg-slate-800 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus-visible:ring focus-visible:ring-slate-800 focus-visible:ring-opacity-75">
                            <span>{branch.title}</span>
                            <ChevronUpIcon
                              className={`${
                                open ? "transform rotate-180" : ""
                              } w-5 h-5 text-white`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                            <div className="flex-col border border-gray-200 rounded-lg col-span-4 xl:col-span-3 overflow-x-auto overflow-hidden flex-grow align-middle inline-block min-w-full">
                              <TableComponent
                                data={{
                                  head: [
                                    {
                                      element: "Tarih",
                                      props: { scope: "col" },
                                    },
                                    {
                                      element: "Çalışan Adı",
                                      props: { scope: "col" },
                                    },
                                    {
                                      element: "Sipariş Adedi",
                                      props: { scope: "col" },
                                    },
                                    {
                                      element: "Kazanç",
                                      props: { scope: "col" },
                                    },
                                  ],
                                  body: parsedForCSV[reportType][index]?.map(
                                    (res: any) => {
                                      return {
                                        id: res.date + res.user,
                                        elements: [
                                          {
                                            element: res.date,
                                            props: {
                                              className:
                                                "text-left text-sm text-gray-900",
                                            },
                                          },
                                          {
                                            element: res.user,
                                            props: {
                                              className:
                                                "text-left text-sm text-gray-900",
                                            },
                                          },
                                          {
                                            element: res.quantity,
                                            props: {
                                              className:
                                                "text-left text-sm text-gray-900",
                                            },
                                          },
                                          {
                                            element: res.payment,
                                            props: {
                                              className:
                                                "text-left text-sm text-gray-900",
                                            },
                                          },
                                        ],
                                      };
                                    }
                                  ),
                                }}
                              />
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              ))}

            {Fetchs.Reports.Table.data &&
              !Fetchs.Reports.Table.error &&
              !Fetchs.Reports.Table.loading &&
              Fetchs.Tables.data &&
              !Fetchs.Tables.error &&
              Fetchs.Tables.data &&
              reportType == "tablebased" &&
              Fetchs.Reports.Table.data.report.map((branch, index) => (
                <div key={branch.title} className="w-full px-4 pt-1">
                  <div className="w-full p-2 mx-auto bg-white rounded-2xl">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left bg-slate-800 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus-visible:ring focus-visible:ring-slate-800 focus-visible:ring-opacity-75">
                            <span>{branch.title}</span>
                            <ChevronUpIcon
                              className={`${
                                open ? "transform rotate-180" : ""
                              } w-5 h-5 text-white`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                            <div className="flex-col border border-gray-200 rounded-lg col-span-4 xl:col-span-3 overflow-x-auto overflow-hidden flex-grow align-middle inline-block min-w-full">
                              <TableComponent
                                data={{
                                  head: [
                                    {
                                      element: "Tarih",
                                      props: { scope: "col" },
                                    },
                                    {
                                      element: "Masa Adı ",
                                      props: { scope: "col" },
                                    },
                                    {
                                      element: "Sipariş Adedi",
                                      props: { scope: "col" },
                                    },
                                  ],
                                  body: parsedForCSV[reportType][index]?.map(
                                    (table: any) => {
                                      return {
                                        id: table.date + table.table,
                                        elements: [
                                          {
                                            element: table.date,
                                            props: {
                                              className:
                                                "text-left text-sm text-gray-900",
                                            },
                                          },
                                          {
                                            element: table.tableName,
                                            props: {
                                              className:
                                                "text-left text-sm text-gray-900",
                                            },
                                          },
                                          {
                                            element: table.quantity,
                                            props: {
                                              className:
                                                "text-left text-sm text-gray-900",
                                            },
                                          },
                                        ],
                                      };
                                    }
                                  ),
                                }}
                              />
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              ))}

            {Fetchs.Reports.Ordertype.data &&
              !Fetchs.Reports.Ordertype.error &&
              !Fetchs.Reports.Ordertype.loading &&
              reportType == "ordertypebased" &&
              Fetchs.Reports.Ordertype.data.report.map((branch, index) => (
                <div key={branch.title} className="w-full px-4 pt-1">
                  <div className="w-full p-2 mx-auto bg-white rounded-2xl">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left bg-slate-800 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus-visible:ring focus-visible:ring-slate-800 focus-visible:ring-opacity-75">
                            <span>{branch.title}</span>
                            <ChevronUpIcon
                              className={`${
                                open ? "transform rotate-180" : ""
                              } w-5 h-5 text-white`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                            <div className="flex-col border border-gray-200 rounded-lg col-span-4 xl:col-span-3 overflow-x-auto overflow-hidden flex-grow align-middle inline-block min-w-full">
                              <TableComponent
                                data={{
                                  head: [
                                    {
                                      element: "Tarih",
                                      props: { scope: "col" },
                                    },
                                    {
                                      element: "Sipariş Tipi",
                                      props: { scope: "col" },
                                    },
                                    {
                                      element: "Sipariş Adedi",
                                      props: { scope: "col" },
                                    },
                                    {
                                      element: "Kazanç",
                                      props: { scope: "col" },
                                    },
                                  ],
                                  body: parsedForCSV[reportType][index]?.map(
                                    (order: any) => {
                                      return {
                                        id: order.date + order.order_type,
                                        elements: [
                                          {
                                            element: order.date,
                                            props: {
                                              className:
                                                "text-left text-sm text-gray-900",
                                            },
                                          },
                                          {
                                            element: order.typeName,
                                            props: {
                                              className:
                                                "text-left text-sm text-gray-900",
                                            },
                                          },
                                          {
                                            element: order.quantity,
                                            props: {
                                              className:
                                                "text-left text-sm text-gray-900",
                                            },
                                          },
                                          {
                                            element: order.payment,
                                            props: {
                                              className:
                                                "text-left text-sm text-gray-900",
                                            },
                                          },
                                        ],
                                      };
                                    }
                                  ),
                                }}
                              />
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              ))}
          </>
        ) : (
          <>
            {Fetchs.Reports.Case.data &&
              !Fetchs.Reports.Case.error &&
              !Fetchs.Reports.Case.loading &&
              reportType == "casebased" &&
              Fetchs.Reports.Case.data.report.map((branch: any) => {
                return (
                  <div key={branch.title} className="w-full px-4 pt-1">
                    <div className="w-full p-2 mx-auto bg-white rounded-2xl">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left bg-slate-800 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus-visible:ring focus-visible:ring-slate-800 focus-visible:ring-opacity-75">
                              <span>{branch.title}</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? "transform rotate-180" : ""
                                } w-5 h-5 text-white`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pt-4 grid grid-cols-1 md:grid-cols-2 md:gap-4 pb-2 text-sm text-gray-500">
                              {parseCaseBased(branch.data).map((parsed) => {
                                return (
                                  <div key={parsed.type}>
                                    <Line
                                      options={{
                                        scales: {
                                          x: {
                                            display: true,
                                            title: {
                                              display: true,
                                              text: "Tarih",
                                            },
                                          },
                                          y: {
                                            display: true,
                                            title: {
                                              display: true,
                                              text: "Satış",
                                            },
                                          },
                                        },
                                      }}
                                      data={{
                                        labels: parsed.dates,
                                        datasets: [
                                          {
                                            label:
                                              payment_types[parsed.type - 1]
                                                .title,
                                            data: parsed.amounts,
                                            borderColor:
                                              "rgba(255, 99, 132, 0.5)",
                                            fill: false,
                                          },
                                        ],
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  </div>
                );
              })}

            {Fetchs.Reports.Worker.data &&
              !Fetchs.Reports.Worker.error &&
              !Fetchs.Reports.Worker.loading &&
              reportType == "workerbased" &&
              Fetchs.Reports.Worker.data.report.map((branch: any) => {
                return (
                  <div key={branch.title} className="w-full px-4 pt-1">
                    <div className="w-full p-2 mx-auto bg-white rounded-2xl">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left bg-slate-800 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus-visible:ring focus-visible:ring-slate-800 focus-visible:ring-opacity-75">
                              <span>{branch.title}</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? "transform rotate-180" : ""
                                } w-5 h-5 text-white`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pt-4 grid grid-cols-1 md:grid-cols-2 md:gap-4 pb-2 text-sm text-gray-500">
                              {parseWorkerBased(branch.data).map((parsed) => {
                                let workerName = Fetchs.Workers.data.find(
                                  (w: any) => w.id == parsed.user
                                )?.name;
                                return (
                                  <div key={parsed.user}>
                                    <h3 className="text-center text-2xl">
                                      {workerName}
                                    </h3>
                                    <Line
                                      options={{
                                        scales: {
                                          x: {
                                            display: true,
                                            title: {
                                              display: true,
                                              text: "Tarih",
                                            },
                                          },
                                          y: {
                                            display: true,
                                            title: {
                                              display: true,
                                              text: "Satış",
                                            },
                                          },
                                        },
                                      }}
                                      data={{
                                        labels: parsed.dates,
                                        datasets: [
                                          {
                                            label: workerName,
                                            data: parsed?.amounts,
                                            borderColor:
                                              "rgba(255, 99, 132, 0.5)",
                                            fill: false,
                                          },
                                        ],
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  </div>
                );
              })}

            {Fetchs.Reports.Table.data &&
              !Fetchs.Reports.Table.error &&
              !Fetchs.Reports.Table.loading &&
              reportType == "tablebased" &&
              Fetchs.Reports.Table.data.report.map((branch: any) => {
                return (
                  <div key={branch.title} className="w-full px-4 pt-1">
                    <div className="w-full p-2 mx-auto bg-white rounded-2xl">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left bg-slate-800 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus-visible:ring focus-visible:ring-slate-800 focus-visible:ring-opacity-75">
                              <span>{branch.title}</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? "transform rotate-180" : ""
                                } w-5 h-5 text-white`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pt-4 grid grid-cols-1 md:grid-cols-2 md:gap-4 pb-2 text-sm text-gray-500">
                              {parseTableBased(branch.data).map((parsed) => {
                                let tableName = Fetchs.Tables.data.find(
                                  (t: any) => t.id == parsed.table
                                )?.title;
                                return (
                                  <div key={parsed.table}>
                                    <Line
                                      options={{
                                        scales: {
                                          x: {
                                            display: true,
                                            title: {
                                              display: true,
                                              text: "Tarih",
                                            },
                                          },
                                          y: {
                                            display: true,
                                            title: {
                                              display: true,
                                              text: "Satış",
                                            },
                                          },
                                        },
                                      }}
                                      data={{
                                        labels: parsed.dates,
                                        datasets: [
                                          {
                                            label: tableName,
                                            data: parsed.quantities,
                                            borderColor:
                                              "rgba(255, 99, 132, 0.5)",
                                            fill: false,
                                          },
                                        ],
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  </div>
                );
              })}

            {Fetchs.Reports.Ordertype.data &&
              !Fetchs.Reports.Ordertype.error &&
              !Fetchs.Reports.Ordertype.loading &&
              reportType == "ordertypebased" &&
              Fetchs.Reports.Ordertype.data.report.map((branch: any) => {
                return (
                  <div key={branch.title} className="w-full px-4 pt-1">
                    <div className="w-full p-2 mx-auto bg-white rounded-2xl">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left bg-slate-800 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus-visible:ring focus-visible:ring-slate-800 focus-visible:ring-opacity-75">
                              <span>{branch.title}</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? "transform rotate-180" : ""
                                } w-5 h-5 text-white`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pt-4 grid grid-cols-1 md:grid-cols-2 md:gap-4 pb-2 text-sm text-gray-500">
                              {parseOrderTypeBased(branch.data).map(
                                (parsed) => {
                                  return (
                                    <div key={parsed.order_type}>
                                      <Line
                                        options={{
                                          scales: {
                                            x: {
                                              display: true,
                                              title: {
                                                display: true,
                                                text: "Tarih",
                                              },
                                            },
                                            y: {
                                              display: true,
                                              title: {
                                                display: true,
                                                text: "Satış",
                                              },
                                            },
                                          },
                                        }}
                                        data={{
                                          labels: parsed.dates,
                                          datasets: [
                                            {
                                              label:
                                                Structures.types.order[
                                                  parsed.order_type as keyof typeof Structures.types.order
                                                ],
                                              data: parsed?.amounts,
                                              borderColor:
                                                "rgba(255, 99, 132, 0.5)",
                                              fill: false,
                                            },
                                          ],
                                        }}
                                      />
                                    </div>
                                  );
                                }
                              )}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  </div>
                );
              })}

            {Fetchs.Reports.Product.data &&
              !Fetchs.Reports.Product.error &&
              !Fetchs.Reports.Product.loading &&
              reportType == "productbased" &&
              Fetchs.Reports.Product.data.report.map((branch: any) => {
                return (
                  <div key={branch.title} className="w-full px-4 pt-1">
                    <div className="w-full p-2 mx-auto bg-white rounded-2xl">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left bg-slate-800 text-white rounded-lg hover:bg-slate-700 focus:outline-none focus-visible:ring focus-visible:ring-slate-800 focus-visible:ring-opacity-75">
                              <span>{branch.title}</span>
                              <ChevronUpIcon
                                className={`${
                                  open ? "transform rotate-180" : ""
                                } w-5 h-5 text-white`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pt-4 grid grid-cols-1 md:grid-cols-2 md:gap-4 pb-2 text-sm text-gray-500">
                              {parseProductBased(branch.data).map((parsed) => {
                                return (
                                  <div key={parsed.name}>
                                    <Line
                                      options={{
                                        scales: {
                                          x: {
                                            display: true,
                                            title: {
                                              display: true,
                                              text: "Tarih",
                                            },
                                          },
                                          y: {
                                            display: true,
                                            title: {
                                              display: true,
                                              text: "Satış",
                                            },
                                          },
                                        },
                                      }}
                                      data={{
                                        labels: parsed.dates,
                                        datasets: [
                                          {
                                            label: parsed.name,
                                            data: parsed.amounts,
                                            borderColor:
                                              "rgba(255, 99, 132, 0.5)",
                                            fill: false,
                                          },
                                        ],
                                      }}
                                    />
                                  </div>
                                );
                              })}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  </div>
                );
              })}
          </>
        )}
      </div>
    </React.Fragment>
  );
}
