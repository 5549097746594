import { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Http from "services/http";
import Case from "services/case";
import Branch from "services/branch";
import Member from "services/member";

import { useSWRConfig } from "swr";
import { useTranslation } from "react-i18next";
import Authenticate from "services/authenticate";
import Response from "services/response";
import {
  Box,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { green, red } from "@mui/material/colors";

export default function () {
  const CaseService = new Case(useContext(Http.Context)!);
  const UserService = new Member(useContext(Http.Context)!);
  const BranchService = new Branch(useContext(Http.Context)!);

  const Cases: any = new Response();
  const BHandle: any = new Response();
  const Users: any = new Response();

  const AuthContext = useContext(Authenticate.Context);

  const selectedBranch = useRef(AuthContext?.User?.branchId);
  const page = useRef(1);
  const perPage = useRef(10);

  const [caseData, setCaseData]: any = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const { mutate } = useSWRConfig();

  const navigate = useNavigate();

  const fetchData = (reset: boolean = false) => {
    if (reset) setCaseData([]);

    Cases.handle(
      CaseService.getByBranchId(
        page.current - 1,
        perPage.current,
        selectedBranch.current!
      )
    );
  };

  useEffect(() => fetchData(), []);
  useEffect(() => BHandle.handle(BranchService.getAll(), undefined), []);
  useEffect(
    () =>
      Users.handle(
        UserService.getByBranchId(selectedBranch.current!),
        undefined
      ),
    []
  );

  useEffect(() => {
    if (Cases.data) {
      setCaseData((prevData: any) => [...prevData, ...Cases.data?.data]);
      setTotalCount(Cases?.data?.totalCount);
    }
  }, [Cases?.data]);

  useEffect(() => {
    if (BHandle.data) {
    }
  }, [BHandle?.data]);

  const handleBranchChange = (value: any) => {
    setCaseData([]);
    selectedBranch.current = value;
    Users.handle(UserService.getByBranchId(selectedBranch.current!));
    page.current = 1;
    fetchData(true);
  };

  const loadMoreData = () => {
    page.current++;
    fetchData();
  };

  const { t } = useTranslation();
  return (
    <>
      <Stack
        direction="row"
        marginTop="10px"
        marginBottom="5px"
        justifyContent="space-between"
        alignContent="center"
        alignItems="center"
      >
        <Box marginLeft="2vw">
          <h2 className="text-xl font-medium pl-2">{t("case-list")}</h2>
        </Box>
        {BHandle.data && BHandle?.data[0]?.subBranch?.length > 0 ? (
          <Box marginRight="2vw">
            <Select
              sx={{ width: "12vw" }}
              size="small"
              value={selectedBranch.current}
              onChange={(e: any) => handleBranchChange(e.target.value)}
            >
              <MenuItem value={BHandle.data[0]?._id}>
                {BHandle.data[0]?.title}
              </MenuItem>

              {BHandle?.data[0]?.subBranch?.map((branch: any) => (
                <MenuItem value={branch?._id}>{branch?.title}</MenuItem>
              ))}
            </Select>
          </Box>
        ) : null}
      </Stack>
      <div>
        {totalCount == 0 && !Cases.loading ? (
          <Typography variant="h4" fontWeight="bold" textAlign="center">
            {t("noCase")}
          </Typography>
        ) : (
          <InfiniteScroll
            dataLength={caseData.length || 0}
            next={loadMoreData}
            hasMore={ caseData?.length < totalCount }
            height= { caseData?.length > 10 ? "85vh":"80vh" }
            loader={
              <Paper elevation={3}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography fontSize="1.1rem" fontWeight="bold">
                          {t("case-owner")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontSize="1.1rem" fontWeight="bold">
                          {t("open-date")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontSize="1.1rem" fontWeight="bold">
                          {t("updated-at")}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontSize="1.1rem" fontWeight="bold">
                          {t("case-status")}
                        </Typography>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {caseData.map((_case: any) => (
                      <TableRow
                        sx={{
                          backgroundColor: _case.is_open
                            ? green[200]
                            : red[200],
                          "&:hover": {
                            backgroundColor: _case.is_open
                              ? green[300]
                              : red[300],
                          },
                        }}
                      >
                        <TableCell>
                          <Typography fontSize="1rem" fontWeight="bold">
                            {Users.data?.find(
                              (user: any) => user._id == _case.user
                            )?.name +
                              " " +
                              Users.data?.find(
                                (user: any) => user._id == _case.user
                              )?.lastname}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize="1rem" fontWeight="bold">
                            {new Date(_case.createdAt).toLocaleDateString(
                              "tr-TR",
                              {
                                minute: "numeric",
                                hour: "numeric",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontSize="1rem" fontWeight="bold">
                            {new Date(_case.updatedAt).toLocaleDateString(
                              "tr-TR",
                              {
                                minute: "numeric",
                                hour: "numeric",
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                              }
                            )}
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography fontSize="1rem" fontWeight="bold">
                            {_case.is_open ? t("open") : t("close")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Detay">
                            <IconButton
                              onClick={() =>
                                navigate(`/case/action/${_case._id}`)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            }
          >
          </InfiniteScroll>
        )}
      </div>
    </>
  );
}
