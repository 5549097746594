import { Service as HService } from "services/http"

export default class Service {
    private Http: HService | null = null

    constructor(http: HService) {
        this.Http = http
    }

    public get endpoint(){
      return `${this.Http?.baseURL}/survey`
    }

    public async getSurveys() {
        return await this.Http?.instance.get("/survey");
    }

    public async createSurvey(data:any) {
        return await this.Http?.instance.post("/survey", data);
    }

    public async updateSurvey(id:string, data:object) {
        return await this.Http?.instance.put(`/survey/${id}`, data);
    }

    public async getSurvey(id:string) {
        return await this.Http?.instance.get(`/survey/${id}`);
    }

    public async deleteSurvey(id:string) {
        return await this.Http?.instance.delete(`/survey/${id}`);
    }



}