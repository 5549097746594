import React, { useContext } from "react";

import Http from "services/http";
import Product from "services/product";

import Table from "components/table";
import Image from "components/image";
import Loading from "components/loading";
import NetworkError from "components/network-error";
import useCache from "services/useCache";
import { useSWRConfig } from "swr";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function () {
  const ProductService = new Product(useContext(Http.Context)!);
  const Products = useCache(ProductService);
  const { t } = useTranslation();
  const { mutate } = useSWRConfig();

  return (
    <React.Fragment>
      <div className="header-top">
        <h2 className="text-xl font-medium pl-2">{t('favourite-opportunity-products')}</h2>
        <div style={{marginLeft: "auto", marginRight: "1.5vw"}}>
          <Link to="/favorite/rank">
            <span style={{ padding: 9, borderRadius: 7, backgroundColor: "#111827", color: "#fff", marginRight: "3vw" }}>
              {t('edit-favourite-rank')}
            </span>
          </Link>
          <Link to="/opportunity/rank">
            <span style={{ padding: 9, borderRadius: 7, backgroundColor: "#111827", color: "#fff" }}>
              {t('edit-opportunity-rank')}
            </span>
          </Link>
        </div>
      </div>
      <div id="content">
        <Loading done={Products.data} />
        {Products.error ? (
          <NetworkError
            error={Products.error}
            retry={() => mutate(ProductService.endpoint)}
          />
        ) : null}

        {Products.data && !Products.error && (
          <Table
            data={{
              head: [
                { element: t('favourite-products'), props: { scope: "col" } },
                { element: t('price'), props: { scope: "col" } },
                { element: t('created-at'), props: { scope: "col" } },
              ],

              body: Products.data
                .filter((product: any) => {
                  if (product?.favorite == true) {
                    return product;
                  }
                })
                .map((product: any) => ({
                  id: product.id,
                  elements: [
                    {
                      element: (
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-12 w-12 rounded-full border border-gray-300 p-0.5">
                            <Image
                              className="h-full w-full rounded-full object-cover"
                              src={product.image}
                            />
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {product.title}
                            </div>
                            <div className="text-sm text-gray-500">
                              {product.description}
                            </div>
                          </div>
                        </div>
                      ),
                    },
                    {
                      element: `${product.prices[0].price} ${product.prices[0].currency}`,
                      props: { className: "text-left text-sm text-gray-900" },
                    },
                    {
                      element: new Date(product.createdAt).toLocaleDateString(
                        "tr-TR",
                        {
                          minute: "numeric",
                          hour: "numeric",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      ),
                      props: { className: "text-left text-sm text-gray-900" },
                    },
                  ],
                })),
            }}
          />
        )}
        {Products.data && !Products.error && (
          <Table
            data={{
              head: [
                { element: t('opportunity-products'), props: { scope: "col" } },
                { element: t('price'), props: { scope: "col" } },
                { element: t('created-at'), props: { scope: "col" } },
              ],

              body: Products.data
                .filter((product: any) => {
                  if (product.opportunity == true) {
                    return product;
                  }
                })
                .map((product: any) => ({
                  id: product.id,
                  elements: [
                    {
                      element: (
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-12 w-12 rounded-full border border-gray-300 p-0.5">
                            <Image
                              className="h-full w-full rounded-full object-cover"
                              src={product.image}
                            />
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {product.title}
                            </div>
                            <div className="text-sm text-gray-500">
                              {product.description}
                            </div>
                          </div>
                        </div>
                      ),
                    },
                    {
                      element: `${product.prices[0].price} ${product.prices[0].currency}`,
                      props: { className: "text-left text-sm text-gray-900" },
                    },
                    {
                      element: new Date(product.createdAt).toLocaleDateString(
                        "tr-TR",
                        {
                          minute: "numeric",
                          hour: "numeric",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        }
                      ),
                      props: { className: "text-left text-sm text-gray-900" },
                    },
                  ],
                })),
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
}
