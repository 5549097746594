import { Service as HService } from "services/http";

export default class Service {
  private Http: HService | null = null;

  constructor(http: HService) {
    this.Http = http;
  }

  public get endpoint() {
    return `${this.Http?.baseURL}/members`;
  }

  public getAll(
    start: number | undefined = undefined,
    end: number | undefined = undefined
  ) {
    const base: URL = new URL(this.endpoint);

    if (start !== undefined && !Number.isNaN(start))
      base.searchParams.append("_start", String(start));

    if (end !== undefined && !Number.isNaN(end))
      base.searchParams.append("_end", String(end));

    return this.Http?.instance.get(
      base.href.substring(base.href.lastIndexOf("/"))
    );
  }

  public async getById(id: string) {
    return await this.Http?.instance.get(`/members/${id}`);
  }

  public async getByBranchId(id: string) {
    return await this.Http?.instance.get(`/members/branch/${id}`);
  }

  public async create(data: any) {
    return await this.Http?.instance.post("/members", data);
  }

  public async update(id: string, data: any) {
    return await this.Http?.instance.put(`/members/${id}`, data);
  }

  public async delete(id: string) {
    return await this.Http?.instance.delete(`/members/${id}`);
  }
}
