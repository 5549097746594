import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Select, MenuItem, Box, Button, InputLabel, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Collapse, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Toolbar, Stack, ButtonGroup, FormControl } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import Http from "services/http";
import Response from "services/response";
import { toast } from 'react-toastify';
import Loading from "components/loading";
import { PaymentTypes } from 'services/interfaces/report/table';
import { FileDownload } from '@mui/icons-material';
import { parseExpenseReport, parseOpenAccountDetailReport, parseOpenAccountReport } from 'services/helpers/excel';
import { convertCurrency, defaultCurrency } from 'services/helpers/exchange';

//user token info for api
const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
const refreshToken = localStorage.getItem(
    process.env.REACT_APP_REFRESH_TOKEN_NAME!
);

export default function ExpenseReport() {
    const { t } = useTranslation();

    //report range - last 1 day by default
    const [to, setTo] = useState(new Date());
    const [from, setFrom] = useState(new Date(new Date().getTime() - 86400000));

    //loading info for fetching
    const [loading, setLoading] = useState(false);
    //all branches accessible by the user
    const [branches, setBranches]: any[] = useState([]);
    //branch to fetch reports
    const [selectedBranch, setSelectedBranch] = useState("");
    //report data
    const [report, setReport] = useState([]);

    //open account report data
    const [openAccountDialogOpen, setOpenAccountDialog] = useState(false);
    const [openAccounts, setOpenAccounts] = useState([]);
    const [openAccountDetail, setOpenAccountDetailDialog] = useState(false);
    const [selectedOpenAccount, setSelectedOpenAccount]: any = useState({});

    //only works once for first report data
    useEffect(() => {
        //fetch branch then fetch report
        const fetchBranch = async () => {
            let branch: any = await axios.get(`manager/branches`, {
                baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "x-refresh": refreshToken!,
                },
            });
            await setBranches([branch?.data[0]].concat(branch?.data[0]?.subBranch));
            await setSelectedBranch(branch?.data[0]?._id);
            const fetchReports = async () => {
                let reportData: any = await axios.get(`manager/report/expenses/${branch?.data[0]?._id}`, {
                    baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "x-refresh": refreshToken!,
                    },
                    params: {
                        from: new Date(from).getTime(),
                        to: new Date(to).getTime()
                    }
                });
                setReport(reportData.data);
            }
            fetchReports();
        }

        fetchBranch();
    }, [])

    const exportReports = () => {
        if(report?.length == 0){
            toast.warning(t('no-ready-report'));
            return;
        }
        parseExpenseReport(report, branches?.find((b: any) => b._id == selectedBranch)?.title);
    }

    const openAccountOnClick = async () => {
        let openAccounts = await axios.get(`manager/report/open-account/${selectedBranch}`, {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                Authorization: `Bearer ${token}`,
                "x-refresh": refreshToken!,
            }
        });
        setOpenAccounts(openAccounts.data);
        setOpenAccountDialog(true);
    }

    const OpenAccountDetailDialog = () => {
        const exportOpenAccountDetail = () => {
            if(!selectedOpenAccount){
                toast.warning('no-ready-report');
                return;
            }
            parseOpenAccountDetailReport(selectedOpenAccount);
        }
        return (
            <Dialog open={openAccountDetail} onClose={() => setOpenAccountDetailDialog(false)} fullWidth>
                <DialogTitle>{selectedOpenAccount.name} {t('detail')}</DialogTitle>
                <DialogContent>
                    {selectedOpenAccount?.expenses?.length == 0 ? (
                        <></>
                    ) : 
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('title')}</TableCell>
                                        <TableCell>{t('description')}</TableCell>
                                        <TableCell>{t('amount')}</TableCell>
                                        <TableCell>{t('currency')}</TableCell>
                                        <TableCell>{t('user')}</TableCell>
                                        <TableCell>{t('created-at')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedOpenAccount?.expenses?.map((expense: any) => (
                                        <TableRow>
                                            <TableCell>{expense.title}</TableCell>
                                            <TableCell>{expense.description}</TableCell>
                                            <TableCell>{convertCurrency(expense.expense_amount)}</TableCell>
                                            <TableCell>{defaultCurrency()}</TableCell>
                                            <TableCell>{expense?.user?.name} {expense?.user?.lastname}</TableCell>
                                            <TableCell>{new Date(expense.createdAt)?.toLocaleDateString()}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => exportOpenAccountDetail()} sx={{ backgroundColor: "#717D8C" }} startIcon={<FileDownload />}>{t('export-reports')}</Button>
                    <Button variant="outlined" color="error" onClick={() => setOpenAccountDetailDialog(false)}>{t('close-it')}</Button>
                </DialogActions>

            </Dialog>
        )
    }

    const OpenAccountDialog = () => {
        const openAccountOnClick = (user: any) => {
            setSelectedOpenAccount({
                name: user.name,
                expenses: user.expenses
            });
            setOpenAccountDetailDialog(true);
        }
        
        const exportOpenAccountReport = () => {
            if(openAccounts?.length == 0){
                toast.warning(t('no-ready-report'));
                return;
            }
            parseOpenAccountReport(openAccounts, branches?.find((b: any) => b._id == selectedBranch)?.title);
        }
        return (
            <Dialog open={openAccountDialogOpen} onClose={() => setOpenAccountDialog(false)} fullWidth>
                <DialogTitle>{t('open-accounts')}</DialogTitle>
                <DialogContent>
                    {openAccounts?.length == 0 ? (
                        <><Loading /></>
                    ) : (
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('name')}</TableCell>
                                        <TableCell>{t('total-amount')}</TableCell>
                                        <TableCell>{t('createdBy')}</TableCell>
                                        <TableCell>{t('created-at')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {openAccounts?.map((user: any) => (
                                        <TableRow sx={{cursor: "pointer", "&:hover": {backgroundColor: "#E8E8E8"}}} onClick={() => openAccountOnClick(user)}>
                                            <TableCell>{user.name}</TableCell>
                                            <TableCell>{convertCurrency(user.totalAmount)}</TableCell>
                                            <TableCell>{user.createdBy?.name} {user.createdBy?.lastname}</TableCell>
                                            <TableCell>{new Date(user.createdAt)?.toLocaleDateString()}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}

                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => exportOpenAccountReport()} sx={{ backgroundColor: "#717D8C" }} startIcon={<FileDownload />}>{t('export-reports')}</Button>
                    <Button variant='outlined' color="error" onClick={() => setOpenAccountDialog(false)}>{t('close-it')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    //handle button click - runs auto on every branch changes
    const syncData = async () => {
        if (
            !new Date(from).getTime() ||
            !new Date(to).getTime()
        ) {
            toast.warning(t('please-check-date'));
            return;
        }
        if (from > to) {
            toast.warning(t('date-warning'));
            return;
        }
        setLoading(true);
        let reportData: any = await axios.get(`manager/report/expenses/${selectedBranch}`, {
            baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
            headers: {
                Authorization: `Bearer ${token}`,
                "x-refresh": refreshToken!,
            },
            params: {
                from: new Date(from).getTime(),
                to: new Date(to).getTime()
            }
        })
        setReport(reportData.data);
        setLoading(false);
    }
    return (
        <Box sx={{ overflow: "auto" }}>
            <OpenAccountDialog />
            <OpenAccountDetailDialog />
                <Stack direction="row" sx={{ marginTop: 1 }}>
                    <Grid container justifyContent="left">
                        {branches.length > 1?
                        <FormControl sx={{ marginTop:1, marginLeft:1, marginBottom:1}} >
                            <InputLabel id="branchLabel">{t('branch')}</InputLabel>
                            <Select sx={{ minWidth: "10vw" }} labelId="branchLabel" value={selectedBranch} onChange={async (e: any) => {
                                await setSelectedBranch(e.target.value);
                                syncData();
                            }}>
                                {branches?.map((branch: any) => (
                                    <MenuItem value={branch._id}>
                                        {branch.title}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>:<></>}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>

                            <Stack direction="row" sx={{ marginTop:1 ,marginLeft:1 }} spacing={1}>
                                <DateTimePicker
                                    label={t('start-date')!}
                                    inputFormat="DD/MM/YYYY HH:mm"
                                    value={from}
                                    ampm={false}
                                    onChange={async (value: any) => {
                                        await setFrom(value)
                                    }}
                                    renderInput={(params: any) => <TextField {...params} />}
                                />
                                <DateTimePicker
                                    label={t('end-date')!}
                                    inputFormat="DD/MM/YYYY HH:mm"
                                    value={to}
                                    ampm={false}
                                    onChange={async (value: any) => {
                                        await setTo(value)
                                    }}
                                    renderInput={(params: any) => <TextField {...params} />}
                                />
                            </Stack>
                        </LocalizationProvider>

                    </Grid>

                    <Grid container justifyContent="right" >
                        <ButtonGroup orientation='vertical' sx={{ marginRight: "1vw" }}>
                            <Button variant="contained" color="inherit" onClick={() => {
                                syncData();
                            }}>{t('get-reports')}
                            </Button>
                            <Button variant="contained" color="inherit" onClick={() => openAccountOnClick()}>{t('open-accounts')}</Button>
                            <Button variant='contained' sx={{ backgroundColor: "#717D8C" }} onClick={() => exportReports()} startIcon={<FileDownload />}>{t('export-reports')}</Button>
                        </ButtonGroup>
                    </Grid>
                </Stack>
            <Box>
                {loading ? (
                    <Loading />
                ) : (
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('title')}</TableCell>
                                    <TableCell>{t('description')}</TableCell>
                                    <TableCell>{t('amount')}</TableCell>
                                    <TableCell>{t('currency')}</TableCell>
                                    <TableCell>{t('payment-type')}</TableCell>
                                    <TableCell>{t('open-account')}</TableCell>
                                    <TableCell>{t('user')}</TableCell>
                                    <TableCell>{t('created-at')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {report?.map((report: any) => (
                                    <TableRow>
                                        <TableCell>{report.title}</TableCell>
                                        <TableCell>{report.description}</TableCell>
                                        <TableCell>{convertCurrency(report.expense_amount)}</TableCell>
                                        <TableCell>{defaultCurrency()}</TableCell>
                                        <TableCell>{report.expense_type == 2 ? t('open-account') : t(PaymentTypes[report.payment_type as keyof typeof PaymentTypes])}</TableCell>
                                        <TableCell>{report.expense_type == 2 ? report.openAccount?.name : <>-</>}</TableCell>
                                        <TableCell>{report?.case?.user?.name} {report?.case?.user?.lastname}</TableCell>
                                        <TableCell>{new Date(report.createdAt).toLocaleString()}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Box>
        </Box>
    )
}
