import { Service as HService } from "services/http"

export default class Service {
    private Http: HService | null = null

    constructor(http: HService) {
        this.Http = http
    }

    public get endpoint(){
      return `${this.Http?.baseURL}/branches/userservices`
    }

    public async getAll() {
        return await this.Http?.instance.get("/branches/userservices");
    }

    public async add(data:any) {
        return await this.Http?.instance.post(`/branches/userservices`, data);
    }

    public async update(id:any, data:any) {
        return await this.Http?.instance.put(`/branches/userservices/${id}`, data);
    }

    public async getService(id:any) {
        return await this.Http?.instance.get(`/branches/userservices/${id}`);
    }

    public async delete(id:any) {
        return await this.Http?.instance.delete(`/branches/userservices/${id}`);
    }



}