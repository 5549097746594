import {
  Box,
  TextField,
  Stack,
  Button,
  Typography,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  Pagination,
  Grid,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";

import Http from "services/http";
import Product from "services/product";
import BranchService from "api/branch";
import Response from "services/response";
import { toast } from "react-toastify";
import clone from "clone";

export const HuginIntegration = () => {
  const { t } = useTranslation();
  const ProductService = new Product(useContext(Http.Context)!);
  const BService = new BranchService();
  const PFetch = new Response();
  const DeviceFetch = new Response();
  const SaveProduct = new Response();
  const [page, setPage] = useState(1);
  const [title, setTitle] = useState("");
  const [productData, setProductData] = useState([]);
  const [selectedProduct, setSelectedProduct]: any = useState({});
  const [productDialogOpen, setProductDialog] = useState(false);
  const [deviceSettingsOpen, setDeviceSettingsDialog] = useState(false);

  useEffect(
    () => (
      productData.length == 0 &&
        PFetch.handle(ProductService.getProducts(page - 1, 20)),
      undefined
    ),
    []
  );

  useEffect(() => {
    if (PFetch.data) {
      setProductData(PFetch?.data?.products);
    }
  }, [PFetch.data]);

  useEffect(() => {
    if (!SaveProduct.loading && (SaveProduct.data || SaveProduct.error)) {
      if (SaveProduct.error) {
        toast.error(
          t(SaveProduct.error.response?.data?.message || "server-error")
        );
      } else {
        let tempData: any = clone(productData);
        let product = SaveProduct.data?.product;
        let i = productData.findIndex((p: any) => p._id == product._id);
        if (i > -1) {
          tempData[i] = product;
          setProductData(tempData);
        }
        toast.success(t("saving-successful"));
      }
    }
  }, [!SaveProduct.loading]);

  const getPageCount = () => {
    if (PFetch.data) {
      return Math.ceil(PFetch.data?.count / 20);
    }
    return 1;
  };

  const saveIntegration = async () => {
    try {
      let res = await ProductService.huginIntegration();
      if ((res.status = 200)) {
        toast.success(t("saving-successful"));
      }
    } catch (e: any) {
      toast.error(t("server-error"));
    }
  };

  const handlePageChange = (value: number) => {
    setPage(value);
    PFetch.handle(ProductService.getProducts(value - 1, 20, title));
  };

  const handleSearch = () => {
    PFetch.handle(ProductService.getProducts(page - 1, 20, title));
  };

  const isNum = (val: string) => {
    return /^\d+$/.test(val.replace(/\s/g, ""));
  };

  const DeviceSettingsDialog = () => {
    const [okc_id, setOkc] = useState("");
    const [huginDevices, setHuginDevices] = useState([]);

    useEffect(() => {
      const fetchDevices = async () => {
        let devices = await BService.getHuginDevices();
        console.log(devices);
        setHuginDevices(devices || []);
      };
      fetchDevices();
    }, []);

    const handleClose = () => {
      setDeviceSettingsDialog(false);
    };

    const handleSave = async () => {
      if (!okc_id) {
        toast.warning(t("okc_id_required"));
        return;
      }
      let find = huginDevices.find((device: any) => device.okc_id == okc_id);
      if (find) {
        toast.warning(t("okc_id_already_in_use"));
        return;
      }
      try {
        let res = await BService.addHuginDevice(okc_id);
        if (res) {
          setHuginDevices(res || huginDevices || []);
          toast.success(t("adding-hugin-device-success"));
        }
      } catch (e: any) {
        toast.error(t("server-error"));
      }
    };

    const handleDelete = async (okc_id: string) => {
      try {
        let res = await BService.deleteHuginDevice(okc_id);
        if (res) {
          toast.success(t("delete-hugin-device-success"));
          setHuginDevices(res || huginDevices || []);
        }
      } catch (e: any) {
        toast.error(t("server-error"));
      }
    };

    return (
      <Dialog open={deviceSettingsOpen} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Box
            sx={{
              margin: "auto",
              width: "50%",
              padding: "1vw",
              borderRadius: "8vw",
              backgroundColor: "#293241",
            }}
          >
            <Typography
              variant="h4"
              fontSize="2vw"
              fontWeight="bold"
              textAlign="center"
              color="white"
            >
              {t("device-settings")}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Stack
            direction="column"
            spacing={5}
            alignItems="center"
            sx={{ marginTop: "1vw" }}
          >
            <Box>
              <Stack direction="row" spacing={2} alignItems="center">
                <TextField
                  sx={{ width: "12vw", fontSize: "1vw" }}
                  label={t("fiscal-id")}
                  size="small"
                  value={okc_id}
                  onChange={(e: any) => setOkc(e.target.value)}
                />
                <Button
                  variant="contained"
                  sx={{
                    width: "8vw",
                    fontSize: "1vw",
                    backgroundColor: "#293241",
                    "&:hover": { backgroundColor: "#535A66" },
                  }}
                  onClick={handleSave}
                >
                  {t("save")}
                </Button>
              </Stack>
            </Box>
            <Box>
              {huginDevices?.length > 0 ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("fiscal-id")}</TableCell>
                        <TableCell>{t("added-by")}</TableCell>
                        <TableCell>{t("date-added")}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {huginDevices?.map((device: any) => (
                        <TableRow>
                          <TableCell>{device.okc_id}</TableCell>
                          <TableCell>{device.addedBy?.name}</TableCell>
                          <TableCell>
                            {new Date(device.createdAt)?.toLocaleString()}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={(e: any) => {
                                handleDelete(device.okc_id);
                              }}
                            >
                              <DeleteIcon color="error" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography
                  variant="h4"
                  textAlign="center"
                  sx={{ fontSize: "1.5vw" }}
                >
                  {t("no-added-device")}
                </Typography>
              )}
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleClose}>
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const ProductDialog = () => {
    const [plu, setPlu] = useState("");
    const [barcode, setBarcode] = useState("");
    const [deptId, setDeptId] = useState(1);

    useEffect(() => {
      setPlu(selectedProduct.plu || "");
      setBarcode(selectedProduct.barcode || "");
      setDeptId(selectedProduct.deptId || 1);
    }, []);

    const handleSave = async () => {
      if (barcode.length > 20 || (barcode.length > 0 && !isNum(barcode))) {
        toast.warning(t("barcode-warning"), { autoClose: 5000 });
        return;
      }
      if (plu.length > 6 || (plu.length > 0 && !isNum(plu))) {
        toast.warning(t("plu-warning"), { autoClose: 5000 });
        return;
      }
      SaveProduct.handle(
        ProductService.setBarcodePlu(selectedProduct._id, deptId, barcode, plu)
      );
      setProductDialog(false);
    };

    const handleClose = () => {
      setProductDialog(false);
      setSelectedProduct({});
    };
    return (
      <Dialog open={productDialogOpen} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Box
            sx={{
              margin: "auto",
              width: "50%",
              padding: "1vw",
              borderRadius: "8vw",
              backgroundColor: "#293241",
            }}
          >
            <Typography
              variant="h4"
              fontSize="2vw"
              fontWeight="bold"
              textAlign="center"
              color="white"
            >
              {selectedProduct.title}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Stack
            direction="column"
            alignItems="center"
            spacing="1vw"
            marginTop="1vw"
          >
            <Stack direction="column" spacing={1} alignItems="center">
              <InputLabel sx={{ fontSize: "1.2vw", fontWeight: "bold" }}>
                {t("department-code")}
              </InputLabel>
              <Select
                sx={{ width: "15vw", fontSize: "1vw" }}
                size="small"
                value={deptId}
                onChange={(e: any) => setDeptId(e.target.value || 1)}
              >
                {[1, 2, 3, 4, 5, 6, 7, 8].map((val: number) => (
                  <MenuItem value={val} key={val}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <Stack direction="column" alignItems="center">
              <TextField
                sx={{ fontSize: "1vw", width: "15vw" }}
                label={`${t("barcode")}`}
                onChange={(e: any) => setBarcode(e.target.value)}
                value={barcode}
              />
            </Stack>
            <TextField
              sx={{ fontSize: "1vw", width: "15vw" }}
              label={t("plu")}
              onChange={(e: any) => setPlu(e.target.value)}
              value={plu}
            />
            <FormHelperText>{t("hugin-helper-text")}</FormHelperText>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: "#293241",
              color: "white",
              fontSize: "1vw",
              width: "8vw",
              "&:hover": {
                backgroundColor: "#535A66",
              },
            }}
            variant="contained"
            onClick={handleSave}
          >
            {t("save")}
          </Button>
          <Button
            sx={{ fontSize: "1vw", width: "8vw" }}
            variant="outlined"
            color="error"
            onClick={handleClose}
          >
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box sx={{ overflowY: "scroll" }}>
      <ProductDialog />
      <DeviceSettingsDialog />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ margin: "2vw" }}
      >
        <Box>
          <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }} variant="h3">
            {t("hugin-integration")}
          </Typography>
        </Box>
        <Box>
          <Button
            variant="contained"
            sx={{
              width: "12vw",
              fontSize: "1vw",
              backgroundColor: "#293241",
              "&:hover": { backgroundColor: "#293238" },
            }}
            onClick={() => setDeviceSettingsDialog(true)}
          >
            {t("device-settings")}
          </Button>
        </Box>
      </Stack>
      <hr />
      <Box sx={{ marginTop: "2vw", marginLeft: "2vw", marginBottom: "2vw" }}>
        <Stack direction="row" justifyContent="space-around">
          <Stack direction="row" spacing={1} alignItems="center">
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={handleSearch}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e: any) => setTitle(e.target.value)}
              sx={{ width: "14vw", fontSize: "1vw" }}
              size="small"
              label={t("search-products")}
            />
          </Stack>
          <Button
            variant="contained"
            sx={{
              width: "16vw",
              fontSize: "0.8vw",
              color: "white",
              backgroundColor: "#293241",
              "&:hover": {
                backgroundColor: "#293238",
              },
            }}
            onClick={saveIntegration}
          >
            {t("save-integration")}
          </Button>
        </Stack>
      </Box>
      <hr />
      <Box
        sx={{
          marginTop: "1vw",
          marginBottom: "2vw",
        }}
      >
        {PFetch.loading ? (
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              className="products"
              sx={{ width: "100%", marginBottom: "1vw" }}
              paddingRight="1vw"
              paddingLeft="1vw"
            >
              <Grid container columns={{ xs: 2, sm: 4, md: 6, lg: 10 }}>
                {Array.from(Array(20).keys()).map((value: number) => (
                  <Grid item xs={2} sm={2} md={2} lg={2} key={value}>
                    <Skeleton height={200} width={220} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Stack>
        ) : productData.length > 0 && !PFetch.loading ? (
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              className="products"
              sx={{ width: "100%", marginBottom: "1vw" }}
              paddingRight="1vw"
              paddingLeft="1vw"
            >
              <Grid
                container
                spacing={{ xs: 1, sm: 2, md: 3 }}
                columns={{ xs: 2, sm: 4, md: 6, lg: 10 }}
              >
                {productData?.map((product: any, index: number) => (
                  <Grid item xs={2} sm={2} md={2} lg={2} key={index}>
                    <Box
                      onClick={() => {
                        setSelectedProduct(product);
                        setProductDialog(true);
                      }}
                      sx={{
                        height: "auto",
                        width: "100%",
                        backgroundColor: "#293241",
                        borderRadius: "8px",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                        padding: "1vw",
                        paddingLeft: "1.5vw",
                        paddingRight: "1.5vw",
                        color: "#E0FBFC",
                        textAlign: "center",
                        transition: "transform 0.2s ease-in-out",
                        cursor: "pointer",
                      }}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.transform = "scale(1.05)";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.transform = "scale(1)";
                      }}
                    >
                      <Typography sx={{ fontSize: "1vw" }}>
                        {product.title}
                      </Typography>
                      {product.barcode ? (
                        <Typography
                          sx={{ fontSize: "0.8vw", color: "#A8DADC" }}
                        >
                          {product.barcode}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontSize: "0.8vw",
                            color: "#F25F5C",
                            height: "1.6vw",
                          }}
                        >
                          {t("barcode-missing")}
                        </Typography>
                      )}
                      {product.plu ? (
                        <Typography
                          sx={{ fontSize: "0.8vw", color: "#F4A261" }}
                        >
                          {product.plu}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontSize: "0.8vw",
                            color: "#F25F5C",
                            height: "1.6vw",
                          }}
                        >
                          {t("plu-missing")}
                        </Typography>
                      )}
                      {product.plu && product.barcode ? (
                        <Typography
                          sx={{
                            fontSize: "0.8vw",
                            color: "#5ECC62",
                          }}
                        >
                          {t("ready-for-integration")}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontSize: "0.8vw",
                            color: "#F25F5C",
                            height: "1.6vw",
                          }}
                        >
                          {t("fix-missing-fields")}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box
              sx={{
                position: "relative",
                display: "block",
                bottom: "0.2vw",
                right: "30w",
              }}
            >
              <Pagination
                shape="rounded"
                count={getPageCount()}
                page={page}
                onChange={(e: any, value: number) => {
                  handlePageChange(value);
                }}
              />
            </Box>
          </Stack>
        ) : (
          <Box>
            <Typography
              variant="h4"
              textAlign="center"
              sx={{ fontWeight: "bold" }}
            >
              {t("no-products-found")}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};
