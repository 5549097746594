import { Formik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { object, string } from "yup";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import Spinner from "components/spinner";

import UString from "utilities/string";
import Response from "services/response";
import Getir from "services/getir";
import Branch from "services/branch";
import Http from "services/http";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function () {
  const Form = useRef<any>();
  const { t } = useTranslation();
  const Save = new Response();
  const GetirIntegration = new Response();
  const GetirService = new Getir(useContext(Http.Context)!);
  const BranchService = new Branch(useContext(Http.Context)!);

  useEffect(() => {
    if (!GetirIntegration.data) {
      GetirIntegration.handle(BranchService.getGetirCredentials());
    }
  }, [GetirIntegration.data]);

  // Hiding/Showing private data
  const [isSecretShown, setIsSecretShown] = useState(false);
  const showSecretHandler = () => {
    setIsSecretShown(!isSecretShown);
  };

  useEffect(() => {
    if (Save.data && !Save.error) {
      toast.success(
        `${t("integration")} ${
          GetirIntegration.data ? t("editing") : t("adding")
        } ${t("success")}.`
      );
    }
  }, [Save.data]);

  useEffect(() => {
    if (Save.error) {
      toast.error(
        `${t("integration")} ${
          GetirIntegration.data ? t("editing") : t("adding")
        } ${t("failure")}. (${Save.error.message})`
      );
    }
  }, [Save.error]);

  return (
    <React.Fragment>
      <div className="header-top">
        <h2 className="text-xl font-medium pl-2">
          <span>
            Getir {t("integration")}{" "}
            {GetirIntegration.data ? t("edit") : t("add")}{" "}
          </span>
        </h2>
      </div>
      <div id="content">
        <Formik
          innerRef={Form}
          enableReinitialize={true}
          initialValues={{
            RSK: GetirIntegration.data || String(),
          }}
          validationSchema={object({
            RSK: string().required(t("required")!),
          })}
          onSubmit={async (values) => {
            const token = await GetirService.login(values.RSK);
            if (token.status !== 200 || token?.data?.error) {
              toast.error(t("restaurant-secret-key-error"));
              return;
            }
            Save.handle(
              BranchService.saveGetirCredentials({
                secret_key: values.RSK,
              })
            );
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form className="action" onSubmit={handleSubmit}>
              <div className="wrapper">
                <div className="item">
                  <div className="relative flex w-full flex-wrap items-stretch">
                    <label htmlFor="RSK" className="h-5">
                      {t("restaurant-secret-key")}
                    </label>
                    <input
                      type={isSecretShown ? "text" : "password"}
                      name="RSK"
                      id="RSK"
                      className={UString.concat_class_name(
                        errors.RSK && touched.RSK ? "has-error" : undefined,
                        "my-1 pl-3 relative w-full pr-10 "
                      )}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="************"
                      value={values.RSK}
                    />
                    <span className="select-none cursor-pointer mt-3 absolute bg-transparent items-center justify-center w-8 right-0 pr-3 py-5">
                      {isSecretShown ? (
                        <EyeOffIcon
                          className="h-5 w-5"
                          onClick={showSecretHandler}
                        />
                      ) : (
                        <EyeIcon
                          className="h-5 w-5"
                          onClick={showSecretHandler}
                        />
                      )}
                    </span>
                    {errors.RSK && touched.RSK ? (
                      <span className="error absolute mt-16">
                        * {errors.RSK}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <div className="header-bottom">
        <button
          type="submit"
          disabled={Save.loading}
          className="ml-auto classic-btn"
          onClick={() => Form.current.handleSubmit()}
        >
          {Save.loading ? <Spinner className="h-5 m-auto" /> : t("save")}
        </button>
      </div>
    </React.Fragment>
  );
}
