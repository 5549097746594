import {
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Grid,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import UserService from "api/user";
import { CircularProgressProps } from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { useTranslation } from "react-i18next";
import { ConfirmAlert } from "components/confirmAlert";
import { toast } from "react-toastify";
import BlockIcon from "@mui/icons-material/Block";
import Loading from "components/loading";
import { socket } from "socket.provider";

interface IPair {
  macAddress: string;
  user: { _id: string; name: string; lastname: string; role: string };
  deviceName: string;
  branch: string;
  createdAt: Date;
}

export const DevicePairings = () => {
  const [alert, setAlert] = useState(false);
  const [ban, setBan] = useState(false);
  const [codeDialog, setCodeDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pairings, setPairings] = useState([]);
  const [selectedUser, setUser] = useState("");
  const [macAddress, setMacAddress] = useState("");
  const [code, setCode]: IPair | any = useState({});
  const [auth, setAuth] = useState(false);
  const { t } = useTranslation();
  const Service = new UserService();

  useEffect(() => {
    socket.on("authenticated", () => {
      toast.success(t("pairing-success"));
      setAuth(true);
    });
    let fetchData = async () => {
      let pairings = await Service.getPairOptions();
      setPairings(pairings);
      setLoading(false);
    };
    fetchData();
  }, []);

  const unPair = async () => {
    setAlert(false);
    let response = await Service.unpairDevice({
      userId: selectedUser,
      macAddress: macAddress,
    });
    if (response) {
      toast.success(t("successfully-unpaired"));
      setPairings(response);
      return;
    }
    toast.error(t("server-error"));
  };

  const banDevice = async () => {
    setBan(false);
    let response = await Service.banDevice({
      userId: selectedUser,
      macAddress: macAddress,
    });
    if (response) {
      toast.success(t("successfully-banned"));
      setPairings(response);
      return;
    }
    toast.error(t("server-error"));
  };

  const generatePairCode = async () => {
    let codeData: IPair = await Service.generatePairCode();
    setCode(codeData);
  };

  const CircularProgressWithLabel = (
    props: CircularProgressProps & { value: number; expires: number }
  ) => {
    const minutes = Math.floor(props.expires / 60);
    const seconds = props.expires - minutes * 60;
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          size="5vw"
          value={props.value}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            sx={{ fontSize: "1.1vw" }}
          >{`0${minutes}:${seconds < 10 ? "0" : ""}${seconds}`}</Typography>
        </Box>
      </Box>
    );
  };

  const AuthenticationDialog = () => {
    const [timer, setTimer] = useState(100);
    const [expires, setExpires] = useState(300);
    const [isExpired, setExpired] = useState(false);

    const handleClose = () => {
      setCodeDialog(false);
      setAuth(false);
    };

    const generateNewCode = () => {
      generatePairCode();
      setExpired(false);
      setAuth(false);
      setTimer(100);
      setExpires(300);
    };

    useEffect(() => {
      let runTime = 0;
      const interval = setInterval(() => {
        if (codeDialog) {
          runTime++;
          setTimer((timer) => timer - 0.333);
          setExpires((expires) => expires - 1);
          if (runTime == 300) {
            runTime = 0;
            setTimer(0);
            setExpires(0);
            setExpired(true);
            clearInterval(interval);
          }
        }
      }, 1000);
    }, []);

    return (
      <Dialog open={codeDialog} onClose={handleClose}>
        <DialogTitle>{t("pair-code")}</DialogTitle>
        <DialogContent>
          <Typography
            textAlign="center"
            sx={{
              backgroundColor: isExpired
                ? "#AC0000"
                : auth
                ? "#3CAD00"
                : "#9B9B9B",
              textDecorationLine: isExpired ? "line-through" : "none",
              padding: "0.4vw",
              fontSize: "1.4vw",
              width: "60%",
              margin: "auto",
              borderRadius: 6,
              color: "#fff",
              border: "1px solid gray",
            }}
          >
            {code.code?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
          </Typography>
          {isExpired && !auth ? (
            <FormHelperText sx={{ marginTop: "1vw", color: "red" }}>
              {t("attention")} {t("pair-code-expired")}
            </FormHelperText>
          ) : null}
          {auth ? (
            <FormHelperText sx={{ marginTop: "1vw", color: "#3CAD00" }}>
              {t("auth-success")}
            </FormHelperText>
          ) : null}
          {!auth && !isExpired ? (
            <>
              <FormHelperText sx={{ marginTop: "1vw" }}>
                {t("pair-code-helper")}
              </FormHelperText>
              <Stack
                direction="column"
                spacing={3}
                alignItems="center"
                sx={{ marginTop: 2 }}
              >
                <Typography>{t("code-expiration-time")}</Typography>
                <Box>
                  <CircularProgressWithLabel value={timer} expires={expires} />
                </Box>
              </Stack>
            </>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={generateNewCode}
            sx={{ backgroundColor: "#9B9B9B", color: "#fff", borderRadius: 4 }}
          >
            {t("generate-new-pair-code")}
          </Button>
          <Button color="error" onClick={handleClose}>
            {t("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const UnpairDialog = () => {
    return (
      <Dialog open={alert} onClose={() => setAlert(false)}>
        <DialogTitle>{t("are-you-confirm")}</DialogTitle>
        <DialogContent>{t("unpair-info")}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#353434",
              "&:hover": { backgroundColor: "#706C6C" },
            }}
            onClick={() => unPair()}
          >
            {t("yes")}
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#C90000",
              "&:hover": { backgroundColor: "#E70101" },
            }}
            onClick={() => setAlert(false)}
          >
            {t("no")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const BanDialog = () => {
    return (
      <Dialog open={ban} onClose={() => setBan(false)}>
        <DialogTitle>{t("are-you-confirm")}</DialogTitle>
        <DialogContent>{t("ban-info")}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#353434",
              "&:hover": { backgroundColor: "#706C6C" },
            }}
            onClick={() => banDevice()}
          >
            {t("yes")}
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#C90000",
              "&:hover": { backgroundColor: "#E70101" },
            }}
            onClick={() => setBan(false)}
          >
            {t("no")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  if (loading) {
    return <Loading />;
  }
  if (pairings.length == 0) {
    return (
      <Box sx={{ width: "65%", margin: "auto", marginTop: "1vw" }}>
        <Typography textAlign="center" variant="h4">
          {t("no-pair-found")}
        </Typography>
      </Box>
    );
  }
  if (loading) {
    return <Loading />;
  }
  if (pairings.length == 0) {
    return (
      <Box sx={{ width: "65%", margin: "auto", marginTop: "1vw" }}>
        <Typography textAlign="center" variant="h4">
          {t("no-pair-found")}
        </Typography>
      </Box>
    );
  }
  return (
    <Box>
      <Typography
        textAlign="left"
        variant="h5"
        sx={{ marginLeft: "3vw", marginTop: "3vw", fontWeight: "bold" }}
      >
        {t("device-account-pairings")}
      </Typography>
      <Box sx={{ width: "100%", marginTop: "1vw" }}>
        <Grid container justifyContent="right" alignItems="center">
          <Button
            variant="contained"
            onClick={() => {
              generatePairCode();
              setCodeDialog(true);
            }}
            sx={{
              margin: "1.2vw",
              marginRight: "2vw",
              backgroundColor: "#838383",
              borderRadius: 8,
              fontSize: "1.05vw",
              "&:hover": { backgroundColor: "#6D6D6D" },
            }}
          >
           {t('matchingCode')}
          </Button>
        </Grid>
        <AuthenticationDialog />
        <UnpairDialog />
        <BanDialog />
        <TableContainer
          sx={{
            margin: "auto",
            width: "95%",
            overflow: "auto",
            paddingBottom:"200px",
            "@media (max-height: 600px)": {
                maxHeight: "50vh"
              },
              "@media (min-height: 601px)": {
                maxHeight: "75vh"
              }
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("username")}</TableCell>
                <TableCell>{t("role")}</TableCell>
                <TableCell>{t("device-name")}</TableCell>
                <TableCell>{t("mac-address")}</TableCell>
                <TableCell>{t("pairing-date")}</TableCell>
                <TableCell>{t("actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pairings.map((pairing: IPair) => (
                pairing.user?
                <TableRow>
                  <TableCell>
                    {pairing.user.name} {pairing.user.lastname}
                  </TableCell>
                  <TableCell>{t(pairing.user?pairing.user.role:"waiter")}</TableCell>
                  <TableCell>{pairing.deviceName}</TableCell>
                  <TableCell>{pairing.macAddress}</TableCell>
                  <TableCell>
                    {new Date(pairing.createdAt)?.toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      title={t("unpair")!}
                      onClick={() => {
                        setMacAddress(pairing.macAddress);
                        setUser(pairing.user._id);
                        setAlert(true);
                      }}
                    >
                      <LinkOffIcon />
                    </IconButton>
                    <IconButton
                      title={t("ban-device")!}
                      onClick={() => {
                        setBan(true);
                        setMacAddress(pairing.macAddress);
                        setUser(pairing.user._id);
                      }}
                    >
                      <BlockIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
                :null
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
