import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Http from "services/http";
import Response from "services/response";
import Getir from "services/getir";
import Branch from "services/branch";
// import Trendyol from "services/trendyol"

export default function ({
  linkBase,
  exclude = false,
  emptyMessage,
}: {
  linkBase: string;
  exclude?: boolean;
  emptyMessage: string;
}) {
  const GetirIntegration = new Response();
  const BranchService = new Branch(useContext(Http.Context)!);

  // const TrendyolIntegration = new Response();

  const supportedIntegrations = ["trendyol", "getir"];
  const [existingIntegrations, setExistingIntegrations] = useState<Object[]>(
    []
  );

  useEffect(() => {
    if (!GetirIntegration.data) {
      GetirIntegration.handle(BranchService.getGetirCredentials());
    } else {
      let integration = Object.assign({}, GetirIntegration.data);
      integration.name = "getir";
      setExistingIntegrations([...existingIntegrations, integration]);
    }
  }, [GetirIntegration.data]);

  // Exculde existing integrations from the supported integrations
  const [targetIntegrations, setTargetIntegrations] = useState<string[]>([]);
  useEffect(() => {
    if (exclude) {
      const target = supportedIntegrations.filter((int) => {
        return !existingIntegrations
          .map((exist: any) => {
            return exist.name;
          })
          .includes(int);
      });
      setTargetIntegrations(target);
    }
  }, [existingIntegrations]);

  /* NOT YET IMPLEMENTED

    useEffect(() => {
      if(!TrendyolIntegration.data){
        TrendyolIntegration.handle(TrendyolService.getIntegration())
      }else {
        let integration = Object.assign({}, TrendyolIntegration.data)
        integration.name = "trendyol"
        setExistingIntegrations([...existingIntegrations, integration])
      }
    }, [TrendyolIntegration.data])

    */

  return (
    <>
      {(exclude ? targetIntegrations : existingIntegrations).map(
        (integration: any) => (
          <Link
            key={integration.name || integration}
            to={`${linkBase}/${integration.name || integration}`}
            className="flex capitalize mr-auto items-center text-white bg-ebony py-3 px-6 rounded-lg text-sm font-medium"
          >
            {integration.name || integration}
          </Link>
        )
      )}
      {(exclude ? targetIntegrations : existingIntegrations).length == 0 && (
        <div className="text-center">
          <span className="text-sm font-medium">{emptyMessage}</span>
        </div>
      )}
    </>
  );
}
