import React, { Component, useState } from "react";
import "./cancel.css";
import { WithTranslation, withTranslation } from "react-i18next";
import Loading from "components/loading";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import TextField from "@mui/material/TextField";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import FileDownload from "@mui/icons-material/FileDownload";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { parseCancelReport } from "services/helpers/excel";
import { toast } from "react-toastify";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import LinkOffIcon from "@mui/icons-material/ReceiptLong";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { convertCurrency} from "services/helpers/exchange";
import IconButton from "@mui/material/IconButton";
import { FormControl } from "@mui/material";

type CancelReportState = {
  check:any,
  reportData: any;
  userBranch: any;
  subBranches: any;
  selectedBranch: string;
  startdate: any;
  enddate: any;
  loading: boolean;
  checkLoading: boolean;
  alert: boolean;
};
const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_NAME!);
const refreshToken = localStorage.getItem(
    process.env.REACT_APP_REFRESH_TOKEN_NAME!
);

interface IProps extends WithTranslation { }


class CancelPage extends Component<IProps> {

  state: CancelReportState = {
    check:{},
    reportData: [],
    userBranch: {},
    subBranches: [],
    selectedBranch: "",
    startdate: new Date(Date.now() - 86400000),
    enddate: new Date(),
    loading: true,
    checkLoading: true,
    alert: false
};

async componentDidMount() {
  let branch: any = await axios.get(`manager/branches`, {
      baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
      headers: {
          Authorization: `Bearer ${token}`,
          "x-refresh": refreshToken!,
      },
  });
  let data: any = await axios.get(
      `manager/report/cancelled-orders/${branch?.data[0]?._id}`,
      {
          baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
          headers: {
              Authorization: `Bearer ${token}`,
              "x-refresh": refreshToken!,
          },
          params: {
              from: new Date(this.state.startdate).getTime(),
              to: new Date(this.state.enddate).getTime(),
          },
      }
  );
  
  this.setState({
      reportData: data.data,
      userBranch: branch?.data[0],
      subBranches: branch?.data[0]?.subBranch,
      selectedBranches: [branch?.data[0]?._id],
      selectedBranch: branch?.data[0]?._id,
      loading: false,
      alert: false,
  });
}
timeConvert(date:Date){
const year = date.getFullYear();
const month = String(date.getMonth() + 1).padStart(2, '0');
const day = String(date.getDate()).padStart(2, '0');
const hours = String(date.getHours()).padStart(2, '0');
const minutes = String(date.getMinutes()).padStart(2, '0');
const seconds = String(date.getSeconds()).padStart(2, '0');

const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

return  formattedDate
}
exportReports = () => {
  if (this.state.reportData.length == 0) {
      toast.warning('no-ready-report');
      return;
  }
  
  let branch = [this.state.userBranch].concat(this.state.subBranches)?.find((b: any) => b._id == this.state.selectedBranch)?.title;
  parseCancelReport(this.state.reportData, branch);
}
syncData = async () => {
  if (
      !new Date(this.state.startdate).getTime() ||
      !new Date(this.state.enddate).getTime()
  ) {
      this.setState({
          reportData: [],
      });
      return;
  }
  if (this.state.startdate > this.state.enddate) {
      toast.warning(this.props.t("date-warning"));
      return;
  }
  if (this.state.enddate >= this.state.startdate) {
      this.setState({ loading: true });
      let data: any = await axios.get(
          `manager/report/cancelled-orders/${this.state.selectedBranch}`,
          {
              baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
              headers: {
                  Authorization: `Bearer ${token}`,
                  "x-refresh": refreshToken!,
              },
              params: {
                  from: new Date(this.state.startdate).getTime(),
                  to: new Date(this.state.enddate).getTime(),
              },
          }
      );
      
      this.setState({
          reportData: data.data,
          loading: false,
      });
  }
};
syncCheckData = async (id:any) => {
  if (id) {
      this.setState({ checkLoading: true });
      let data: any = await axios.get(
          `manager/report/check/${id}`,
          {
              baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}/v1`,
              headers: {
                  Authorization: `Bearer ${token}`,
                  "x-refresh": refreshToken!,
              },
          }
      );   
      this.setState({
          check: data.data,
          checkLoading: false,
      });
  }
};

Report = (cancelData: any) => {
  return (this.state.loading)?(<div></div>
  ):(
                    <TableRow>
                        <TableCell style={{width:"15%", textAlign:"center"}}>{cancelData.product.title}</TableCell>
                        <TableCell style={{width:"5%", textAlign:"center"}}>{convertCurrency(cancelData.product.quantity)}</TableCell>
                        <TableCell style={{width:"10%", textAlign:"center"}}>{convertCurrency(cancelData.product.price)}</TableCell>
                        <TableCell style={{width:"15%", textAlign:"center"}}>{new Date(cancelData.orderDate).toLocaleString()}</TableCell>
                        <TableCell style={{width:"15%", textAlign:"center"}}>{new Date(cancelData.cancelledDate).toLocaleString()}</TableCell>
                        <TableCell style={{width:"15%", textAlign:"center"}}>{cancelData.cancelledBy}</TableCell>
                        <TableCell style={{width:"15%", textAlign:"center"}}>{cancelData.cancelReason}</TableCell>
                        <TableCell style={{width:"10%", textAlign:"center"}}>{<IconButton title={this.props.t("check")!} onClick={() => 
                          { this.setState({alert:true});
                          this.syncCheckData(cancelData.checkId);}}>
                          <LinkOffIcon /></IconButton>}</TableCell>
                    </TableRow>)}; 

CheckDialog = () => {
  
if (this.state.alert! === false){
    return  <></> }

if (this.state.checkLoading){
  return  <> <Loading /></>;
}

const check = this.state.check;

const getTotalDiscount = () => {
    let total = 0;
    if(check?.discounts.length >0){
    check?.discounts?.map((discount: any) => {
        total += discount.amount;
    })
   }
    return total;
}
const getTotalCover = () => {
    let total = 0;
    if(check?.covers?.length > 0 ) {
        check?.covers?.map((cover: any) => {
            total += cover.price;
        })
      }
    return total;
}
const getTotalAmout = () => {
  let total = 0;
  if(check?.orders?.length > 0 ) {
      check?.orders?.map((order: any) => {
        if(order?.products?.length > 0 ) {
          order?.products?.map((product: any) => {
          product.status>0 && product.isServe === false ?   
          total += product.price : null
        })}
      })
  }
  return total;
}

const getTotalService = () => {
    let total = 0;   
    if(check?.serviceFee){          
        check?.serviceFee?.map((service: any) => {
            total += service.amount;
        })
    } 
    return total;
} 
 const TotalAll = (
    <div style={{display: 'flex',justifyContent: 'center'}}>
    <div style={{backgroundColor:"ghostwhite", marginTop:"5px" }}>
    <table style={{ display: "block" }}>
        <tbody>  
        <tr style={{ marginBottom: "5px"}}>
           <td style={{width: '180px',textAlign: 'start',fontSize:"18px" }}><span>{this.props.t("subTotal")??"Ara Toplam"}</span></td>
           <td style={{width: '28px',textAlign: 'start' }}><span></span></td>
           <td style={{width: '55px',whiteSpace: 'nowrap', overflow: 'hidden',textAlign: 'end', fontSize:"18px" }}>
           <span>{(Number(getTotalAmout() - getTotalService() - getTotalCover() + getTotalDiscount()))?.toFixed(2)}</span>
           </td>
           </tr>
         {( check?.serviceFee ? check?.serviceFee.map((service: any) => {
          return (
          <tr style={{ marginBottom: "5px"}}>
          <td style={{width: '180px',whiteSpace: 'nowrap', overflow: 'hidden',textAlign: 'start', fontSize:"18px"}}><span style={{ marginRight: "1px" }}>{this.props.t("service")??"Servis"}  {"%"}{service.percentile.toFixed(2).replace(/\.?0+$/, '')}</span>
          </td>
          <td style={{width: '28px',textAlign: 'start', paddingRight:"30px" }}><span></span>
          </td>
          <td style={{width: '55px',whiteSpace: 'nowrap', overflow: 'hidden',textAlign: 'end', fontSize:"18px" }}><span>{(Number(service.amount).toFixed(2))}</span>
          </td>
          </tr>)}):<td></td>)}
     
    {Number(getTotalDiscount()).toFixed(2)==='0.00'?<tr></tr>:<tr style={{ marginBottom: "5px"}}>
    <td style={{width:  '180px',textAlign: 'start', fontSize:"18px" }}><span>{this.props.t("total-discount")??"Toplam İndirim"}</span></td>
    <td style={{width:  '28px',textAlign: 'start' }}><span></span></td>
    <td style={{width: '55px',whiteSpace: 'nowrap', overflow: 'hidden',textAlign: 'end', fontSize:"18px" }}><span>{(Number(Number(getTotalDiscount())))?.toFixed(2)}</span></td>
     </tr>}
      <tr style={{ marginBottom: "5px", marginTop:"5px"}}>
          <td style={{width:  '180px',textAlign: 'start',fontWeight: "bold", fontSize:"18px"}}> <span>{this.props.t("total")??"Toplam"}</span></td>
          <td style={{width:  '28px',textAlign: 'start' }}><span></span></td>
          <td style={{width: '55px',whiteSpace: 'nowrap', overflow: 'hidden',textAlign: 'end',fontWeight: "bold", fontSize:"18px"}}><span>{Number(getTotalAmout() + getTotalService() + getTotalCover() + getTotalDiscount()).toFixed(2)}</span> </td>
      </tr>
      </tbody>
     </table>
     </div>
     </div>
  ) 
const ProductAll = () => { 

  if (check?.orders?.length > 0) {
    const products = check.orders.flatMap((order:any) => order.products);

    if (products.length > 0) {
      return products.map((product:any, index:any) => (
        <TableRow key={index}>
          <TableCell style={{ width: '40vw', maxWidth: "480px", textAlign: "center", fontSize:"medium" }}>
            {convertCurrency(product.quantity)} x {product.productName}
          </TableCell>
          <TableCell style={{ width: '30vw', maxWidth: "200px", textAlign: "center", fontSize:"medium" }}>
            {product.status < 1 ? this.props.t("cancel") : product.isServe ? this.props.t("catering") : convertCurrency(product.price)}
          </TableCell>
        </TableRow>
      ));
    }
  }
    return null
}

return (
<Dialog open={this.state.alert} onClose={() => this.setState({ alert: false })}>
  <div style={{ width: '70vw', maxWidth: "600px", height: "60vh", maxHeight: "600px" }}>
    {this.state.checkLoading ? (
       <><Loading /></> 
    ) : (
      <div style={{ width: '100%' }}>
        <div style={{ textAlign: "center", marginTop:"8px", fontSize:"larger"}}>{[this.state.userBranch].concat(this.state.subBranches)?.find((b: any) => b._id == this.state.selectedBranch)?.title}</div>
        <div style={{ marginTop: "3px", marginLeft:"10px" }}>
          <p>{this.props.t("date")}: {new Date(check.createdAt).toLocaleString()}</p>
          <p>{this.props.t("checkNo")}: {check.checkNo}</p>
        </div>
        <hr style={{marginTop:"3px", marginBottom:"3px"}} />
        <div style={{marginTop:"2px"}}>
                 <table style={{ width: '100%', borderCollapse: "collapse"}}>
                  <thead style={{backgroundColor:"antiquewhite"}}>
                    <tr style={{ display: "block", maxWidth:"600px" }}>
                      <th style={{width: '40vw', maxWidth:"400px"}}>{this.props.t('product')}</th>
                      <th style={{width: '30vw', maxWidth:"200px"}}>{this.props.t('price')}</th>
                    </tr>
                  </thead>
                  <tbody style={{ display:"block", width: '70vw', maxWidth:"600px", height:"34vh", overflow: "auto", backgroundColor:"lightsalmon"}}>                       
                  <><ProductAll /></>
                  </tbody>
                </table>
                 </div>
        {TotalAll}
      </div>
    )}
  </div>
</Dialog>
    );
  };
  render() {
    if (this.state.loading) {
      return (
          <>
              <Loading />
          </>
      );
  }
  return (
    <div className="CancelPage">
      <Stack direction="row">
        <Grid container justifyContent="left">
            {[this.state.userBranch]
                        .concat(this.state.subBranches).length>1? <FormControl sx={{  marginTop:1, marginLeft:1, marginRight: "1vw"}}  >
                <InputLabel id="branchLabel">{this.props.t('branch')}</InputLabel>
                <Select
                    labelId="branchLabel"
                    value={this.state.selectedBranch}
                    onChange={async (e: any) => {
                        await this.setState({
                            selectedBranch: e.target.value,
                        });
                    }}
                >
                    {[this.state.userBranch]
                        .concat(this.state.subBranches)
                        .map((branch: any) => (
                            <MenuItem value={branch._id}>{branch.title}</MenuItem>
                        ))}
                </Select>
            </FormControl>:<></>}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack direction="row" spacing={1} sx={{marginTop:1, marginLeft:1}}>
                                    <DateTimePicker
                                        label={this.props.t('start-date')!}
                                        inputFormat="DD/MM/YYYY HH:mm"
                                        value={this.state.startdate}
                                        ampm={false}
                                        onChange={async (value: any) => {
                                            await this.setState({
                                                startdate: value,
                                            });
                                        }}
                                        renderInput={(params: any) => <TextField {...params} />}
                                    />
                                    <DateTimePicker
                                        label={this.props.t('end-date')!}
                                        inputFormat="DD/MM/YYYY HH:mm"
                                        value={this.state.enddate}
                                        ampm={false}
                                        onChange={async (value: any) => {
                                            await this.setState({
                                                enddate: value,
                                            });
                                        }}
                                        renderInput={(params: any) => <TextField {...params} />}
                                    />
                </Stack>
            </LocalizationProvider>
        </Grid>
        <Grid container justifyContent="right">
            <ButtonGroup sx={{ marginRight: "2px", marginTop:"2px",fontSize:"0.75rem" }} orientation="vertical">
                <Button variant="contained" color="inherit" onClick={() => {
                    this.syncData();
                }}>{this.props.t('get-reports')}</Button>
                <Button variant="contained" startIcon={<FileDownload />} onClick={() => this.exportReports()} sx={{ backgroundColor: "#717D8C" }}>{this.props.t('export-reports')}</Button>
            </ButtonGroup>
        </Grid>
      </Stack>
      <div className="bottomContainer" style={{ overflow: "auto"}}>
        <table className="dataTable">
          <thead>
            <tr>
              <th style={{width:"15%"}}>{this.props.t('product')}</th>
              <th style={{width:"5%"}}>{this.props.t('quantity')}</th>
              <th style={{width:"10%"}}>{this.props.t('price')}</th>
              <th style={{width:"15%"}}>{this.props.t('order-time')}</th>
              <th style={{width:"15%"}}>{this.props.t('cancellation-time')}</th>
              <th style={{width:"15%"}}>{this.props.t('user')}</th>
              <th style={{width:"15%"}}>{this.props.t('reason')}</th>
              <th style={{width:"10%"}}></th>
            </tr>
          </thead>
          <tbody style={{ overflow: "auto",paddingBottom:"100px"}}>               
             {
                 this.state.reportData?.slice().sort((a:any, b:any) => {
                    return new Date(b.orderDate).getTime() - new Date(a.orderDate).getTime();
                  }).map((cancel: any) => (
                     <this.Report key={cancel.id} {...cancel} />
                 ))
            }    
          </tbody>
        </table>
      </div>
      <this.CheckDialog />
    </div>)
  };
};

export default withTranslation()(CancelPage);
