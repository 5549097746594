import { CaseType } from "services/interfaces/case/caseType";
import { Service as HService } from "services/http";

export default class Service {
  private Http: HService | null = null;

  constructor(http: HService) {
    this.Http = http;
  }

  public get endpoint() {
    return `${this.Http?.baseURL}/cases`;
  }

  public getByBranchId(
    page: number | undefined,
    perPage: number | undefined,
    branchId: string
  ) {
    const base: URL = new URL(`${this.Http?.baseURL}/report/case/${branchId}`);

    if (page !== undefined && !Number.isNaN(page))
      base.searchParams.append("page", String(page));

    if (perPage !== undefined && !Number.isNaN(perPage))
      base.searchParams.append("perPage", String(perPage));

    return this.Http?.instance.get(base.href);
  }

  public getAll(
    start: number | undefined = undefined,
    end: number | undefined = undefined
  ) {
    const base: URL = new URL(this.endpoint);

    if (start !== undefined && !Number.isNaN(start))
      base.searchParams.append("_start", String(start));

    if (end !== undefined && !Number.isNaN(end))
      base.searchParams.append("_end", String(end));

    return this.Http?.instance.get<CaseType[]>(
      base.href.substring(base.href.lastIndexOf("/"))
    );
  }

  public async getById(id: string) {
    return await this.Http?.instance.get<CaseType>(`/case/${id}`);
  }

  public async create(data: any) {
    return await this.Http?.instance.post("/cases", data);
  }

  public async update(id: string, data: any) {
    return await this.Http?.instance.put(`/cases/${id}`, data);
  }

  public async delete(id: string) {
    return await this.Http?.instance.delete(`/cases/${id}`);
  }
}
